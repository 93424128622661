export const GET_TILE_TYPES_REQUEST = 'GET_TILES_REQUEST';
export const GET_TILE_TYPES_REQUEST_SUCCESS = 'GET_TILE_TYPES_REQUEST_SUCCESS';
export const GET_TILE_TYPES_REQUEST_FAIL = 'GET_TILE_TYPES_REQUEST_FAIL';

export const UPDATE_TILE_REQUEST = 'UPDATE_TILE_REQUEST';
export const UPDATE_TILE_SUCCESS = 'UPDATE_TILE_SUCCESS';
export const UPDATE_TILE_FAIL = 'UPDATE_TILE_FAIL';

export const CREATE_REQUEST = 'CREATE_REQUEST';
export const CREATE_REQUEST_SUCCESS = 'CREATE_REQUEST_SUCCESS';
export const CREATE_REQUEST_FAIL = 'CREATE_REQUEST_FAIL';

export const GET_TILE_BY_ID = 'GET_TILE_BY_ID';
export const GET_TILE_BY_ID_SUCCESS = 'GET_TILE_BY_ID_SUCCESS';
export const GET_TILE_BY_ID_FAIL = 'GET_TILE_BY_ID_FAIL';

export const RESET_MAPO_SINGLE_CONTENT = 'RESET_MAPO_SINGLE_CONTENT';

export const UPDATE_PREVIEW_TILE = 'UPDATE_PREVIEW_TILE';
