import { loadingBarReducer } from 'react-redux-loading-bar';
import { combineReducers } from 'redux';
import w2pContentReducer from './administration/content-management/w2p-content-management/reducer';
import w2pSingleContentReducer from './administration/content-management/w2p-single-content/reducer';
import mappingRulesReducer from './administration/mapping-rules/reducer';
import userManagementReducer from './administration/user-management/reducer';
import authReducer from './global/auth/reducer';
import headerReducer from './global/header/reducer';
import homeReducer from './home/reducer';
import categoriesReducer from './w2p/categories/reducer';
import editTemplateReducer from './w2p/chili-editor/reducer';
import myTemplateReducer from './w2p/my-templates/reducer';
import templatesByCatAndTagReducer from './w2p/templates-by-cat-and-tag/reducer';
import templatesByTagReducer from './w2p/templates-by-tag/reducer';
import latestTemplatesByTagReducer from './w2p/templates-latest/reducer';
import singleAssetReducer from './w2p/single-asset/reducer';
import mapoContentReducer from './administration/content-management/mapo-content-management/reducer';
import myDocumentReducer from './w2p/my-documents/reducer';
import mapoSingleContentReducer from './administration/content-management/mapo-single-content/reducer';
import microSiteReducer from './microSite/reducer';
import cmsMenuReducer from './administration/menu/reducer';
import userProfileReducer from './userProfile/reducer';
import browserReducer from './global/browser/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  header: headerReducer,
  home: homeReducer,
  mappingRules: mappingRulesReducer,
  userManagement: userManagementReducer,
  loadingBar: loadingBarReducer,
  categories: categoriesReducer,
  templatesByCatAndTag: templatesByCatAndTagReducer,
  templatesByTag: templatesByTagReducer,
  latestTemplatesByTag: latestTemplatesByTagReducer,
  templateInfo: singleAssetReducer,
  myTemplateManagement: myTemplateReducer,
  myDocumentManagement: myDocumentReducer,
  w2pContentManagement: w2pContentReducer,
  w2pSingleContentManagement: w2pSingleContentReducer,
  w2pEditor: editTemplateReducer,
  mapoContentManagement: mapoContentReducer,
  mapoSingleContent: mapoSingleContentReducer,
  microSite: microSiteReducer,
  cmsMenu: cmsMenuReducer,
  userProfile: userProfileReducer,
  browser: browserReducer,
});

export default rootReducer;
