import { Layout } from 'antd';
import Breadcrumb from 'components/UI/Breadcrumb';
import { useMemo } from 'react';
import { navigationRoutes } from 'utilities';
import ScrollToTop from '../ScrollToTop';

export const TermsAndConditions = () => {
  const { Content } = Layout;

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Web2PublishMain,
      },
      {
        title: 'Terms & Conditions',
      },
    ],
    [],
  );

  return (
    <Content className="terms-and-conditions">
      <Breadcrumb items={breadCrumbItems} />
      <div className="terms-banner">
        <h1 className="middle-content font-size-big bold-text">
          Terms &amp; Conditions
        </h1>
      </div>
      <div className="terms-content">
        <div className="middle-content">
          <h2 className="bold-text">Preamble</h2>
          <p>
            With its image and video database for advertising and product
            descriptions Alfred Kärcher SE &amp; Co. KG (hereinafter
            &quot;Kärcher&quot;) provides templates of texts hereinafter
            &quot;Text Material&quot;, pictures and photographs (hereinafter
            &quot;Photographic Material&quot;) and videos and films (hereinafter
            &quot;Video Material&quot;), hereinafter referred to for Text, Photo
            and Video Material collectively as &quot;Work&quot; or
            &quot;Works&quot;. These Works may be used free of charge by those
            users authorized by Kärcher, however only in accordance with the
            following conditions of use. With the templates of Works the
            authorized user (hereinafter &quot;User&quot;) may create
            personalized communication materials, also hereinafter referred to
            as &quot;Work&quot; or &quot;Works&quot;. The authorization process
            is either automatically via SSO (Kärcher Intranet Single Sign On)
            for official dealers, affiliates and Kärcher personnel. Others may
            either receive special, internal access by Kärcher or have to fill
            out an application form in order to receive such a special access.
            Such an application can also be denied, without reason. This
            authorization process may be changed from time to time by Kärcher.
          </p>
          <ol className="mb-0">
            <li className="bold-text">Scope</li>
            <p>
              The following conditions of use govern the use of the database by
              the User and the rights to use the Works provided and or created.
            </p>
            <li className="bold-text">Subject to Alterations</li>
            <p>
              Kärcher is entitled to modify the content of these conditions of
              use at any time.
            </p>
            <li className="bold-text">
              <span className="bold-text">Granting of Rights</span>
              <ol className="nest-list">
                <li>
                  Kärcher grants to the user a non-exclusive right to use the
                  Works downloaded and or created by the user to the following
                  extent. The right to use is timely limited to the term of
                  these conditions of use, unless a shorter period is specified
                  in the detailed information for a particular Work (sec 3.3).
                  The Works may only be used in connection with original Kärcher
                  products and may not impair the brand image of Kärcher.
                  Furthermore, the Works may only be used in accordance with the
                  Kärcher Corporate Design. The consequences of termination are
                  set down in section 10.2.
                </li>
                <li>
                  The grant of rights to use under section 3.1 is &ndash; unless
                  specified differently in the detailed information for a
                  particular Work (sec. 3.3) - limited to:
                  <ol>
                    <li>
                      Making available of the Works to the public on the website
                      of the User as well as on online market places and social
                      media.
                    </li>
                    <li>
                      Using banner advertisements and online marketing measures
                      provided that the corresponding legal provisions (no spam)
                      are met.
                    </li>
                    <li>
                      The right to use and reproduce Works for use in
                      advertising material and sales catalogues distributed free
                      of charge.
                    </li>
                    <li>
                      The right to use the Works in its brick and mortar stores.
                      Outside of the stores, their use is only permitted for the
                      User&apos;s sales events or trade fairs, at which the user
                      takes part as a seller.
                    </li>
                    <li>
                      The right to pass the Works on to third parties as part of
                      normal business operations by the User e.g. to printers or
                      advertising agencies to create advertising materials,
                      however only, if these advertising materials are created
                      for the User.
                    </li>
                    <li>
                      A use beyond the rights to use as set down in sections
                      3.2.1 to 3.2.5 is forbidden to the User. Any use beyond
                      these sections is considered an unauthorized use.
                    </li>
                  </ol>
                </li>

                <li>
                  The User must comply with the limitations of use in territory,
                  time or scope set out in the detailed information listed for
                  each image.
                </li>
                <li>
                  The User may give its staff and others (see section 3.2.5
                  printers etc.) access to the Works. Such access is only
                  authorized if and when the number of people that have access
                  to the Works is still manageable. Also the User must keep and
                  update a list of all people in its staff and others (see
                  section 3.2.5 printers etc.) that received access to the
                  Works. Kärcher has the right to demand this list at any time.
                  User also must inform staff and others (see section 3.2.5
                  printers etc.) concerning the scope and conditions of the
                  right to use as specified herein and must ensure their
                  compliance. Staff and others (see section 3.2.5 printers etc.)
                  do not have the right to pass on their access to the Works.
                  Such use is unauthorized.
                </li>
                <li>The granting of rights is royalty-free.</li>
              </ol>
            </li>

            <li className="bold-text">No Transfer</li>
            <p>
              The distribution, sub-licensing or transfer of the rights to use
              for the Works is expressly forbidden - subject to section 3.2.5.
            </p>

            <li className="bold-text">No Adaptation</li>
            <p>
              The right to modify the Works is limited to the previously
              specified framework by Kärcher as far as technical modifications
              and/or modifications in content are concerned. In this instance it
              is necessary to ensure that the Works are not distorted and that
              the proportions and quality remain unchanged. For example:
              Retouching, distortion and addition to the Photographic Material
              (for example spare parts, other products, promotional messages or
              water marks) or any other method of processing the work might not
              be permitted in the previously specified framework. Further
              information can be found in the Corporate Design from Kärcher.
              Video Material must not be modified, however the previously
              specified framework might permit the user to mention their
              business or company name in the credits.
            </p>

            <li className="bold-text">Copyright Notice</li>
            <p>
              The User may not remove or alter the copyright notices built into
              the Works. Also, in any case the user must comply with the
              copyright notice included in the detailed information for each
              image.
            </p>

            <li className="bold-text">Forbidden Use</li>
            <p>
              The Works may be used exclusively within the scope of the
              conditions and right to use as set forth here and for legally
              permitted purposes. The Works may not be used in connection with
              insulting, discriminating, defamatory, or racist statements, those
              glorifying violence or any other illegal statements or for other
              purposes.
            </p>

            <li className="bold-text">Blocking of Users</li>
            <p>
              In the event of a breach of these conditions of use, Kärcher has
              the right to block the access of the User to the database with
              immediate effect. Such User is considered an unauthorized user.
            </p>

            <li className="bold-text">Liability</li>
            <p>
              The User is liable for all breaches of these conditions of use.
              The User is also liable for any unauthorized use of the Works,
              such as for example a use in breach of section 3 or for missing or
              incorrectly included copyright notices. The User fully indemnifies
              and holds harmless Kärcher against and from any claims by third
              parties.
            </p>
            <li className="bold-text">
              Validity and Termination
              <ol className="nest-list">
                <li>
                  The contract that comes into effect by the acceptance of these
                  conditions of use is valid for an unlimited period of time,
                  unless it is terminated in accordance with section 10.
                  Furthermore, these conditions of use end automatically if the
                  trade/distribution agreements with the user are wound up.
                </li>
                <li>
                  Kärcher can terminate the contract for convenience with a
                  notice period of six months. The right to terminate for cause
                  shall remain unaffected.
                </li>
                <li>
                  Kärcher has the right to terminate for cause in cases such as:
                  <ol className="alpha-list" type="a">
                    <li>
                      If the User files for bankruptcy or becomes insolvent.
                    </li>
                    <li>
                      In the event of a change in the ownership structure of the
                      User.
                    </li>
                    <li>
                      If the User breaches an aforementioned condition of use,
                      especially sections 3., 4., 5., 6., 7. and is therefore
                      considered an unauthorized user.
                    </li>
                  </ol>
                </li>
                <li>Notices of termination shall be given in writing.</li>
                <li>
                  When the right to use is terminated, the User must immediately
                  cease the use of the Works, in particular, it must remove
                  those Works from its internet sites, from offers on online
                  market places, social media and from its stores, as well as
                  ceasing display and/ or performance in its business premises.
                  Printed advertising material may no longer be used and must be
                  destroyed.
                </li>
              </ol>
            </li>

            <li className="bold-text">Final Provisions</li>
            <p className="mb-0">
              The contract for use coming into effect by the acceptance of these
              conditions of use is governed by German law excluding the United
              Nations Convention on Contracts for the International Sale of
              Goods. The place of jurisdiction is Stuttgart (Centre). Kärcher
              reserves, however, the right to sue at the general place of
              jurisdiction of the User.
            </p>
          </ol>
        </div>
      </div>
      <ScrollToTop />
    </Content>
  );
};
