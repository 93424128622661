export const Tags = [
  {
    name: 'PROJECTS',
    id: '1',
  },
  {
    name: 'DOWNLOADS',
    id: '2',
  },
];
