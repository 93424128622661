import { Layout } from 'antd';
import Breadcrumb from 'components/UI/Breadcrumb';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { headerTitle, navigationRoutes, ROUTE } from 'utilities';
import { useLocation } from 'react-router-dom';
import { UPDATE_HEADER_TITLE } from 'redux/global/header/action';
import ScrollToTop from '../ScrollToTop';

export const Disclaimer = () => {
  const { Content } = Layout;
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isInW2P = useMemo(() => {
    return pathname.indexOf(ROUTE.W2P) !== -1;
  }, [pathname]);

  useEffect(() => {
    if (!isInW2P) {
      dispatch({
        type: UPDATE_HEADER_TITLE,
        payload: { title: headerTitle.mapo },
      });
    }
  }, [dispatch, isInW2P]);

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: isInW2P
          ? navigationRoutes.Web2PublishMain
          : navigationRoutes.Home,
      },
      {
        title: 'Disclaimer',
      },
    ],
    [isInW2P],
  );

  return (
    <Content className="disclaimer-container">
      <Breadcrumb items={breadCrumbItems} />
      <div className="disclaimer-banner">
        <h1 className="middle-content font-size-big bold-text">DISCLAIMER</h1>
      </div>
      <div className="disclaimer-content">
        <div className="middle-content">
          <p>
            „The contents of our internet pages are constantly changing. Each
            time they are drawn up with the greatest possible care. Nevertheless
            no guarantee can be taken for completeness or correctness. The
            Alfred Kärcher SE &amp; Co. KG is not liable for damages on the
            basis for the use of the information and data on these internet
            pages, except in cases of intention or gross negligence of the
            Alfred Kärcher SE &amp; Co. KG.
          </p>
          <p>
            The Alfred Kärcher SE &amp; Co. KG does not take any liability or
            guarantee for the contents of internet pages to which reference is
            directly or indirectly made on our homepage. Alfred Kärcher SE &amp;
            Co. KG is not responsible for the internet pages referred to, Third
            parties are. Therefore visitors follow connections to other internet
            pages and homepages on own risk and use these according to the
            respectively valid conditions for use of the corresponding web
            sites.“
          </p>
        </div>
      </div>
      <ScrollToTop />
    </Content>
  );
};
