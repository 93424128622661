import { Layout, Col, Form, Input, Row, Radio } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { BaseButton } from 'components/UI/BaseButton';
import Breadcrumb from 'components/UI/Breadcrumb';
import ScrollToTop from 'components/ScrollToTop';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  compareArrayObject,
  CREATE_STATUS,
  navigationRoutes,
  UPDATE_STATUS,
  STATUS,
} from 'utilities';
import {
  getMediaSubPage,
  createMediaSubPage,
  updateMediaSubPage,
  updatePreviewMediaSubPage,
  resetMediaSubPageData,
} from 'redux/microSite/operation';
import {
  selectMediaSubPage,
  selectCreateMediaSubPage,
  selectUpdateMediaSubPage,
} from 'redux/microSite/selector';
import { selectMenuInfo } from 'redux/administration/menu/selector';
import { BaseUpload } from 'components/UI/BaseUpload';
import Preview from './Preview';

const MicroMediaSubPage = () => {
  const { Content } = Layout;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const typingTimeoutRef = useRef(null);

  const { tileId, subPageId } = useParams();

  const { mediaSubPage, mediaSubPageCreate, mediaSubPageUpdate, cmsMenu } =
    useSelector((state) => ({
      mediaSubPage: selectMediaSubPage(state),
      mediaSubPageCreate: selectCreateMediaSubPage(state),
      mediaSubPageUpdate: selectUpdateMediaSubPage(state),
      cmsMenu: selectMenuInfo(state),
    }));

  const isEditable = useMemo(() => !!subPageId, [subPageId]);

  useEffect(() => {
    if (subPageId) {
      dispatch(getMediaSubPage(subPageId));
    }
  }, [dispatch, tileId, subPageId]);

  const onFinish = useCallback(
    (values) => {
      values.status = values.status === STATUS.ACTIVE;
      if (isEditable && mediaSubPage) {
        const [comparedCategories] = compareArrayObject(
          mediaSubPage.categories,
          values.categories,
        );
        values.categories = comparedCategories;
        dispatch(updateMediaSubPage(subPageId, values));
      } else {
        values.tileId = tileId;
        if (values.categories && values.categories.length > 0) {
          values.categories = values.categories.filter(
            (category) => !!category,
          );
        }
        dispatch(createMediaSubPage(values));
      }
    },
    [dispatch, tileId, isEditable, subPageId, mediaSubPage],
  );

  useEffect(() => {
    if (
      mediaSubPageCreate.status === CREATE_STATUS.SUCCESS ||
      mediaSubPageUpdate.status === UPDATE_STATUS.SUCCESS
    ) {
      history.push(`${navigationRoutes.MicroSingleStartPage}/${tileId}`);
    }
  }, [history, mediaSubPageCreate.status, mediaSubPageUpdate.status, tileId]);

  useEffect(() => {
    const formValues =
      isEditable && mediaSubPage
        ? {
            title: mediaSubPage.title,
            textHeadline: mediaSubPage.textHeadline,
            mediaHeadline: mediaSubPage.mediaHeadline,
            subHeadlineTitle: mediaSubPage.subHeadlineTitle,
            subHeadlineText: mediaSubPage.subHeadlineText,
            categories: mediaSubPage.categories,
            status: mediaSubPage.status ? STATUS.ACTIVE : STATUS.INACTIVE,
          }
        : {
            textHeadline: null,
            mediaHeadline: null,
            subHeadlineTitle: null,
            subHeadlineText: null,
            categories: [{}],
            status: STATUS.INACTIVE,
          };
    form.setFieldsValue(formValues);
    dispatch(updatePreviewMediaSubPage(formValues));
  }, [dispatch, isEditable, mediaSubPage, form]);

  useEffect(() => {
    return () => {
      dispatch(resetMediaSubPageData());
    };
  }, [dispatch]);

  const onUploadMediaHeadline = useCallback(
    (value) => {
      form.setFieldsValue({
        mediaHeadline: value,
      });
      dispatch(
        updatePreviewMediaSubPage({
          mediaHeadline: value,
        }),
      );
    },
    [dispatch, form],
  );

  const onUploadMediaSupport = useCallback(
    (value, key) => {
      const categories = form.getFieldValue('categories') || [];
      if (!categories[key]) {
        categories[key] = {};
      }
      categories[key].mediaLink = value;
      form.setFieldsValue({
        categories,
      });
      dispatch(
        updatePreviewMediaSubPage({
          categories,
        }),
      );
    },
    [dispatch, form],
  );

  const onValuesChange = useCallback(
    (_, allValues) => {
      clearTimeout(typingTimeoutRef.current);
      typingTimeoutRef.current = setTimeout(() => {
        dispatch(updatePreviewMediaSubPage(allValues));
      }, 500);
    },
    [dispatch],
  );

  const onCancel = useCallback(() => {
    history.push(`${navigationRoutes.MicroSingleStartPage}/${tileId}`);
  }, [history, tileId]);

  const breadCrumbTitle = useMemo(() => {
    if (!isEditable) return 'Media Sub Page';
    if (isEditable && mediaSubPage && mediaSubPage.title)
      return mediaSubPage.title;
    return '';
  }, [isEditable, mediaSubPage]);

  const startPageTitle = useMemo(() => {
    if (cmsMenu) {
      const allContents = cmsMenu.menu.find((item) => item.children);
      const startPage = allContents.children.find(
        (content) => content.id === Number(tileId),
      );
      return startPage ? startPage.title : '';
    }
    return '';
  }, [cmsMenu, tileId]);

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Home,
      },
      {
        title: 'Administration',
        path: navigationRoutes.UserManagement,
      },
      {
        title: 'Content Management',
        path: navigationRoutes.MapoContent,
      },
      {
        title: startPageTitle,
        path: `${navigationRoutes.MicroSingleStartPage}/${tileId}`,
      },
      {
        title: breadCrumbTitle,
      },
    ],
    [tileId, startPageTitle, breadCrumbTitle],
  );

  return (
    <Content className="micro-media-sub-page">
      <Breadcrumb items={breadCrumbItems} />
      <div className="media-sub-page_banner mb-6" />
      <div className="middle-content">
        <Form
          form={form}
          onValuesChange={onValuesChange}
          className="media-sub-page_form"
          layout="vertical"
          onFinish={onFinish}>
          <Row span={24} gutter={24}>
            <Col xs={24} md={16} lg={16}>
              <h3 className="form-title">SITE INFORMATION</h3>
              <Form.Item
                className={isEditable ? 'title' : ''}
                label={!isEditable ? 'TITLE' : ''}
                name="title"
                rules={[{ required: true, message: 'Please input title!' }]}>
                <Input
                  placeholder="Title"
                  className={!isEditable ? 'headline-title' : 'title-input'}
                />
              </Form.Item>

              <Form.Item label="TEXT HEADLINE" name="textHeadline">
                <TextArea
                  className="headline-text"
                  placeholder="Text headline"
                  autoSize
                />
              </Form.Item>

              <Form.Item
                className="media-headline"
                name="mediaHeadline"
                label="MEDIA HEADLINE">
                <BaseUpload
                  initialValue={isEditable ? mediaSubPage?.mediaHeadline : null}
                  onUploadChange={onUploadMediaHeadline}
                />
              </Form.Item>

              <Form.Item label="SUBHEADLINE TITLE" name="subHeadlineTitle">
                <Input
                  placeholder="Subheadline title"
                  className="headline-title"
                />
              </Form.Item>

              <Form.Item label="TEXT SUBHEADLINE" name="subHeadlineText">
                <TextArea
                  className="headline-text"
                  placeholder="Text subheadline"
                  autoSize
                />
              </Form.Item>

              <Form.List name="categories">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <div className="sub-headline" key={key}>
                        <Form.Item
                          {...restField}
                          label="MEDIA TITLE"
                          name={[name, 'title']}
                          fieldKey={[fieldKey, 'title']}
                          rules={[
                            {
                              required: true,
                              message: 'Please input title!',
                            },
                          ]}>
                          <Input
                            placeholder="Media title"
                            className="headline-title"
                          />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          label="MEDIA SUPPORT"
                          name={[name, 'mediaLink']}
                          fieldKey={[fieldKey, 'mediaLink']}>
                          <BaseUpload
                            initialValue={
                              isEditable
                                ? mediaSubPage?.categories?.[name]?.mediaLink
                                : null
                            }
                            onUploadChange={(value) =>
                              onUploadMediaSupport(value, name)
                            }
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </div>
                    ))}

                    <div className="d-flex justify-space-between">
                      <BaseButton
                        className="bold-text"
                        htmlType="button"
                        onClick={onCancel}>
                        CANCEL
                      </BaseButton>
                      <BaseButton
                        className="add-category-button"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      />
                      <BaseButton
                        loading={
                          isEditable
                            ? mediaSubPageUpdate.isLoading
                            : mediaSubPageCreate.isLoading
                        }
                        className="base-yellow-btn"
                        type="primary"
                        htmlType="submit">
                        SAVE CONTENT
                      </BaseButton>
                    </div>
                  </>
                )}
              </Form.List>
            </Col>
            <Col xs={24} md={8} lg={8}>
              <Preview startPageTitle={startPageTitle} />
              <Form.Item
                className="media-sub-page_status"
                name="status"
                label="STATUS">
                <Radio.Group className="group-status">
                  <Radio.Button
                    className="group-status-item"
                    value={STATUS.ACTIVE}>
                    <span className="label-radio">ACTIVE</span>
                  </Radio.Button>
                  <Radio.Button
                    className="group-status-item ml-4"
                    value={STATUS.INACTIVE}>
                    <span className="label-radio">INACTIVE</span>
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <ScrollToTop />
    </Content>
  );
};

export default MicroMediaSubPage;
