import { EditOutlined, SyncOutlined } from '@ant-design/icons';
import { navigationRoutes } from 'utilities';
import BaseTagsDisplay from 'components/UI/BaseTagsDisplay';
import { updateThumbnail } from 'redux/administration/content-management/w2p-content-management/operation';

const onClickEdit = ({ history, id, title }) => {
  history.push(`${navigationRoutes.W2PSingleContent}/${id}/${title}`);
};

const onClickSyncThumbnail = ({ dispatch, id }) => {
  dispatch(updateThumbnail(id));
};

export const renderColumns = (history, dispatch) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (_, dt, index) => index + 1,
      width: '5%',
    },
    {
      title: 'Title/Headline',
      dataIndex: 'title',
      width: '20%',
      ellipsis: true,
    },
    {
      title: 'Body Short',
      dataIndex: 'bodyShort',
      width: '12%',
    },
    {
      title: 'Asset Size',
      dataIndex: 'assetSize',
      width: '10%',
    },
    {
      title: 'Categories',
      dataIndex: 'categories',
      render: (data) => <BaseTagsDisplay tags={data} />,
      width: '14%',
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      render: (data) => <BaseTagsDisplay tags={data} />,
      width: '14%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: 'Active',
          value: 'active',
        },
        {
          text: 'Inactive',
          value: 'inactive',
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => {
        if (status === 'active') {
          return <div className="status-active">{status}</div>;
        }

        if (status === 'inactive') {
          return <div className="status-inactive">{status}</div>;
        }

        return null;
      },
      width: '10%',
    },
    {
      title: 'Mapping',
      dataIndex: 'status',
      render: (status) => {
        if (status) {
          return <div className="status-active background-blue">mapped</div>;
        }
        return null;
      },
      width: '10%',
    },
    {
      title: '',
      dataIndex: 'action',
      render: (text, record) => {
        const { id, title } = record;
        return (
          <div className="actions-group">
            <div
              className="w2p-content-list_editable sync"
              onClick={() => onClickSyncThumbnail({ dispatch, id })}
              aria-hidden="true">
              <SyncOutlined />
            </div>
            <div
              className="w2p-content-list_editable"
              onClick={() => onClickEdit({ history, id, title })}
              aria-hidden="true">
              <EditOutlined />
            </div>
          </div>
        );
      },
      width: '7%',
    },
  ];

  return columns;
};
