import { Layout } from 'antd';
import Breadcrumb from 'components/UI/Breadcrumb';
import BaseTabs from 'components/UI/BaseTabs';
import { useMemo } from 'react';
import { navigationRoutes, BUSINESS_TYPE } from 'utilities';
import BaseRule from './BaseRule';

const MapoRule = () => {
  const { Content } = Layout;

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Home,
      },
      {
        title: 'Administration',
        path: navigationRoutes.UserManagement,
      },
      {
        title: 'MAPO Mapping Rules',
      },
    ],
    [],
  );

  return (
    <Content className="mapo-administrator-mapo-rule">
      <Breadcrumb items={breadCrumbItems} />
      <div className="mapo-rule-content_banner">
        <div className="mapo-rule-content middle-content">
          <h1>Mapo Mapping Rules</h1>
        </div>
      </div>
      <div className="mapo-rule-content-tabs">
        <BaseTabs
          initialPanes={[
            {
              title: 'REGION',
              content: <BaseRule type={BUSINESS_TYPE.REGION} />,
              key: '1',
            },
            {
              title: 'COMPANY',
              content: <BaseRule type={BUSINESS_TYPE.COMPANY} />,
              key: '2',
            },
            {
              title: 'JOB ATTRIBUTES',
              content: <BaseRule type={BUSINESS_TYPE.JOB_TYPE} />,
              key: '3',
            },
          ]}
        />
      </div>
    </Content>
  );
};

export default MapoRule;
