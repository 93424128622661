import { W2pService } from 'services';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  GET_TEMPLATES_BY_TAG,
  GET_TEMPLATES_BY_TAG_SUCCESS,
  GET_TEMPLATES_BY_TAG_FAIL,
} from './action';

export const getTagsAssociateTemplate = () => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_TAGS, payload: true });
    const res = await W2pService.getTagsAssociateTemplate();
    dispatch(hideLoading());
    dispatch({ type: GET_TAGS_SUCCESS, payload: res });
  } catch (err) {
    dispatch({ type: GET_TAGS_FAIL, err });
    dispatch(hideLoading());
  }
};

export const getTemplatesByTag = (tagId, term) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_TEMPLATES_BY_TAG, payload: true });
    const res = await W2pService.getTemplatesByTag(tagId, term);
    dispatch(hideLoading());
    dispatch({ type: GET_TEMPLATES_BY_TAG_SUCCESS, payload: res });
  } catch (err) {
    dispatch({ type: GET_TEMPLATES_BY_TAG_FAIL, err });
    dispatch(hideLoading());
  }
};
