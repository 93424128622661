import { W2pService } from 'services';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
  GET_TAGS_BY_CAT,
  GET_TAGS_BY_CAT_SUCCESS,
  GET_TAGS_BY_CAT_FAIL,
  GET_TEMPLATES_BY_CAT_AND_TAG,
  GET_TEMPLATES_BY_CAT_AND_TAG_SUCCESS,
  GET_TEMPLATES_BY_CAT_AND_TAG_FAIL,
} from './action';

export const getTemplatesByCat = (id) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_TAGS_BY_CAT, payload: true });
    const res = await W2pService.getTemplatesByCat(id);

    const tagsId = {};
    const tags = [];
    res.data.forEach((item) => {
      item.tags.forEach((tag) => {
        if (!tagsId[tag.id]) {
          tags.push(tag);
        }
      });
    });
    const resTags = tags;
    dispatch(hideLoading());
    dispatch({
      type: GET_TAGS_BY_CAT_SUCCESS,
      payload: { templates: res.data, tags: resTags },
    });
  } catch (err) {
    dispatch({ type: GET_TAGS_BY_CAT_FAIL, err });
    dispatch(hideLoading());
  }
};

export const getTemplatesByCatAndTag = (catId, tags) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_TEMPLATES_BY_CAT_AND_TAG, payload: true });
    const res = await W2pService.getTemplatesByCat(catId, tags);
    dispatch(hideLoading());
    dispatch({ type: GET_TEMPLATES_BY_CAT_AND_TAG_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: GET_TEMPLATES_BY_CAT_AND_TAG_FAIL, err });
    dispatch(hideLoading());
  }
};
