import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
  UserService,
  TileService,
  BusinessInfosService,
  JobTypeService,
} from 'services';
import { toast } from 'utilities';
import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAIL,
  UPDATE_USER_DETAIL_FAIL,
  UPDATE_USER_DETAIL_SUCCESS,
  GET_USER_COMPANY_FAIL,
  GET_USER_COMPANY_SUCCESS,
  GET_USER_REGION_FAIL,
  GET_USER_REGION_SUCCESS,
  RESET_USER_DETAIL,
  GET_JOB_TYPE_SUCCESS,
  GET_JOB_TYPE__FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  SET_USER_ADMIN_ROLE_FAIL,
  SET_USER_ADMIN_ROLE_SUCCESS,
  SET_USER_ADMIN_ROLE_REQUEST,
  SET_USER_VIEW_ALL_TILES_REQUEST,
  SET_USER_VIEW_ALL_TILES_SUCCESS,
  SET_USER_VIEW_ALL_TILES_FAIL,
} from './action';
import {
  convertDataResponse,
  singleDataResponse,
} from './util/convertDataResponse';

export const searchUsers = (limit, page, params) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: GET_USERS_REQUEST });
    const response = await UserService.searchUsers(limit, page, params);
    response.data = convertDataResponse(response.data);
    response.search =
      params?.find((param) => param.fieldName === 'term')?.value ?? '';
    response.startWith =
      params?.find((param) => param.fieldName === '_startWith')?.value ?? '';
    dispatch(hideLoading());
    dispatch({ type: GET_USERS_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: GET_USERS_FAIL, payload: { error } });
    dispatch(hideLoading());
  }
};

export const getDetailUser = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: GET_USERS_REQUEST });
    const response = await Promise.all([
      UserService.getDetailUser(id),
      TileService.getTileRole('tileTypeRoles'),
    ]);

    const userDetail = singleDataResponse(response[0].data);
    userDetail.tiles = response[1].data;
    dispatch(hideLoading());
    dispatch({ type: GET_USER_DETAIL_SUCCESS, payload: userDetail });
  } catch (error) {
    dispatch({ type: GET_USER_DETAIL_FAIL, payload: { error } });
    dispatch(hideLoading());
  }
};

export const updateDetailUser = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: GET_USERS_REQUEST });
    await Promise.all([
      UserService.updateUser(id, data),
      TileService.getTileRole('tileTypeRoles'),
    ]);

    dispatch(hideLoading());
    dispatch({ type: UPDATE_USER_DETAIL_SUCCESS, payload: true });
    toast('success', 'User', 'Updated Successfully');
  } catch (error) {
    dispatch({ type: UPDATE_USER_DETAIL_FAIL, payload: { error } });
    dispatch(hideLoading());
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: DELETE_USER_REQUEST });
    await UserService.deleteUser(id);

    dispatch(hideLoading());
    dispatch({ type: DELETE_USER_SUCCESS, payload: { id } });
    toast('success', 'User', 'Deleted Successfully!');
  } catch (error) {
    dispatch({ type: DELETE_USER_FAIL, payload: { error } });
    dispatch(hideLoading());
  }
};

export const getJobType = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await JobTypeService.getJobTypes(100);
    dispatch(hideLoading());
    dispatch({ type: GET_JOB_TYPE_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_JOB_TYPE__FAIL, payload: { error } });
    dispatch(hideLoading());
  }
};

export const getRegion = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await BusinessInfosService.getRegions();
    dispatch(hideLoading());
    dispatch({ type: GET_USER_REGION_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_USER_REGION_FAIL, payload: { error } });
    dispatch(hideLoading());
  }
};

export const getCompany = (parentId) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await BusinessInfosService.getCompaniesByRegions(parentId);

    dispatch(hideLoading());
    dispatch({ type: GET_USER_COMPANY_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_USER_COMPANY_FAIL, payload: { error } });
    dispatch(hideLoading());
  }
};

export const resetUser = () => (dispatch) => {
  dispatch({ type: RESET_USER_DETAIL });
};

export const setUserAdminRole = (id, checked) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: SET_USER_ADMIN_ROLE_REQUEST });
    await UserService.setUserAdminRole(id, checked);
    dispatch(hideLoading());
    dispatch({ type: SET_USER_ADMIN_ROLE_SUCCESS, payload: checked ? 2 : 1 });
    toast('success', 'User', 'Updated Successfully');
  } catch (error) {
    dispatch({ type: SET_USER_ADMIN_ROLE_FAIL, payload: { error } });
    dispatch(hideLoading());
  }
};

export const setUserViewAllTiles = (id, checked) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: SET_USER_VIEW_ALL_TILES_REQUEST });
    await UserService.setUserAccessAllTilesRole(id, checked);
    dispatch(hideLoading());
    dispatch({
      type: SET_USER_VIEW_ALL_TILES_SUCCESS,
      payload: checked ? 3 : 1,
    });
    toast('success', 'User', 'Updated Successfully');
  } catch (error) {
    dispatch({ type: SET_USER_VIEW_ALL_TILES_FAIL, payload: { error } });
    dispatch(hideLoading());
  }
};
