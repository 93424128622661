import http from 'utilities/httpService';

export class AuthenticateService {
  static async getAuthenticationInfos() {
    return http.get('/auth/users?_withs=userRole,accessTiles');
  }

  static async getSingedUrl(fileName) {
    return http.get(`/auth/signed-url/${fileName}`);
  }

  static async getUserProfile() {
    return http.get('/auth/users?_withs=company');
  }

  static async updateUserInfo(data) {
    return http.put('/auth/users', data);
  }
}
