import { Breadcrumb as BreadcrumbComponent } from 'antd';
import { Link } from 'react-router-dom';
import PropType from 'prop-types';

const Breadcrumb = ({ items }) => {
  return (
    <div className="breadcrumb-container d-flex align-center flex-row">
      <BreadcrumbComponent separator=">" className="breadcrumb">
        {items.map(({ title, path, onClick }) =>
          path || onClick ? (
            <BreadcrumbComponent.Item key={title} className="breadcrumb-item">
              {onClick ? (
                <span
                  className="clickable"
                  role="presentation"
                  onKeyPress={onClick}
                  onClick={onClick}>
                  {title}
                </span>
              ) : (
                <Link to={path}>{title}</Link>
              )}
            </BreadcrumbComponent.Item>
          ) : (
            <BreadcrumbComponent.Item key={title} className="breadcrumb-item">
              {title}
            </BreadcrumbComponent.Item>
          ),
        )}
      </BreadcrumbComponent>
    </div>
  );
};

Breadcrumb.propTypes = {
  items: PropType.arrayOf(
    PropType.shape({
      title: PropType.string,
      path: PropType.string,
      onClick: PropType.func,
    }),
  ).isRequired,
};

export default Breadcrumb;
