import immutable from 'immutable';
import {
  GET_TILES_REQUEST,
  GET_TILES_SUCCESS,
  GET_TILES_FAIL,
  DELETE_TILE_REQUEST,
  DELETE_TILE_SUCCESS,
  DELETE_TILE_FAIL,
  CHANGE_ORDER_TILE_REQUEST,
  CHANGE_ORDER_TILE_SUCCESS,
  CHANGE_ORDER_TILE_FAIL,
} from './action';

const init = () => {
  const initValue = immutable.fromJS({
    mapoContent: undefined,
  });

  return initValue.set('mapoContent', {
    tiles: [],
    isLoading: false,
    pagination: {
      page: 1,
      limit: 10,
      total: 0,
    },
  });
};

export default function mapoContentReducer(state = init(), action) {
  switch (action.type) {
    case GET_TILES_REQUEST:
    case CHANGE_ORDER_TILE_REQUEST:
    case DELETE_TILE_REQUEST:
      return state.update('mapoContent', (mapoContent) => ({
        ...mapoContent,
        isLoading: true,
      }));

    case GET_TILES_SUCCESS: {
      const {
        payload: { data, metadata },
      } = action;
      return state.update('mapoContent', (mapoContent) => ({
        ...mapoContent,
        tiles: data,
        pagination: {
          page: Number(metadata.page),
          limit: Number(metadata.limit),
          total: Number(metadata.totalRecords),
        },
        isLoading: false,
      }));
    }

    case GET_TILES_FAIL:
    case CHANGE_ORDER_TILE_FAIL:
    case DELETE_TILE_FAIL:
      return state.update('mapoContent', (mapoContent) => ({
        ...mapoContent,
        isLoading: false,
      }));
    case DELETE_TILE_SUCCESS:
      return state.update('mapoContent', (mapoContent) => {
        const tiles = [...mapoContent.tiles];
        return {
          ...mapoContent,
          tiles: tiles.filter((e) => e.id !== action.payload),
          isLoading: false,
        };
      });
    case CHANGE_ORDER_TILE_SUCCESS: {
      const { tiles } = state.get('mapoContent');
      const cloneTiles = [...tiles];
      const {
        payload: {
          data: { id, tileOrder },
        },
      } = action;
      const oldIndex = cloneTiles.findIndex((tile) => tile.id === id);
      const newIndex = tileOrder - 1;

      cloneTiles.splice(newIndex, 0, cloneTiles.splice(oldIndex, 1)[0]);
      return state.update('mapoContent', (mapoContent) => ({
        ...mapoContent,
        tiles: cloneTiles,
        isLoading: false,
      }));
    }
    default:
      return state;
  }
}
