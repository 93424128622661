import { Auth } from 'aws-amplify';

class Authentication {
  constructor() {
    this.userInfo = null;
    this.jwtToken = null;
  }

  getUserInfo = async () => {
    try {
      this.userInfo = await Auth.currentSession();

      return this.userInfo;
    } catch (error) {
      return false;
    }
  };

  signIn = (provider = null) => {
    Auth.federatedSignIn({ provider });
  };

  signOut = async () => {
    await Auth.signOut();
  };

  getJwtToken = async () => {
    this.jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();

    return this.jwtToken;
  };

  getUserId = async () => {
    const userId = (await Auth.currentSession()).getIdToken()?.payload?.sub;

    return userId;
  };
}

export default new Authentication();
