import { Layout, Row, Col } from 'antd';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getMediaSubPage } from 'redux/microSite/operation';
import { selectMediaSubPage } from 'redux/microSite/selector';
import Breadcrumb from 'components/UI/Breadcrumb';
import ScrollToTop from 'components/ScrollToTop';
import { navigationRoutes } from 'utilities';
import { imagePlaceHolder } from 'assets/images';

export const MicroSiteMediaSubPage = () => {
  const { Content } = Layout;
  const dispatch = useDispatch();
  const { tileId, subPageId } = useParams();

  const { mediaSubPage } = useSelector((state) => ({
    mediaSubPage: selectMediaSubPage(state),
  }));

  useEffect(() => {
    dispatch(getMediaSubPage(subPageId));
  }, [dispatch, tileId, subPageId]);

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Home,
      },
      {
        title: mediaSubPage ? mediaSubPage.tile.title : '',
        path: `${navigationRoutes.MicroSite}/${tileId}`,
      },
      {
        title: mediaSubPage ? mediaSubPage.title : '',
      },
    ],
    [tileId, mediaSubPage],
  );
  return (
    <Content className="micro-site-media-sub-page">
      {mediaSubPage && (
        <>
          <Breadcrumb items={breadCrumbItems} />
          <div className="media-sub-page_banner">
            <div className="middle-content">
              <h2 className="media-sub-page-headline font-size-big bold-text">
                {mediaSubPage.title}
              </h2>
              <p className="media-sub-page-headline-text bold-text">
                {mediaSubPage.textHeadline}
              </p>
            </div>
          </div>
          <div className="media-sub-page-image">
            <img src={mediaSubPage.mediaHeadline || imagePlaceHolder} alt="" />
          </div>
          <div className="media-sub-page_sub-headline-category mt-8">
            <div className="middle-content">
              <h2 className="media-sub-page_sub-headline">
                {mediaSubPage.subHeadlineTitle}
              </h2>
              <p className="media-sub-page_sub-headline-text">
                {mediaSubPage.subHeadlineText}
              </p>
              {mediaSubPage.categories && (
                <Row
                  className="media-sub-page-sub-headline-category-items mt-12"
                  gutter={[24, 24]}>
                  {mediaSubPage.categories.map((item) => {
                    return (
                      <Col xs={24} md={24} sm={24} key={item.id}>
                        <div className="sub-headline-category-img_wraper mb-4">
                          <img
                            className="sub-headline-category-img"
                            src={item.mediaLink || imagePlaceHolder}
                            alt=""
                          />
                        </div>
                        <div className="sub-headline-category-title mb-4">
                          {item.title}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </div>
          </div>
        </>
      )}
      <ScrollToTop />
    </Content>
  );
};
