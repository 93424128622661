import { Layout } from 'antd';
import { BaseButton } from 'components/UI/BaseButton';
import { useHistory } from 'react-router-dom';
import { navigationRoutes } from 'utilities';

export const Web2PublishWelcome = () => {
  const { Content } = Layout;
  const history = useHistory();
  const handleGetStarted = () => {
    history.push(navigationRoutes.Web2PublishMain);
  };

  return (
    <Content className="web2Publish-welcome-content">
      <div className="middle-content">
        <h1 className="web2Publish-welcome-content_text">
          WELCOME <br />
          TO KÄRCHER <br />
          WEB 2 PUBLISH
        </h1>

        <p className="web2Publish-welcome-content_description">
          Find ready-made Kärcher templates here to personalize yourself. Filter
          by categories and tags to quickly and easily find what you are looking
          for.
        </p>

        <BaseButton
          className="base-yellow-btn mt-10 web2Publish-welcome-content_button bold-text"
          onClick={handleGetStarted}>
          GET STARTED
        </BaseButton>
      </div>
    </Content>
  );
};
