import groupBy from 'lodash/groupBy';

export const getSelectedRegions = (templateAccess) => {
  const groupByRegion = groupBy(templateAccess, 'regionId');
  const selectedRegions = [];
  Object.keys(groupByRegion).forEach((key) => {
    selectedRegions.push(key);
  });
  return selectedRegions;
};

export const getCheckedRegion = (regions, selectedRegions) => {
  const checkedRegion = [];
  if (selectedRegions.length) {
    selectedRegions.forEach((selectedRegion) => {
      const foundRegion = regions.find(
        (region) => region.id === selectedRegion,
      );
      if (foundRegion && foundRegion.isChecked) {
        checkedRegion.push(selectedRegion);
      }
    });
  }
  return checkedRegion;
};
