import { Col } from 'antd';
import PropType from 'prop-types';
import { format } from 'date-fns';
import { BaseButton } from 'components/UI/BaseButton';

const MyTemplateItem = (props) => {
  const { temp, onClick, buttonName, modifiedAt, fileType, tempId } = props;

  return (
    <Col key={tempId} xs={12} md={12} lg={6} className="template">
      <div className="image-container">
        <img className="image" src={temp.image} alt="" />
        <p className="date">
          {modifiedAt
            ? format(new Date(modifiedAt), 'dd.MM.yyyy kk:mm')
            : 'N/A'}
        </p>
        {fileType && <div className="file-type">{fileType}</div>}
      </div>

      <p className="title bold-text">{temp.title}</p>
      <p className="description">{temp.bodyShort}</p>
      <BaseButton className="base-yellow-btn mb-6" onClick={onClick}>
        {buttonName}
      </BaseButton>
    </Col>
  );
};

export default MyTemplateItem;

MyTemplateItem.defaultProps = {
  onClick: undefined,
};

MyTemplateItem.propTypes = {
  temp: PropType.shape({
    id: PropType.string.isRequired,
    image: PropType.string.isRequired,
    title: PropType.string.isRequired,
    bodyShort: PropType.string,
  }).isRequired,
  tempId: PropType.oneOfType([PropType.string, PropType.number]).isRequired,
  onClick: PropType.func,
  buttonName: PropType.string.isRequired,
  modifiedAt: PropType.string.isRequired,
  fileType: PropType.string,
};

MyTemplateItem.defaultProps = {
  fileType: undefined,
};
