import { Spin, Row, Empty, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import BaseGroupOptions from 'components/UI/BaseGroupOptions';
import {
  selectTags,
  selectTemplates,
  selectIsLoading,
} from 'redux/w2p/templates-latest/selector';
import {
  getTagsAssociateTemplate,
  getLatestTemplatesByTag,
} from 'redux/w2p/templates-latest/operation';
import { useHistory } from 'react-router-dom';
import { ChevronRight, imagePlaceHolder } from 'assets/images';
import { navigationRoutes } from 'utilities';

export const Web2PublishLatestTemplatesByTag = () => {
  const templates = useSelector((state) => selectTemplates(state));
  const tags = useSelector((state) => selectTags(state));
  const isLoading = useSelector((state) => selectIsLoading(state));

  const dispatch = useDispatch();
  const history = useHistory();

  const [tagValues, setTagValues] = useState([]);

  useEffect(() => {
    dispatch(getTagsAssociateTemplate());
  }, [dispatch]);

  useEffect(() => {
    const tagIds = tagValues.map((tag) => tag.id);
    if (tagIds.length > 0) {
      dispatch(getLatestTemplatesByTag(tagIds));
    } else {
      dispatch(getLatestTemplatesByTag(tagIds, true));
    }
  }, [dispatch, tagValues]);

  const onValueChange = useCallback((values) => {
    setTagValues(values);
  }, []);

  const onClickBaseTemplateItem = (templateItem) => {
    const breadCrumbItems = [
      {
        path: navigationRoutes.Web2PublishMain,
        title: 'Home',
      },
      {
        path: navigationRoutes.Web2PublishMain,
        title: 'Categories',
      },
      {
        title: templateItem.categories[0].name,
      },
    ];
    history.push(
      `${navigationRoutes.SingleAsset}/${templateItem.id}`,
      breadCrumbItems,
    );
  };

  return (
    <div className="web2Publish-latest-templates-by-tag-container">
      <div className="middle-content">
        <BaseGroupOptions
          options={tags ?? []}
          onValueChange={(value) => onValueChange(value)}
        />
        {isLoading ? (
          <div className="loading">
            <Spin />
          </div>
        ) : (
          [
            templates.length > 0 ? (
              <Row
                className="templates"
                key="latest-templates"
                gutter={[
                  { xs: 8, sm: 16, md: 24 },
                  { xs: 8, sm: 16, md: 24 },
                ]}>
                {templates.map((temp) => (
                  <Col
                    key={temp.id}
                    xs={12}
                    md={12}
                    lg={6}
                    onClick={() => onClickBaseTemplateItem(temp)}>
                    <div className="template-image_wrapper">
                      <img
                        className="template-image"
                        src={temp.image ? temp.image : imagePlaceHolder}
                        alt=""
                      />
                      <div className="template-image_overlay">
                        <div className="template-image_overlay-content">
                          <h2 className="template-image_overlay-text bold-text mr-2">
                            View
                          </h2>
                          <ChevronRight />
                        </div>
                      </div>
                    </div>
                    <h2 className="template-title bold-text">{temp.title}</h2>
                    <h2 className="template-size">{`Size: ${temp.assetSize}`}</h2>
                    <h3 className="template-description">{temp.bodyShort}</h3>
                  </Col>
                ))}
              </Row>
            ) : (
              <div className="empty" key="latest-templates-empty">
                <Empty description={<span>No templates found.</span>} />
              </div>
            ),
          ]
        )}
      </div>
    </div>
  );
};
