export const selectReducer = (state) => state.templatesByCatAndTag;

export const selectTemplates = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('templatesByCatAndTag').templates
    : [];

export const selectTags = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('templatesByCatAndTag').tags
    : [];

export const selectIsLoading = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('templatesByCatAndTag').isLoading
    : false;
