import { MenuOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { DeleteIcon, EditIcon } from 'assets/images';
import { SortableHandle } from 'react-sortable-hoc';
import {
  formatValidUrl,
  isExternalUrl,
  navigationRoutes,
  STATUS,
} from 'utilities';
import { splitImageFileName } from './common';

const onClickEdit = ({ history, id, tileTypeId }) => {
  history.push(`${navigationRoutes.MapoSingleContentEdit}/${tileTypeId}/${id}`);
};

const DragHandle = SortableHandle(() => (
  <Tooltip>
    <MenuOutlined className="drag-icon" />
  </Tooltip>
));

export const renderColumns = (history, deleteTile) => [
  {
    title: 'Title',
    dataIndex: 'title',
    render: (title) => <p className="bold-text mb-0">{title}</p>,
    width: '12%',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (type) => <p className="mb-0">{type || '---'}</p>,
    width: '10%',
  },
  {
    title: 'Body',
    dataIndex: 'body',
    render: (body) => <p className="tile-desctiption">{body}</p>,
    ellipsis: true,
  },
  {
    title: 'Thumbnail/ Picture',
    dataIndex: 'image',
    render: (imgUrl) =>
      imgUrl ? (
        <a
          className="tile-image bold-text"
          href={imgUrl}
          target="_blank"
          rel="noreferrer">
          {splitImageFileName(imgUrl)}
        </a>
      ) : (
        <p className="mb-0">---</p>
      ),
    width: '10%',
  },
  {
    title: 'Embedded Link',
    dataIndex: 'embeddedLink',
    render: (link) => {
      let path = link;
      if (path && isExternalUrl(path)) {
        path = formatValidUrl(path);
      }
      return link ? (
        <a
          className="tile-embedded-link bold-text"
          href={path}
          target="_blank"
          rel="noreferrer">
          {link}
        </a>
      ) : (
        <p className="mb-0">---</p>
      );
    },
    width: '13%',
    ellipsis: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: (status) => {
      if (status === STATUS.ACTIVE) {
        return <div className="status-active">{status}</div>;
      }

      if (status === STATUS.INACTIVE) {
        return <div className="status-inactive">{status}</div>;
      }

      return null;
    },
    width: '10%',
  },
  {
    title: '',
    render: (text, record) => {
      const { id, tileTypeId } = record;
      return (
        <div className="action">
          <Tooltip
            onClick={() => onClickEdit({ history, id, tileTypeId })}
            className="edit-icon"
            title="Edit"
            placement="bottom">
            <EditIcon />
          </Tooltip>
          <Tooltip
            onClick={() => deleteTile(id)}
            className="delete-icon"
            title="Delete"
            placement="bottom">
            <DeleteIcon />
          </Tooltip>
          <DragHandle />
        </div>
      );
    },
    width: '12%',
  },
];
