import immutable from 'immutable';
import {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  GET_TEMPLATES_BY_TAG,
  GET_TEMPLATES_BY_TAG_SUCCESS,
  GET_TEMPLATES_BY_TAG_FAIL,
} from './action';

const init = () => {
  const initValue = immutable.fromJS({
    templatesTag: undefined,
  });

  return initValue.set('templatesTag', {
    tags: [],
    templates: [],
    isLoading: false,
  });
};

export default function templatesByTagReducer(state = init(), action) {
  switch (action.type) {
    case GET_TAGS:
    case GET_TEMPLATES_BY_TAG:
      return state.update('templatesTag', (templatesTag) => ({
        ...templatesTag,
        isLoading: true,
      }));
    case GET_TAGS_SUCCESS:
      return state.update('templatesTag', (templatesTag) => ({
        ...templatesTag,
        tags: action.payload.data,
        isLoading: false,
      }));
    case GET_TEMPLATES_BY_TAG_SUCCESS:
      return state.update('templatesTag', (templatesTag) => ({
        ...templatesTag,
        templates: action.payload.data,
        isLoading: false,
      }));
    case GET_TAGS_FAIL:
    case GET_TEMPLATES_BY_TAG_FAIL:
      return state;
    default:
      return state;
  }
}
