import immutable from 'immutable';
import {
  GET_TAGS_BY_CAT,
  GET_TAGS_BY_CAT_SUCCESS,
  GET_TAGS_BY_CAT_FAIL,
  GET_TEMPLATES_BY_CAT_AND_TAG,
  GET_TEMPLATES_BY_CAT_AND_TAG_SUCCESS,
  GET_TEMPLATES_BY_CAT_AND_TAG_FAIL,
} from './action';

const init = () => {
  const initValue = immutable.fromJS({
    templatesByCatAndTag: undefined,
  });

  return initValue.set('templatesByCatAndTag', {
    tags: [],
    templates: [],
    isLoading: false,
  });
};

export default function templatesByCatAndTagReducer(state = init(), action) {
  switch (action.type) {
    case GET_TAGS_BY_CAT:
    case GET_TEMPLATES_BY_CAT_AND_TAG:
      return state.update('templatesByCatAndTag', (templatesByCatAndTag) => ({
        ...templatesByCatAndTag,
        isLoading: true,
      }));
    case GET_TAGS_BY_CAT_SUCCESS:
      return state.update('templatesByCatAndTag', (templatesByCatAndTag) => ({
        ...templatesByCatAndTag,
        tags: action.payload.tags,
        templates: action.payload.templates,
        isLoading: false,
      }));
    case GET_TEMPLATES_BY_CAT_AND_TAG_SUCCESS:
      return state.update('templatesByCatAndTag', (templatesByCatAndTag) => ({
        ...templatesByCatAndTag,
        templates: action.payload,
        isLoading: false,
      }));
    case GET_TAGS_BY_CAT_FAIL:
    case GET_TEMPLATES_BY_CAT_AND_TAG_FAIL:
      return state;
    default:
      return state;
  }
}
