import { useState, useCallback, useEffect } from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

const BaseGroupOptions = (props) => {
  const { options, values, onValueChange, isLoading } = props;

  const [selects, setSelects] = useState(values ?? []);

  useEffect(() => {
    setSelects(values ?? []);
  }, [values]);

  const handleOnChange = useCallback(
    (newValue) => {
      const check = selects.filter((i) => i.id === newValue.id);
      let updateVals;
      if (check.length > 0) {
        updateVals = selects.filter((i) => i.id !== newValue.id);
      } else {
        updateVals = [...selects, newValue];
      }
      setSelects(updateVals);
      onValueChange(updateVals);
    },
    [selects, onValueChange],
  );

  return (
    <div className="group-options">
      {isLoading ? (
        <Spin />
      ) : (
        options.map((option) => (
          <button
            key={option.id}
            type="button"
            onClick={() => handleOnChange(option)}
            className={`option bold-text ${
              selects.filter((i) => i.id === option.id).length > 0
                ? 'selected'
                : ''
            }`}>
            {option.name}
          </button>
        ))
      )}
    </div>
  );
};

BaseGroupOptions.defaultProps = {
  values: [],
  isLoading: false,
};

BaseGroupOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.arrayOf(PropTypes.object),
  onValueChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default BaseGroupOptions;
