import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  selectDowloadLink,
  selectEditorLink,
  selectIsGenerating,
  selectIsLoading,
  selectUserTemplateId,
} from 'redux/w2p/chili-editor/selector';
import {
  generateImage,
  generatePdf,
  getEditor,
} from 'redux/w2p/chili-editor/operation';
import Breadcrumb from 'components/UI/Breadcrumb';
import { Spin, Modal, Result, Layout, Select } from 'antd';
import { LeftOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { BaseButton } from 'components/UI/BaseButton';
import { ChevronDown } from 'assets/images';
import { APP_DOMAIN } from 'utilities';
import { REMOVE_DOWNLOAD_LINK } from 'redux/w2p/chili-editor/action';
import {
  DOWNLOAD_TEMPLATE_OPTIONS,
  PDF_PRINT_EXTENSION,
  PNG_EXTENSION,
} from './util/constants';

let editor;
let frameWindow;

export const Web2PublishChiliEditor = () => {
  const { Content } = Layout;
  const { Option } = Select;
  const editorLink = useSelector((state) => selectEditorLink(state));
  const userTemplateId = useSelector((state) => selectUserTemplateId(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const downloadLink = useSelector((state) => selectDowloadLink(state));
  const isGenerating = useSelector((state) => selectIsGenerating(state));

  const [showModal, setShowModal] = useState(false);
  const [downloadExtension, setDownloadExtension] = useState(undefined);

  const dispatch = useDispatch();
  const params = useParams();

  const { state } = useLocation();
  const history = useHistory();

  const onBack = async () => {
    await saveDocument();
    history.goBack();
  };

  const isRightDomain = useMemo(() => {
    return editorLink && window.location.hostname.indexOf(APP_DOMAIN) >= 0;
  }, [editorLink]);

  const saveDocument = async () => {
    if (isRightDomain) {
      editor.ExecuteFunction('document', 'Save');
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }
  };

  const handleOk = () => {
    window.open(downloadLink, '_blank');
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const onDownload = async () => {
    await saveDocument();
    setShowModal(true);
    if (downloadExtension === PNG_EXTENSION) {
      dispatch(generateImage(userTemplateId));
    } else {
      dispatch(
        generatePdf(userTemplateId, downloadExtension === PDF_PRINT_EXTENSION),
      );
    }
  };

  const onSelectDownloadExtension = (value) => {
    setDownloadExtension(value);
  };

  useEffect(() => {
    return () => {
      dispatch({ type: REMOVE_DOWNLOAD_LINK });
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEditor(params.id, params.edit));
  }, [dispatch, params.edit, params.id]);

  useEffect(() => {
    if (downloadLink) {
      window.open(downloadLink, '_blank');
    }
  }, [dispatch, downloadLink]);

  const breadCrumbItems = useMemo(() => {
    const newBreadcrum = [...state];
    newBreadcrum[newBreadcrum.length - 1].onClick = () => window.history.back();
    newBreadcrum.push({ title: 'editor' });
    return newBreadcrum;
  }, [state]);

  const iFrameLoaded = () => {
    frameWindow = document.getElementById('chili-iframe').contentWindow;
    frameWindow.GetEditor(() => {
      editor = frameWindow.editorObject;
    });
  };

  return (
    <Content className="web2Publish-chili-editor-container">
      <div className="breadcrum">
        <Breadcrumb items={breadCrumbItems} noPadding />
      </div>
      <div className="group-header-button middle-content">
        <BaseButton
          className="base-yellow-btn"
          icon={<LeftOutlined />}
          onClick={onBack}>
          Back
        </BaseButton>
        <Select
          className="base-select select-option-download"
          dropdownClassName="select-option-download-dropdown"
          placeholder="Choose download format"
          suffixIcon={<ChevronDown />}
          onChange={onSelectDownloadExtension}>
          {DOWNLOAD_TEMPLATE_OPTIONS.map((option) => (
            <Option value={option.value} key={option.value}>
              {option.name}
            </Option>
          ))}
        </Select>
        <BaseButton
          className="base-yellow-btn download"
          disabled={!downloadExtension}
          icon={<VerticalAlignBottomOutlined />}
          onClick={onDownload}>
          Download
        </BaseButton>
      </div>
      {isLoading ? (
        <div className="loading">
          <Spin />
        </div>
      ) : (
        <iframe
          src={`${editorLink}&d=kaercher.com`}
          title="Chili Editor"
          id="chili-iframe"
          onLoad={isRightDomain ? iFrameLoaded : null}
          height="800"
          width="100%"
        />
      )}
      <Modal
        visible={showModal}
        okText="Download document"
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ loading: isGenerating }}>
        <p>Generate document...</p>
        <div className="text-center">
          {!isGenerating ? (
            <Result status="success" title="Document successfully downloaded" />
          ) : (
            <Spin />
          )}
        </div>
      </Modal>
    </Content>
  );
};
