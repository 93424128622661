import { Spin, Row, Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectMyDocument } from 'redux/w2p/my-documents/selector';
import { getMyDocuments } from 'redux/w2p/my-documents/operation';
import MyTemplateItem from './item';

const MyDocuments = () => {
  const myDocument = useSelector((state) => selectMyDocument(state));

  const onClickBaseTemplateItem = (templateItem) => {
    const downloadLink = templateItem.pdfLink || templateItem.imageLink;
    window.open(downloadLink, '_blank');
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyDocuments());
  }, [dispatch]);

  return (
    <div className="middle-content">
      {myDocument.isLoading ? (
        <div className="loading">
          <Spin />
        </div>
      ) : (
        [
          myDocument.info && myDocument.info.length > 0 ? (
            <Row
              className="my-templates"
              gutter={[
                { xs: 8, sm: 16, md: 24 },
                { xs: 8, sm: 16, md: 24 },
              ]}>
              {myDocument.info
                .sort((a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt))
                .map((temp) => (
                  <MyTemplateItem
                    tempId={temp.id}
                    temp={temp.template}
                    key={temp.id}
                    buttonName="Download"
                    modifiedAt={temp.modifiedAt}
                    fileType={temp.pdfLink ? 'PDF' : 'PNG'}
                    onClick={() => onClickBaseTemplateItem(temp)}
                  />
                ))}
            </Row>
          ) : (
            <div className="empty">
              <Empty description={<span>No templates found.</span>} />
            </div>
          ),
        ]
      )}
    </div>
  );
};

export default MyDocuments;
