import { navigationPaths } from 'utilities';
import Home from 'screens/Home';
import LoginDealerEmail from 'screens/LoginDealerEmail';
import LoginDealerName from 'screens/LoginDealerName';
import LoginDealerNameEmail from 'screens/LoginDealerNameAndEmail';
import Screen403 from 'screens/Error/403';
import ScreenMyProfile from 'screens/MyProfile';
import ScreenImprint from 'screens/Imprint';
import ScreenDisclaimer from 'screens/Disclaimer';
import ScreenPrivacyPolicy from 'screens/PrivacyPolicy';
import MapoRoutes from './mapoRoutes';
import Web2PublishRoutes from './web2PublishRoutes';
import MicroSiteRoutes from './microSiteRoutes';

export default [
  {
    path: navigationPaths.Home,
    component: Home,
    exact: true,
  },
  {
    path: navigationPaths.LoginDealerName,
    component: LoginDealerName,
    exact: true,
  },
  {
    path: navigationPaths.LoginDealerEmail,
    component: LoginDealerEmail,
    exact: true,
  },
  {
    path: navigationPaths.LoginDealerNameEmail,
    component: LoginDealerNameEmail,
    exact: true,
  },
  {
    path: navigationPaths.Error_403,
    component: Screen403,
    exact: true,
  },
  {
    path: navigationPaths.MyProfile,
    component: ScreenMyProfile,
    exact: true,
  },
  {
    path: navigationPaths.Imprint,
    component: ScreenImprint,
    exact: true,
  },
  {
    path: navigationPaths.Disclaimer,
    component: ScreenDisclaimer,
    exact: true,
  },
  {
    path: navigationPaths.PrivacyPolicy,
    component: ScreenPrivacyPolicy,
    exact: true,
  },
  {
    path: navigationPaths.Mapo,
    component: MapoRoutes,
  },
  {
    path: navigationPaths.Web2Publish,
    component: Web2PublishRoutes,
  },
  {
    path: navigationPaths.MicroSite,
    component: MicroSiteRoutes,
  },
];
