import immutable from 'immutable';
import {
  GET_TEMPLATE_DETAIL_FAIL,
  GET_TEMPLATE_DETAIL_REQUEST,
  GET_TEMPLATE_DETAIL_SUCCESS,
  RESET_TEMPLATE_DETAIL,
} from './action';

const init = () => {
  const initValue = immutable.fromJS({
    singleAsset: undefined,
  });

  return initValue.set('singleAsset', {
    info: null,
    isLoading: false,
  });
};

export default function singleAssetReducer(state = init(), action) {
  switch (action.type) {
    case GET_TEMPLATE_DETAIL_REQUEST:
      return state.update('singleAsset', (singleAsset) => ({
        ...singleAsset,
        isLoading: true,
      }));
    case GET_TEMPLATE_DETAIL_SUCCESS: {
      const { payload } = action;
      return state.update('singleAsset', (singleAsset) => ({
        ...singleAsset,
        info: payload,
        isLoading: false,
      }));
    }
    case GET_TEMPLATE_DETAIL_FAIL:
      return state.update('singleAsset', (singleAsset) => ({
        ...singleAsset,
        isLoading: false,
      }));
    case RESET_TEMPLATE_DETAIL:
      return state.update('singleAsset', (singleAsset) => ({
        ...singleAsset,
        info: null,
      }));
    default:
      return state;
  }
}
