import { Col } from 'antd';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import { ChevronRight, imagePlaceHolder } from 'assets/images';

const BaseOverlayItem = ({
  className,
  hoverText,
  path,
  image,
  children,
  isExternalLink,
  isSmallImage = false,
  ...props
}) => {
  return (
    <Col className={`base-overlay-item ${className}`} {...props}>
      <Link
        target={isExternalLink ? '_blank' : '_self'}
        rel={isExternalLink ? 'noreferrer' : ''}
        className="item-image_wrapper"
        to={{ pathname: path }}>
        {isSmallImage ? (
          <div className="item-image-small">
            <img src={image} alt="" />
          </div>
        ) : (
          <img className="item-image" src={image || imagePlaceHolder} alt="" />
        )}
        <div className="item-image_overlay">
          <div className="item-image_overlay-content">
            <h1 className="item-image_overlay-text bold-text mr-2">
              {hoverText}
            </h1>
            <ChevronRight />
          </div>
        </div>
      </Link>
      {children}
    </Col>
  );
};

BaseOverlayItem.propTypes = {
  className: PropType.string,
  hoverText: PropType.string.isRequired,
  path: PropType.oneOfType([PropType.string, PropType.shape({})]).isRequired,
  isExternalLink: PropType.bool,
  image: PropType.string,
  children: PropType.node.isRequired,
  isSmallImage: PropType.bool,
};

BaseOverlayItem.defaultProps = {
  className: '',
  image: '',
  isExternalLink: false,
  isSmallImage: false,
};

export default BaseOverlayItem;
