import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import PropType from 'prop-types';
import BaseTable from '../BaseTable';

const SortableItem = sortableElement((props) => (
  <tr className="test-tr" style={{ backgroundColor: '#000' }} {...props} />
));
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const DraggableTable = ({
  dataSource,
  rowKey = 'id',
  onDrag,
  helperClass,
  ...props
}) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const oldRecord = dataSource[oldIndex];
      const newOrder = newIndex + 1;
      onDrag(oldRecord, newOrder);
    }
  };

  const DraggableContainer = (containerProps) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass={`row-dragging ${helperClass}`}
      onSortEnd={onSortEnd}
      {...containerProps}
    />
  );

  const DraggableBodyRow = (bodyRowProps) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    if (!dataSource.length) return null;
    const index = dataSource.findIndex(
      (x) => x[rowKey] === bodyRowProps['data-row-key'],
    );
    return <SortableItem index={index} {...bodyRowProps} />;
  };

  return (
    <BaseTable
      dataSource={dataSource}
      rowKey={rowKey}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
      {...props}
    />
  );
};

DraggableTable.propTypes = {
  dataSource: PropType.arrayOf(PropType.shape({})),
  rowKey: PropType.string,
  onDrag: PropType.func,
  helperClass: PropType.string,
};

DraggableTable.defaultProps = {
  dataSource: [],
  rowKey: 'id',
  onDrag: () => {},
  helperClass: '',
};

export default DraggableTable;
