import { Maporule, Usermanagement } from 'assets/images';
import immutable from 'immutable';
import { navigationRoutes } from 'utilities';
import {
  GET_CMS_MENU,
  GET_CMS_MENU_SUCCESS,
  GET_CMS_MENU_FAIL,
  ADD_CMS_MENU,
  REMOVE_CMS_MENU,
  ADD_CMS_SUB_MENU,
  EDIT_CMS_MENU,
  REMOVE_CMS_SUB_MENU,
  EDIT_CMS_SUB_MENU,
} from './action';

const init = () => {
  const initValue = immutable.fromJS({
    menuInfo: undefined,
  });

  return initValue.set('menuInfo', {
    menu: [
      {
        id: 'USER_MANAGEMENT',
        title: 'USER <br/>MANAGEMENT',
        embeddedLink: navigationRoutes.UserManagement,
        icon: <Usermanagement className="icon-menu" />,
      },
      {
        id: 'MAPO_MAPPING_RULES',
        title: 'MAPO MAPPING <br/>RULES',
        embeddedLink: navigationRoutes.MapoRule,
        icon: <Maporule className="icon-menu" />,
      },
      {
        id: 'CONTENT_MANAGEMENT',
        title: 'CONTENT <br/>MANAGEMENT',
        embeddedLink: navigationRoutes.MapoContent,
        children: [
          {
            id: 'MAPO',
            title: 'MAPO MAIN PAGE',
            embeddedLink: navigationRoutes.MapoContent,
          },
          {
            id: 'W2P',
            title: 'WEB 2 PUBLISH',
            embeddedLink: navigationRoutes.W2PContent,
          },
        ],
      },
    ],
    isLoading: false,
  });
};

export default function cmsMenuReducer(state = init(), action) {
  switch (action.type) {
    case GET_CMS_MENU:
      return state.update('menuInfo', (menuInfo) => ({
        ...menuInfo,
        isLoading: true,
      }));
    case ADD_CMS_MENU:
      return state.update('menuInfo', (menuInfo) => {
        const menuRes = [...menuInfo.menu];
        const newMenu = action.payload;
        menuRes[2].children.push(newMenu);
        return {
          ...menuInfo,
          menu: menuRes,
        };
      });
    case ADD_CMS_SUB_MENU:
      return state.update('menuInfo', (menuInfo) => {
        const menuRes = [...menuInfo.menu];
        const newMenu = action.payload;
        menuRes[2].children.forEach((menu) => {
          // eslint-disable-next-line eqeqeq
          if (menu.id == newMenu.tileId) {
            menu.children.push({
              id: newMenu.id,
              embeddedLink: newMenu.embeddedLink,
              title: newMenu.title,
            });
          }
        });

        return {
          ...menuInfo,
          menu: menuRes,
        };
      });
    case REMOVE_CMS_SUB_MENU:
      return state.update('menuInfo', (menuInfo) => {
        const menuRes = [...menuInfo.menu];
        const newMenu = action.payload;
        menuRes[2].children?.forEach((menu) => {
          if (menu.children) {
            const findChild = menu.children.findIndex(
              (child) => child.id === newMenu,
            );
            if (findChild >= 0) {
              menu.children = menu.children.filter((e) => e.id !== newMenu);
            }
          }
        });

        return {
          ...menuInfo,
          menu: menuRes,
        };
      });
    case REMOVE_CMS_MENU:
      return state.update('menuInfo', (menuInfo) => {
        const menuRes = [...menuInfo.menu];
        const id = action.payload;
        menuRes[2].children = menuRes[2].children.filter((e) => e.id !== id);
        return {
          ...menuInfo,
          menu: menuRes,
        };
      });
    case EDIT_CMS_MENU:
      return state.update('menuInfo', (menuInfo) => {
        const menuRes = [...menuInfo.menu];
        const newMenu = action.payload;
        const updatedIndex = menuRes[2].children.findIndex(
          (e) => e.id === newMenu.id,
        );
        if (updatedIndex >= 0) {
          menuRes[2].children[updatedIndex].title = newMenu.title;
        }
        return {
          ...menuInfo,
          menu: menuRes,
        };
      });
    case EDIT_CMS_SUB_MENU:
      return state.update('menuInfo', (menuInfo) => {
        const menuRes = [...menuInfo.menu];
        const newMenu = action.payload;
        menuRes[2].children?.forEach((menu) => {
          if (menu.children) {
            const findChild = menu.children.findIndex(
              (child) => child.id === newMenu.id,
            );
            if (findChild >= 0) {
              menu.children[findChild].title = newMenu.title;
            }
          }
        });
        return {
          ...menuInfo,
          menu: menuRes,
        };
      });
    case GET_CMS_MENU_SUCCESS: {
      return state.update('menuInfo', (menuInfo) => {
        const menuRes = [...menuInfo.menu];
        const newMenu = action.payload;
        newMenu.forEach((menu) => {
          if (
            menuRes[2].children.filter((e) => e.id === menu.id).length === 0
          ) {
            menuRes[2].children.push(menu);
          }
        });

        return {
          ...menuInfo,
          menu: menuRes,
          isLoading: false,
        };
      });
    }

    case GET_CMS_MENU_FAIL:
      return state.update('menuInfo', (menuInfo) => ({
        ...menuInfo,
        isLoading: false,
      }));

    default:
      return state;
  }
}
