import immutable from 'immutable';
import { CLEAR_USER_INFO, SET_USER_INFO } from './action';

const init = () => {
  const initValue = immutable.fromJS({
    user: undefined,
  });

  return initValue.set('user', {});
};

export default function authReducer(state = init(), action) {
  switch (action.type) {
    case SET_USER_INFO:
      return state.update('user', (userData) => ({
        ...userData,
        ...action.payload,
        fullname:
          action.payload.given_name && action.payload.family_name
            ? `${action.payload.given_name} ${action.payload.family_name}`
            : '',
      }));

    case CLEAR_USER_INFO:
      return state.set('user', {});

    default:
      return state;
  }
}
