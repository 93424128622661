export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const GET_USER_DETAIL_FAIL = 'GET_USER_DETAIL_FAIL';
export const UPDATE_USER_DETAIL_SUCCESS = 'UPDATE_USER_DETAIL_SUCCESS';
export const UPDATE_USER_DETAIL_FAIL = 'UPDATE_USER_DETAIL_FAIL';
export const RESET_USER_DETAIL = 'RESET_USER_DETAIL';

export const GET_USER_REGION_SUCCESS = 'GET_USER__REGION_SUCCESS';
export const GET_USER_REGION_FAIL = 'GET_USER_REGION_FAIL';

export const GET_USER_COMPANY_SUCCESS = 'GET_USER_COMPANY_SUCCESS';
export const GET_USER_COMPANY_FAIL = 'GET_USER_COMPANY_FAIL';

export const GET_JOB_TYPE_SUCCESS = 'GET_JOB_TYPE_SUCCESS';
export const GET_JOB_TYPE__FAIL = 'GET_JOB_TYPE__FAIL';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const SET_USER_ADMIN_ROLE_REQUEST = 'SET_USER_ADMIN_ROLE_REQUEST';
export const SET_USER_ADMIN_ROLE_SUCCESS = 'SET_USER_ADMIN_ROLE_SUCCESS';
export const SET_USER_ADMIN_ROLE_FAIL = 'SET_USER_ADMIN_ROLE_FAIL';

export const SET_USER_VIEW_ALL_TILES_REQUEST =
  'SET_USER_VIEW_ALL_TILES_REQUEST';
export const SET_USER_VIEW_ALL_TILES_SUCCESS =
  'SET_USER_VIEW_ALL_TILES_SUCCESS';
export const SET_USER_VIEW_ALL_TILES_FAIL = 'SET_USER_VIEW_ALL_TILES_FAIL';
