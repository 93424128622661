import { Input } from 'antd';
import PropTypes from 'prop-types';

const BaseInput = (props) => {
  const { className, ...rest } = props;
  const customClassName = `base-input ${className}`;
  return <Input {...rest} className={customClassName} />;
};

BaseInput.defaultProps = {
  className: '',
};

BaseInput.propTypes = {
  className: PropTypes.string,
};

export default BaseInput;
