import { Layout, Dropdown } from 'antd';
import { logo, logoBlack, UserIcon } from 'assets/images';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { navigationRoutes } from 'utilities';
import { useSelector } from 'react-redux';
import { selectUserRole } from 'redux/home/selector';
import { selectHeaderTitle } from 'redux/global/header/selector';
import useCheckLocation from 'hooks/useCheckLocation';
import LogoutMenu from './components/LogoutMenu';

const HeaderComponent = () => {
  const { Header } = Layout;
  const history = useHistory();
  const {
    isInW2P,
    isInW2PWelcomPage,
    isInAdministration,
    isInMicroSite,
    isInStaticPage,
    isInSubmitInfoPage,
  } = useCheckLocation();

  const { headerTitle, userRole } = useSelector((state) => ({
    headerTitle: selectHeaderTitle(state),
    userRole: selectUserRole(state),
  }));

  const isDisplayAdminButton = useMemo(
    () =>
      !isInW2P &&
      !isInStaticPage &&
      !isInMicroSite &&
      !!userRole &&
      userRole.id === 2,
    [isInW2P, isInStaticPage, isInMicroSite, userRole],
  );

  const handleLogo = () =>
    isInW2P
      ? history.push(navigationRoutes.Web2PublishMain)
      : history.push(navigationRoutes.Home);

  const onAdminClick = () => {
    history.push(navigationRoutes.UserManagement);
  };

  return (
    <Header
      className={`header-wrapper ${
        isInW2P ? 'background-grey' : 'background-white'
      } ${isInSubmitInfoPage ? 'd-none' : ''}`}>
      <div className="header-content middle-content d-flex align-center">
        <div
          className="header_logo"
          onClick={() => handleLogo()}
          aria-hidden="true">
          <img
            className="header_logo--image"
            src={isInW2P ? logo : logoBlack}
            alt="logo"
          />
        </div>
        <span
          className={`header_title font-size-big ${
            isInW2P ? 'header_title--white' : ''
          }`}>
          {headerTitle}
        </span>
        <div className="header_buttons d-flex align-center">
          {isDisplayAdminButton ? (
            <button
              type="button"
              onClick={onAdminClick}
              className={`admin_button ${
                isInAdministration ? 'admin_button--active' : ''
              }`}>
              Administration
            </button>
          ) : null}
          {!isInW2PWelcomPage ? (
            <Dropdown
              overlay={LogoutMenu({ isInW2P })}
              overlayClassName="header-buttons-logout"
              placement="bottomRight"
              trigger={['click']}>
              <div className="header_user">
                <UserIcon
                  className={
                    isInW2P ? 'header_user-icon' : 'header_user-icon-black'
                  }
                />
              </div>
            </Dropdown>
          ) : null}
        </div>
      </div>
    </Header>
  );
};

export default HeaderComponent;
