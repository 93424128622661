import { useEffect, useMemo } from 'react';
import { Layout, Row, Col, Spin } from 'antd';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from 'components/UI/Breadcrumb';
import { selectSingleAsset } from 'redux/w2p/single-asset/selector';
import {
  getSingleAsset,
  resetTemplateDetail,
} from 'redux/w2p/single-asset/operation';
import { BaseButton } from 'components/UI/BaseButton';
import { navigationRoutes } from 'utilities';

export const SingleAsset = () => {
  const { Content } = Layout;
  const { state } = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const { singleAsset } = useSelector((stateRedux) => ({
    singleAsset: selectSingleAsset(stateRedux),
  }));

  useEffect(() => {
    dispatch(resetTemplateDetail());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSingleAsset(params.templateId));
  }, [dispatch, params]);

  const onBack = () => {
    history.goBack();
  };

  const onGoEditor = () => {
    const newBreadcrum = breadCrumbItems.slice(-1);
    history.push(
      `${navigationRoutes.W2PTemplateEditor}/${singleAsset.info.id}`,
      newBreadcrum,
    );
  };

  const breadCrumbItems = useMemo(() => {
    const newBreadcrum = [...state];
    newBreadcrum[newBreadcrum.length - 1].onClick = () => window.history.back();
    newBreadcrum.push({ title: singleAsset.info?.title });
    return newBreadcrum;
  }, [state, singleAsset.info]);

  return (
    <Content className="singleAsset-main-container">
      <Breadcrumb items={breadCrumbItems ?? []} />

      {singleAsset.isLoading ? (
        <div className="loading">
          <Spin />
        </div>
      ) : (
        singleAsset.info && (
          <div className="middle-content">
            <div className="back-button mt-4">
              <BaseButton
                className="base-yellow-btn"
                icon={<LeftOutlined />}
                onClick={onBack}>
                Back
              </BaseButton>
            </div>
            <Row className="mt-4" gutter={24}>
              <Col md={8} lg={10}>
                <img className="image" src={singleAsset.info.image} alt="" />
              </Col>
              <Col md={16} lg={14}>
                <h1 className="title bold-text font-size-big uppercase-text">
                  {singleAsset.info.title}
                </h1>
                <h3 className="description">{singleAsset.info.bodyLong}</h3>
                <h2 className="bold-text font-size-medium mb-2">Category</h2>
                <div className="d-flex mb-4">
                  {singleAsset.info.categories?.map((item) => (
                    <div
                      key={item.id}
                      className="label bold-text d-flex align-center justify-center flex-column mr-3">
                      {item.name}
                    </div>
                  ))}
                </div>
                <h2 className="bold-text font-size-medium mb-2">Tags</h2>
                <div className="d-flex">
                  {singleAsset.info.tags?.map((item) => (
                    <div
                      key={item.id}
                      className="label bold-text d-flex align-center justify-center flex-column mr-3">
                      {item.name}
                    </div>
                  ))}
                </div>
                <h2 className="bold-text font-size-medium mb-2 mt-5">Size</h2>
                <div className="d-flex">{singleAsset.info.assetSize}</div>
              </Col>
            </Row>

            <div className="start-editing-button mt-4">
              <BaseButton onClick={onGoEditor} className="base-yellow-btn">
                START EDITING
              </BaseButton>
            </div>
          </div>
        )
      )}
    </Content>
  );
};
