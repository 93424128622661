/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useCallback, useEffect } from 'react';
import { Checkbox, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import BaseTable from 'components/UI/BaseTable';
import {
  getCompany,
  resetCompany,
  updateRegion,
  updateSingleCompany,
  updateUserType,
  updateJobType,
  updateSelectedRegions,
  updateAllRegion,
} from 'redux/administration/content-management/w2p-single-content/operation';
import {
  renderCompanyColumns,
  renderRegionColumns,
} from './util/render-columns';

const MappingRule = ({ ruleItem, selectAllRegion }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSelectedRowKeysChange = useCallback((data) => {
    dispatch(updateSelectedRegions(data, ruleItem));
  }, []);

  const selectRow = useCallback(
    (record) => {
      const newSelectedRowKeys = [...ruleItem.selectedRegions];
      if (newSelectedRowKeys.indexOf(record.id) >= 0) {
        newSelectedRowKeys.splice(newSelectedRowKeys.indexOf(record.id), 1);
        dispatch(updateRegion(false, ruleItem, record));
      } else {
        newSelectedRowKeys.push(record.id);
      }
      dispatch(updateSelectedRegions(newSelectedRowKeys, ruleItem));
    },
    [ruleItem.selectedRegions],
  );

  useEffect(() => {
    if (ruleItem.companiesByRegion.length > 0) {
      setLoading(false);
    }
  }, [ruleItem.companiesByRegion]);

  useEffect(() => {
    if (ruleItem.selectedRegions.length > 0) {
      const regionIds = ruleItem.selectedRegions.join(',');
      dispatch(getCompany(regionIds, ruleItem));
    }
  }, [ruleItem.selectedRegions, dispatch]);

  const rowSelection = useMemo(() => {
    return {
      selectedRowKeys: ruleItem.selectedRegions,
      onChange: onSelectedRowKeysChange,
    };
  }, [ruleItem.selectedRegions]);

  const onClickRegion = useCallback(
    (record, rule) => {
      setLoading(true);
      dispatch(resetCompany(rule));
      selectRow(record);
    },
    [selectRow, dispatch],
  );

  const onCheckRegion = useCallback(
    (event, region) => {
      if (
        event.target.checked &&
        !ruleItem.selectedRegions.includes(region.id)
      ) {
        dispatch(updateSelectedRegions(null, ruleItem, region.id));
      }
      dispatch(updateRegion(event.target.checked, ruleItem, region));
    },
    [dispatch, ruleItem],
  );

  const onCheckCompany = useCallback(
    (event, company) => {
      dispatch(updateSingleCompany(event.target.checked, ruleItem, company));
    },
    [dispatch, ruleItem],
  );

  const onCheckRegionAll = useCallback(
    (event) => {
      const newSelectedRegions = [...ruleItem.selectedRegions];
      if (
        event.target.checked &&
        newSelectedRegions.length !== ruleItem.regions.length
      ) {
        ruleItem.regions.forEach((region) => {
          if (!newSelectedRegions.includes(region.id)) {
            newSelectedRegions.push(region.id);
          }
        });
        dispatch(updateSelectedRegions(newSelectedRegions, ruleItem));
      }
      dispatch(updateAllRegion(event.target.checked, ruleItem));
    },
    [dispatch, ruleItem],
  );

  const onCheckUserType = (event, item) => {
    dispatch(updateUserType(event.target.checked, ruleItem, item));
  };

  const onCheckJobType = (event, item) => {
    dispatch(updateJobType(event.target.checked, ruleItem, item));
  };

  const regionColumns = useMemo(() => {
    return renderRegionColumns(onClickRegion, ruleItem, onCheckRegion);
  }, [onClickRegion, ruleItem, onCheckRegion]);

  const companyColumns = useMemo(() => {
    return renderCompanyColumns(onCheckCompany);
  }, [onCheckCompany]);

  const tableRegionProps = useMemo(
    () => ({
      columns: regionColumns,
      rowSelection,
      dataSource: ruleItem.regions,
      rowKey: 'id',
      pagination: false,
      className: 'region-management-table',
    }),
    [regionColumns, rowSelection, ruleItem],
  );

  const tableCompanyProps = useMemo(
    () => ({
      columns: companyColumns,
      dataSource: ruleItem.companiesByRegion,
      rowKey: 'id',
      pagination: false,
      loading,
      scroll: { y: 700 },
      className: 'company-management-table',
    }),
    [companyColumns, ruleItem, loading],
  );

  return (
    <>
      {ruleItem && (
        <div className="d-flex">
          <div className="width-30">
            <Checkbox
              className="ml-4 mb-4 mt-4"
              checked={selectAllRegion}
              onChange={(event) => onCheckRegionAll(event)}>
              Select all regions
            </Checkbox>
            <BaseTable {...tableRegionProps} />
          </div>

          <div className="template-access width-50 pt-4 ml-4">
            <span className="pl-5">SELECT THE USER TYPE</span>
            <br />
            <div className="pl-5 mt-3">
              {ruleItem.userTypes.map((item) => {
                return (
                  <Checkbox
                    checked={item.isChecked}
                    onChange={(event) => onCheckUserType(event, item)}
                    key={item.id}
                    className="user-type mr-6">
                    {item.name}
                  </Checkbox>
                );
              })}
            </div>
            <br />
            <span className="pl-5">SELECT THE JOB TYPE</span>
            <br />
            <div className="pl-5 mt-3">
              <Row>
                {ruleItem.jobTypes.map((item) => {
                  return (
                    <Col xs={24} md={8} lg={8} key={item.name}>
                      <Checkbox
                        checked={item.isChecked}
                        key={item.id}
                        onChange={(event) => onCheckJobType(event, item)}>
                        {item.name}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </div>
            <br />
            <span className="pl-5">SELECT THE COMPANY</span>
            <br />
            <BaseTable {...tableCompanyProps} />
          </div>
        </div>
      )}
    </>
  );
};

MappingRule.propTypes = {
  ruleItem: PropTypes.objectOf.isRequired,
  selectAllRegion: PropTypes.bool.isRequired,
};

export default MappingRule;
