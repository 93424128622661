import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BaseButton } from 'components/UI/BaseButton';
import { useSelector } from 'react-redux';
import { selectStartPageData, selectIsLoading } from 'redux/microSite/selector';
import { Spin } from 'antd';
import ScrollToTop from 'components/ScrollToTop';

const ViewStartPage = ({ onEdit }) => {
  const { isLoading, startPage } = useSelector((state) => ({
    isLoading: selectIsLoading(state),
    startPage: selectStartPageData(state),
  }));

  return isLoading ? (
    <div className="loading">
      <Spin />
    </div>
  ) : (
    <div className="view-start-page">
      <section className="site-informaton-item">
        <p className="bold-text">Headline</p>
        <p>{startPage.headline}</p>
      </section>
      <section className="site-informaton-item">
        <p className="bold-text">Text headline</p>
        <p>{startPage.textHeadline}</p>
      </section>
      <section className="site-informaton-item">
        <p className="bold-text">Media</p>
        <img
          className="site-informaton-item-image"
          src={startPage.mediaHeadline}
          alt=""
        />
      </section>
      {startPage.subPages && startPage.subPages.length > 0
        ? startPage.subPages.map((subPage) => (
            <div className="sub-page-items" key={subPage.embededLink}>
              <section className="site-informaton-item">
                <p className="bold-text">Subheadline title</p>
                <p>{subPage.title}</p>
              </section>
              <section className="site-informaton-item">
                <p className="bold-text">Text headline</p>
                <p className="site-informaton-item-text-headline">
                  {subPage.textHeadline}
                </p>
              </section>
              <section className="site-informaton-item">
                <p className="bold-text">Status</p>
                <p className="site-informaton-item-status">{subPage.status}</p>
              </section>
              {subPage.contentLink ? (
                <section className="site-informaton-item">
                  <p className="bold-text">Content Link</p>
                  <Link to={subPage.contentLink}>{subPage.contentLink}</Link>
                </section>
              ) : null}
              {subPage.mediaHeadline ? (
                <section className="site-informaton-item">
                  <p className="bold-text">Media Support</p>
                  <img
                    className="site-informaton-item-image"
                    src={subPage.mediaHeadline}
                    alt=""
                  />
                </section>
              ) : null}
            </div>
          ))
        : null}
      <BaseButton className="edit-button base-yellow-btn" onClick={onEdit}>
        EDIT CONTENT
      </BaseButton>
      <ScrollToTop />
    </div>
  );
};

ViewStartPage.propTypes = {
  startPage: PropTypes.shape({
    headline: PropTypes.string,
    textHeadline: PropTypes.string,
    mediaHeadline: PropTypes.string,
    subPages: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onEdit: PropTypes.func.isRequired,
};

ViewStartPage.defaultProps = {
  startPage: {},
};

export default ViewStartPage;
