import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import PreviewComponent from 'components/UI/PreviewComponent';
import { selectGlobalUserInfo } from 'redux/global/auth/selector';
import { selectPreviewTile } from 'redux/administration/content-management/mapo-single-content/selector';
import { selectTiles } from 'redux/home/selector';
import { headerTitle, STATUS } from 'utilities';
import { imagePlaceHolder } from 'assets/images';

const Preview = () => {
  const { globalUserInfo, tiles, previewTile } = useSelector((state) => ({
    globalUserInfo: selectGlobalUserInfo(state),
    tiles: selectTiles(state),
    previewTile: selectPreviewTile(state),
  }));

  const activeTiles = useMemo(() => {
    const result =
      tiles.length > 0
        ? tiles
            .filter((t) => t.isAccess === true && t.role.isViewable === true)
            .map(({ tile }) => tile)
        : [];

    if (previewTile) {
      const existTileIndex = result.findIndex((t) => t.id === previewTile.id);
      if (existTileIndex >= 0) {
        result[existTileIndex] = previewTile;
      } else {
        result.push(previewTile);
      }
    }

    return result
      .filter((t) => t.status === STATUS.ACTIVE)
      .sort((a, b) => a.tileOrder - b.tileOrder);
  }, [tiles, previewTile]);

  return (
    <PreviewComponent
      contentClassName="preview-home"
      headerTitle={headerTitle.mapo}>
      <div className="preview-home-content-welcome d-flex flex-column align-center justify-center">
        <p className="preview-home-breadcrumb middle-content">Home</p>
        <p className="middle-content bold-text">{globalUserInfo?.name}</p>
      </div>
      <div className="middle-content">
        <Row className="mt-4 mb-4" gutter={[4, 4]}>
          <Col
            xs={24}
            md={8}
            sm={24}
            key="preview-home_welcome-content"
            className="preview-home-welcome-text d-flex flex-column">
            <h2 className="bold-text">Welcome Text</h2>
            <h3>
              Welcome to the Kärcher Marketing Portal. From here, you can access
              various pieces of marketing information and applications. This
              portal will be expanded gradually.
            </h3>
          </Col>
          {activeTiles.map((tile) => (
            <Col
              className="preview-home-content-item"
              xs={24}
              md={8}
              sm={24}
              key={tile.id}>
              <img
                className="item-image"
                src={tile.image || imagePlaceHolder}
                alt=""
              />
              <h2 className="item-title bold-text">{tile.title}</h2>
              <h3 className="item-description overflow-hidden">{tile.body}</h3>
            </Col>
          ))}
        </Row>
      </div>
    </PreviewComponent>
  );
};

export default Preview;
