import { AuthenticateService, UserService } from 'services';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { Auth } from 'utilities';
import { CLEAR_USER_INFO } from 'redux/global/auth/action';
import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  UPDATE_USER_LANGUAGE_REQUEST,
  UPDATE_USER_LANGUAGE_SUCCESS,
  UPDATE_USER_LANGUAGE_ERROR,
  UPDATE_USER_INFO_REQUEST,
  UPDATE_USER_INFO_ERROR,
  UPDATE_USER_INFO,
} from './action';

export const getUserProfile = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: GET_USER_PROFILE_REQUEST });
    const result = await AuthenticateService.getUserProfile();

    dispatch(hideLoading());
    dispatch({
      type: GET_USER_PROFILE_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    dispatch({ type: GET_USER_PROFILE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const updateUserLanguage = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: UPDATE_USER_LANGUAGE_REQUEST });
    await UserService.updateUserLanguage(id, data);

    dispatch(hideLoading());
    dispatch({
      type: UPDATE_USER_LANGUAGE_SUCCESS,
      payload: { langCode: data.langCode },
    });
  } catch (error) {
    dispatch({ type: UPDATE_USER_LANGUAGE_ERROR, error });
    dispatch(hideLoading());
  }
};

export const updateUserInfo = (data, history) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: UPDATE_USER_INFO_REQUEST });
    await AuthenticateService.updateUserInfo(data);
    dispatch(hideLoading());
    await Auth.signOut();
    dispatch({
      type: UPDATE_USER_INFO_SUCCESS,
    });
    dispatch({
      type: CLEAR_USER_INFO,
    });
    history.push('/');
  } catch (error) {
    dispatch({ type: UPDATE_USER_INFO_ERROR, error });
    dispatch(hideLoading());
  }
};
