import { TileService, MicroSiteService } from 'services';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { navigationRoutes, STATUS, SUB_PAGE_TYPES, toast } from 'utilities';
import {
  ADD_CMS_SUB_MENU,
  EDIT_CMS_MENU,
  EDIT_CMS_SUB_MENU,
  REMOVE_CMS_SUB_MENU,
} from 'redux/administration/menu/action';
import {
  GET_START_PAGE_REQUEST,
  GET_START_PAGE_SUCCESS,
  GET_START_PAGE_FAIL,
  UPDATE_START_PAGE_REQUEST,
  UPDATE_START_PAGE_SUCCESS,
  UPDATE_START_PAGE_FAIL,
  UPDATE_PREVIEW_START_PAGE,
  RESET_START_PAGE_DATA,
  DELETE_SUB_PAGE_REQUEST,
  DELETE_SUB_PAGE_SUCCESS,
  DELETE_SUB_PAGE_FAIL,
  GET_TEXT_SUB_PAGE_REQUEST,
  GET_TEXT_SUB_PAGE_SUCCESS,
  GET_TEXT_SUB_PAGE_FAIL,
  CREATE_TEXT_SUB_PAGE_REQUEST,
  CREATE_TEXT_SUB_PAGE_SUCCESS,
  CREATE_TEXT_SUB_PAGE_FAIL,
  UPDATE_TEXT_SUB_PAGE_REQUEST,
  UPDATE_TEXT_SUB_PAGE_SUCCESS,
  UPDATE_TEXT_SUB_PAGE_FAIL,
  UPDATE_PREVIEW_TEXT_SUB_PAGE,
  RESET_TEXT_SUB_PAGE_DATA,
  GET_MEDIA_SUB_PAGE_REQUEST,
  GET_MEDIA_SUB_PAGE_SUCCESS,
  GET_MEDIA_SUB_PAGE_FAIL,
  CREATE_MEDIA_SUB_PAGE_REQUEST,
  CREATE_MEDIA_SUB_PAGE_SUCCESS,
  CREATE_MEDIA_SUB_PAGE_FAIL,
  UPDATE_MEDIA_SUB_PAGE_REQUEST,
  UPDATE_MEDIA_SUB_PAGE_SUCCESS,
  UPDATE_MEDIA_SUB_PAGE_FAIL,
  UPDATE_PREVIEW_MEDIA_SUB_PAGE,
  RESET_MEDIA_SUB_PAGE_DATA,
} from './action';

export const getStartPage = (id, isEditing) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_START_PAGE_REQUEST });
    const result = await TileService.getTileById(id, isEditing);
    const { id: tileId, title, startPage, image } = result.data;
    const textSubPages = isEditing
      ? result.data.textSubPages
      : result.data.activeTextSubPages;
    const mediaSubPages = isEditing
      ? result.data.mediaSubPages
      : result.data.activeMediaSubPages;
    startPage.title = title;
    startPage.mediaHeadline = image;
    startPage.subPages = [];

    if (textSubPages && textSubPages.length > 0) {
      textSubPages.forEach((item) => {
        startPage.subPages.push({
          id: item.id,
          title: item.title,
          textHeadline: item.textHeadline,
          embededLink: `${navigationRoutes.MicroSite}/${tileId}${navigationRoutes.MicroSiteTextSubPage}/${item.id}`,
          mediaHeadline: item.mediaHeadline,
          status: item.status ? STATUS.ACTIVE : STATUS.INACTIVE,
          type: SUB_PAGE_TYPES.TEXT,
        });
      });
    }

    if (mediaSubPages && mediaSubPages.length > 0) {
      mediaSubPages.forEach((item) => {
        startPage.subPages.push({
          id: item.id,
          title: item.title,
          textHeadline: item.textHeadline,
          embededLink: `${navigationRoutes.MicroSite}/${tileId}${navigationRoutes.MicroSiteMediaSubPage}/${item.id}`,
          mediaHeadline: item.mediaHeadline,
          status: item.status ? STATUS.ACTIVE : STATUS.INACTIVE,
          type: SUB_PAGE_TYPES.MEDIA,
        });
      });
    }

    dispatch(hideLoading());
    dispatch({
      type: GET_START_PAGE_SUCCESS,
      payload: startPage,
    });
  } catch (err) {
    dispatch({ type: GET_START_PAGE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const updateStartPage = (id, startPage) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: UPDATE_START_PAGE_REQUEST });
    const result = await MicroSiteService.updateStartPage(id, startPage);
    dispatch(hideLoading());
    dispatch({ type: UPDATE_START_PAGE_SUCCESS, payload: result });
    dispatch({
      type: EDIT_CMS_MENU,
      payload: {
        id: result.data.tileId,
        title: result.data.headline,
      },
    });
    toast('success', 'Start Page', 'Updated Successfully');
  } catch (err) {
    dispatch({ type: UPDATE_START_PAGE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const getTextSubPage = (id) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_TEXT_SUB_PAGE_REQUEST });

    const result = await TileService.getTextSubPage(id);
    dispatch(hideLoading());
    dispatch({
      type: GET_TEXT_SUB_PAGE_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    dispatch({ type: GET_TEXT_SUB_PAGE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const createTextSubPage = (subPage) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: CREATE_TEXT_SUB_PAGE_REQUEST });
    const result = await MicroSiteService.createTextSubPage(subPage);
    dispatch(hideLoading());
    dispatch({ type: CREATE_TEXT_SUB_PAGE_SUCCESS, payload: result });
    const { data } = result;
    dispatch({
      type: ADD_CMS_SUB_MENU,
      payload: {
        id: `SubText_${data.id}`,
        title: data.title,
        embeddedLink: `/mapo-administration/micro-site/${data.tileId}/text-sub-page/${data.id}`,
        tileId: data.tileId,
      },
    });
    toast('success', 'Text Sub Page', 'Created Successfully');
  } catch (err) {
    dispatch({ type: CREATE_TEXT_SUB_PAGE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const updateTextSubPage = (id, subPage) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: UPDATE_TEXT_SUB_PAGE_REQUEST });
    const result = await MicroSiteService.updateTextSubPage(id, subPage);
    dispatch(hideLoading());
    dispatch({ type: UPDATE_TEXT_SUB_PAGE_SUCCESS, payload: result });
    dispatch({
      type: EDIT_CMS_SUB_MENU,
      payload: {
        id: `SubText_${id}`,
        title: result.data.title,
      },
    });
    toast('success', 'Text Sub Page', 'Updated Successfully');
  } catch (err) {
    dispatch({ type: UPDATE_TEXT_SUB_PAGE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const deleteTextSubPage = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: DELETE_SUB_PAGE_REQUEST });
    await MicroSiteService.deleteTextSubPage(id);
    dispatch(hideLoading());
    dispatch({
      type: DELETE_SUB_PAGE_SUCCESS,
      payload: { id, type: SUB_PAGE_TYPES.TEXT },
    });
    dispatch({
      type: REMOVE_CMS_SUB_MENU,
      payload: `SubText_${id}`,
    });
    toast('success', 'Text Sub Page', 'Delete Successfully');
  } catch (err) {
    dispatch({ type: DELETE_SUB_PAGE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const getMediaSubPage = (id) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_MEDIA_SUB_PAGE_REQUEST });

    const result = await TileService.getMediaSubPage(id);
    dispatch(hideLoading());
    dispatch({
      type: GET_MEDIA_SUB_PAGE_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    dispatch({ type: GET_MEDIA_SUB_PAGE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const createMediaSubPage = (subPage) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: CREATE_MEDIA_SUB_PAGE_REQUEST });
    const result = await MicroSiteService.createMediaSubPage(subPage);
    dispatch(hideLoading());
    dispatch({ type: CREATE_MEDIA_SUB_PAGE_SUCCESS, payload: result });
    const { data } = result;
    dispatch({
      type: ADD_CMS_SUB_MENU,
      payload: {
        id: `SubMedia_${data.id}`,
        title: data.title,
        embeddedLink: `/mapo-administration/micro-site/${data.tileId}/media-sub-page/${data.id}`,
        tileId: data.tileId,
      },
    });
    toast('success', 'Media Sub Page', 'Created Successfully');
  } catch (err) {
    dispatch({ type: CREATE_MEDIA_SUB_PAGE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const updateMediaSubPage = (id, subPage) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: UPDATE_MEDIA_SUB_PAGE_REQUEST });
    const result = await MicroSiteService.updateMediaSubPage(id, subPage);
    dispatch(hideLoading());
    dispatch({ type: UPDATE_MEDIA_SUB_PAGE_SUCCESS, payload: result });
    dispatch({
      type: EDIT_CMS_SUB_MENU,
      payload: {
        id: `SubMedia_${id}`,
        title: result.data.title,
      },
    });
    toast('success', 'Media Sub Page', 'Updated Successfully');
  } catch (err) {
    dispatch({ type: UPDATE_MEDIA_SUB_PAGE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const deleteMediaSubPage = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: DELETE_SUB_PAGE_REQUEST });
    await MicroSiteService.deleteMediaSubPage(id);
    dispatch(hideLoading());
    dispatch({
      type: DELETE_SUB_PAGE_SUCCESS,
      payload: { id, type: SUB_PAGE_TYPES.MEDIA },
    });
    dispatch({
      type: REMOVE_CMS_SUB_MENU,
      payload: `SubMedia_${id}`,
    });
    toast('success', 'Media Sub Page', 'Delete Successfully');
  } catch (err) {
    dispatch({ type: DELETE_SUB_PAGE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const updatePreviewStartPage = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PREVIEW_START_PAGE,
    payload: data,
  });
};

export const resetStartPageData = () => (dispatch) => {
  dispatch({ type: RESET_START_PAGE_DATA });
};

export const updatePreviewTextSubPage = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PREVIEW_TEXT_SUB_PAGE,
    payload: data,
  });
};

export const resetTextSubPageData = () => (dispatch) => {
  dispatch({ type: RESET_TEXT_SUB_PAGE_DATA });
};

export const updatePreviewMediaSubPage = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PREVIEW_MEDIA_SUB_PAGE,
    payload: data,
  });
};

export const resetMediaSubPageData = () => (dispatch) => {
  dispatch({ type: RESET_MEDIA_SUB_PAGE_DATA });
};
