import { Table } from 'antd';
import PropTypes from 'prop-types';

const BaseTable = (props) => {
  const { className, ...rest } = props;
  const customClassName = `base-table ${className}`;
  return <Table {...rest} className={customClassName} />;
};

BaseTable.defaultProps = {
  className: '',
};

BaseTable.propTypes = {
  className: PropTypes.string,
};

export default BaseTable;
