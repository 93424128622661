import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { REMOVE_CMS_MENU } from 'redux/administration/menu/action';
import { TileService } from 'services';
import { toast } from 'utilities';
import {
  GET_TILES_REQUEST,
  GET_TILES_SUCCESS,
  GET_TILES_FAIL,
  DELETE_TILE_REQUEST,
  DELETE_TILE_SUCCESS,
  DELETE_TILE_FAIL,
  CHANGE_ORDER_TILE_REQUEST,
  CHANGE_ORDER_TILE_SUCCESS,
  CHANGE_ORDER_TILE_FAIL,
} from './action';

export const getTiles =
  ({ page, limit }, params) =>
  async (dispatch) => {
    try {
      // Loading
      dispatch(showLoading());
      dispatch({ type: GET_TILES_REQUEST });
      const result = await TileService.getTiles(page, limit, params);
      dispatch(hideLoading());
      dispatch({ type: GET_TILES_SUCCESS, payload: result });
    } catch (err) {
      dispatch({ type: GET_TILES_FAIL, err });
      dispatch(hideLoading());
    }
  };

export const deleteTile = (id) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: DELETE_TILE_REQUEST });
    await TileService.deleteTile(id);
    dispatch(hideLoading());
    dispatch({ type: DELETE_TILE_SUCCESS, payload: id });
    dispatch({ type: REMOVE_CMS_MENU, payload: id });

    toast('success', 'Tile', 'Delete Successfully');
  } catch (err) {
    dispatch({ type: DELETE_TILE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const changeOrderTile = (id, order) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: CHANGE_ORDER_TILE_REQUEST });
    const result = await TileService.updateTile(id, order);
    dispatch(hideLoading());
    dispatch({ type: CHANGE_ORDER_TILE_SUCCESS, payload: result });
    toast('success', 'Tile', 'Update Successfully');
  } catch (err) {
    dispatch({ type: CHANGE_ORDER_TILE_FAIL });
    dispatch(hideLoading());
  }
};
