import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { navigationRoutes, ROUTE } from 'utilities';

const useCheckLocation = () => {
  const { pathname } = useLocation();
  const [isInW2P, setIsInW2P] = useState(false);
  const [isInW2PWelcomPage, setIsInW2PWelcomPage] = useState(false);
  const [isInAdministration, setIsInAdministration] = useState(false);
  const [isInStaticPage, setIsInStaticPage] = useState(false);
  const [isInMicroSite, setIsInMicroSite] = useState(false);
  const [isInSubmitInfoPage, setisInSubmitInfoPage] = useState(false);

  useEffect(() => {
    if (pathname.indexOf(ROUTE.HOME) > 0) {
      setIsInW2P(false);
      setIsInAdministration(false);
      setIsInW2PWelcomPage(false);
      setIsInMicroSite(false);
      setIsInStaticPage(false);
      setisInSubmitInfoPage(false);
    }
    if (pathname.indexOf(ROUTE.W2P) > 0) {
      setIsInW2P(true);
      setIsInAdministration(false);
      setIsInMicroSite(false);
      setIsInStaticPage(false);
      if (pathname === navigationRoutes.Web2PublishWelcome) {
        setIsInW2PWelcomPage(true);
      } else {
        setIsInW2PWelcomPage(false);
      }
      setisInSubmitInfoPage(false);
    }
    if (pathname.indexOf(ROUTE.MAPO) > 0) {
      setIsInW2P(false);
      setIsInAdministration(true);
      setIsInMicroSite(false);
      setIsInW2PWelcomPage(false);
      setIsInStaticPage(false);
      setisInSubmitInfoPage(false);
    }
    if (
      pathname.indexOf(ROUTE.MICRO_SITE) > 0 &&
      pathname.indexOf(ROUTE.MAPO) === -1
    ) {
      setIsInW2P(false);
      setIsInAdministration(false);
      setIsInMicroSite(true);
      setIsInW2PWelcomPage(false);
      setIsInStaticPage(false);
      setisInSubmitInfoPage(false);
    }
    if (
      (pathname.indexOf(ROUTE.MY_PROFILE) > 0 ||
        pathname.indexOf(ROUTE.IMPRINT) > 0 ||
        pathname.indexOf(ROUTE.DISCLAIMER) > 0) &&
      pathname.indexOf(ROUTE.MAPO) === -1 &&
      pathname.indexOf(ROUTE.W2P) === -1
    ) {
      setIsInW2P(false);
      setIsInAdministration(false);
      setIsInMicroSite(false);
      setIsInW2PWelcomPage(false);
      setIsInStaticPage(true);
      setisInSubmitInfoPage(false);
    }
    if (pathname.indexOf(ROUTE.SUBMIT_INFO) > 0) {
      setIsInW2P(false);
      setIsInAdministration(false);
      setIsInMicroSite(false);
      setIsInW2PWelcomPage(false);
      setIsInStaticPage(false);
      setisInSubmitInfoPage(true);
    }
  }, [pathname]);

  return {
    isInW2P,
    isInW2PWelcomPage,
    isInAdministration,
    isInMicroSite,
    isInStaticPage,
    isInSubmitInfoPage,
  };
};

export default useCheckLocation;
