import { Col, Layout, Row } from 'antd';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { navigationPaths } from 'utilities';
import { MailOutlined } from '@ant-design/icons';
import useCheckLocation from 'hooks/useCheckLocation';

const FooterComponent = () => {
  const { Footer } = Layout;
  const { isInW2P, isInSubmitInfoPage } = useCheckLocation();

  const contactEmail = useMemo(
    () =>
      isInW2P
        ? 'web2publish@de.kaercher.com'
        : 'marketingportal@de.kaercher.com',
    [isInW2P],
  );

  return (
    <Footer className={`footer ${isInSubmitInfoPage ? 'd-none' : ''}`}>
      <div className={`content_footer ${isInW2P ? 'background-grey' : ''}`}>
        <div className="middle-content">
          <Row className="content_footer-middle pt-7 pb-7" gutter={[150, 15]}>
            <Col
              xs={24}
              md={10}
              sm={24}
              className="content_footer-item d-flex flex-column align-start">
              <h3 className="bold-text">LEGAL INFORMATION</h3>
              <Link
                to={
                  isInW2P ? navigationPaths.W2PImprint : navigationPaths.Imprint
                }>
                Imprint
              </Link>
              <Link
                to={
                  isInW2P
                    ? navigationPaths.W2PDisclaimer
                    : navigationPaths.Disclaimer
                }>
                Disclaimer
              </Link>
              <Link
                to={
                  isInW2P
                    ? navigationPaths.W2PPrivacyPolicy
                    : navigationPaths.PrivacyPolicy
                }>
                Privacy Information
              </Link>
              {isInW2P ? (
                <Link to={navigationPaths.W2PTermsAndConditions}>
                  Terms &amp; Conditions
                </Link>
              ) : null}
            </Col>
            <Col
              xs={24}
              md={14}
              sm={24}
              className="content_footer-item d-flex flex-column align-start">
              <h3 className="bold-text">CONTACT</h3>
              <a href={`mailto:${contactEmail}`}>
                {contactEmail}
                <MailOutlined className="email-icon" />
              </a>
            </Col>
          </Row>
        </div>
      </div>

      <div
        className={`content_footer-tail d-flex align-center ${
          isInW2P ? 'background-grey' : ''
        }`}>
        <span className="bold-text middle-content">
          © {new Date().getFullYear()} Alfred Kärcher SE & Co. KG
        </span>
      </div>
    </Footer>
  );
};

export default FooterComponent;
