import immutable from 'immutable';
import {
  GET_EDITOR,
  GET_EDITOR_FAIL,
  GET_EDITOR_SUCCESS,
  GENERATE_PDF,
  GENERATE_PDF_SUCCESS,
  GENERATE_PDF_FAIL,
  GENERATE_IMAGE,
  GENERATE_IMAGE_SUCCESS,
  GENERATE_IMAGE_FAIL,
  REMOVE_DOWNLOAD_LINK,
} from './action';

const init = () => {
  const initValue = immutable.fromJS({
    editTemplate: undefined,
  });

  return initValue.set('editTemplate', {
    editorLink: null,
    userTemplateId: null,
    dowloadLink: null,
    isLoading: false,
    isGenerating: false,
  });
};

export default function editTemplateReducer(state = init(), action) {
  switch (action.type) {
    case GET_EDITOR:
      return state.update('editTemplate', (editTemplate) => ({
        ...editTemplate,
        isLoading: true,
      }));
    case GET_EDITOR_SUCCESS:
      return state.update('editTemplate', (editTemplate) => ({
        ...editTemplate,
        editorLink: action.payload.link,
        userTemplateId: action.payload.id,
        isLoading: false,
      }));
    case REMOVE_DOWNLOAD_LINK:
      return state.update('editTemplate', (editTemplate) => ({
        ...editTemplate,
        dowloadLink: null,
      }));
    case GENERATE_PDF:
    case GENERATE_IMAGE:
      return state.update('editTemplate', (editTemplate) => ({
        ...editTemplate,
        dowloadLink: null,
        isGenerating: true,
      }));
    case GENERATE_PDF_SUCCESS:
    case GENERATE_IMAGE_SUCCESS:
      return state.update('editTemplate', (editTemplate) => ({
        ...editTemplate,
        dowloadLink: action.payload.data,
        isGenerating: false,
      }));
    case GET_EDITOR_FAIL:
    case GENERATE_PDF_FAIL:
    case GENERATE_IMAGE_FAIL:
    default:
      return state;
  }
}
