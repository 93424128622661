import { Layout } from 'antd';
import { useHistory } from 'react-router-dom';
import { BaseButton } from 'components/UI/BaseButton';
import useHeader from 'hooks/useHeader';
import { navigationRoutes } from 'utilities';
import useTitle from 'hooks/useTitle';

const ForbiddenAccess = () => {
  useHeader('');
  useTitle('403');
  const history = useHistory();
  const { Content } = Layout;

  const handleReturnHome = () => {
    history.push(navigationRoutes.Home);
  };

  return (
    <Content className="forbidden-content">
      <div className="forbidden-content_container d-flex flex-column align-center">
        <h1 className="bold-text mb-6 mt-10 forbidden-content-code">403</h1>
        <span className="bold-text mb-6 forbidden-content-text">
          Access Denied
        </span>
        <span className="bold-text mb-6 forbidden-content-text">
          You dont have permission to access requested page.
        </span>
        <BaseButton className="base-yellow-btn" onClick={handleReturnHome}>
          RETURN HOME
        </BaseButton>
      </div>
    </Content>
  );
};

export default ForbiddenAccess;
