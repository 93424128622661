export const selectReducer = (state) => state.microSite;

export const selectIsLoading = (state) =>
  selectReducer(state).get('isLoading') || false;

export const selectStartPageData = (state) =>
  selectReducer(state).get('startPage').data || {};

export const selectStartPageUpdate = (state) =>
  selectReducer(state).get('startPage').update || {};

export const selectPreviewStartPage = (state) =>
  selectReducer(state).get('startPage').preview || {};

export const selectDeleteSubPage = (state) =>
  selectReducer(state).get('startPage').deleteSubPage || {};

export const selectTextSubPage = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('textSubPage').data
    : undefined;

export const selectCreateTextSubPage = (state) =>
  selectReducer(state).get('textSubPage').create || {};

export const selectUpdateTextSubPage = (state) =>
  selectReducer(state).get('textSubPage').update || {};

export const selectPreviewTextSubPage = (state) =>
  selectReducer(state).get('textSubPage').preview || {};

export const selectMediaSubPage = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('mediaSubPage').data
    : undefined;

export const selectPreviewMediaSubPage = (state) =>
  selectReducer(state).get('mediaSubPage').preview || {};

export const selectCreateMediaSubPage = (state) =>
  selectReducer(state).get('mediaSubPage').create || {};

export const selectUpdateMediaSubPage = (state) =>
  selectReducer(state).get('mediaSubPage').update || {};
