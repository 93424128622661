import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import PropType from 'prop-types';
import { AuthenticateService, S3Service } from 'services';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const BaseUpload = ({ onUploadChange, initialValue, uploadProps }) => {
  const [preview, setPreview] = useState({
    visible: false,
    image: '',
    title: '',
  });

  const [fileList, setFileList] = useState(
    initialValue
      ? [
          {
            uid: '-xxx',
            name: 'Defaul',
            status: 'done',
            url: initialValue,
          },
        ]
      : [],
  );

  useEffect(() => {
    if (initialValue) {
      setFileList([
        {
          uid: '-xxx',
          name: 'Defaul',
          status: 'done',
          url: initialValue,
        },
      ]);
    }
  }, [initialValue]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreview({
      image: file.url || file.preview,
      visible: true,
      title: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleupload = async (file) => {
    setFileList([
      {
        uid: '-xxx',
        name: file.name,
        status: 'uploading',
      },
    ]);
    const res = await AuthenticateService.getSingedUrl(file.name);
    const urlData = res.data;
    try {
      const uploadS3 = await S3Service.uploadTos3(urlData.signedUrl, file);
      if (uploadS3.status === 200) {
        const urlDownload = urlData.uploadedUrl;
        setFileList([
          {
            uid: '-xxx',
            name: file.name,
            status: 'done',
            url: urlDownload,
          },
        ]);

        onUploadChange(urlDownload);
      }
    } catch (error) {
      setFileList([
        {
          uid: '-xxx',
          name: file.name,
          status: 'error',
        },
      ]);
    }
    return false;
  };

  const handleRemove = (file) => {
    let lists = [...fileList];
    lists = lists.filter((i) => i.uid !== file.uid);
    onUploadChange(null);
    setFileList(lists);
  };

  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        beforeUpload={handleupload}
        onRemove={handleRemove}
        {...uploadProps}>
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      <Modal
        visible={preview.visible}
        title={preview.title}
        footer={null}
        onCancel={() => setPreview({ visible: false })}>
        <img alt="example" style={{ width: '100%' }} src={preview.image} />
      </Modal>
    </>
  );
};

BaseUpload.defaultProps = {
  onUploadChange: () => {},
  initialValue: '',
  uploadProps: {},
};

BaseUpload.propTypes = {
  onUploadChange: PropType.func,
  initialValue: PropType.string,
  uploadProps: PropType.shape({}),
};
