export const GET_EDITOR = 'GET_EDITOR';
export const GET_EDITOR_SUCCESS = 'GET_EDITOR_SUCCESS';
export const GET_EDITOR_FAIL = 'GET_EDITOR_FAIL';

export const GENERATE_PDF = 'GENERATE_PDF';
export const GENERATE_PDF_SUCCESS = 'GENERATE_PDF_SUCCESS';
export const GENERATE_PDF_FAIL = 'GENERATE_PDF_FAIL';

export const GENERATE_IMAGE = 'GENERATE_IMAGE';
export const GENERATE_IMAGE_SUCCESS = 'GENERATE_IMAGE_SUCCESS';
export const GENERATE_IMAGE_FAIL = 'GENERATE_IMAGE_FAIL';

export const REMOVE_DOWNLOAD_LINK = 'REMOVE_DOWNLOAD_LINK';
