import immutable from 'immutable';
import { CREATE_STATUS, UPDATE_STATUS } from 'utilities';
import {
  GET_TILE_TYPES_REQUEST,
  GET_TILE_TYPES_REQUEST_SUCCESS,
  GET_TILE_TYPES_REQUEST_FAIL,
  CREATE_REQUEST,
  CREATE_REQUEST_SUCCESS,
  CREATE_REQUEST_FAIL,
  UPDATE_TILE_REQUEST,
  UPDATE_TILE_SUCCESS,
  UPDATE_TILE_FAIL,
  GET_TILE_BY_ID,
  GET_TILE_BY_ID_SUCCESS,
  GET_TILE_BY_ID_FAIL,
  RESET_MAPO_SINGLE_CONTENT,
  UPDATE_PREVIEW_TILE,
} from './action';

const init = () => {
  const initValue = immutable.fromJS({
    mapoContent: undefined,
  });

  return initValue.set('mapoContent', {
    tileTypes: [],
    isLoading: false,
    tileContent: undefined,
    create: {
      status: CREATE_STATUS.DEFAULT,
      tileId: undefined,
      isLoading: false,
    },
    update: {
      status: UPDATE_STATUS.DEFAULT,
      tileId: undefined,
      isLoading: false,
    },
    preview: undefined,
  });
};

export default function mapoSingleContentReducer(state = init(), action) {
  switch (action.type) {
    case GET_TILE_TYPES_REQUEST:
    case GET_TILE_BY_ID:
      return state.update('mapoContent', (mapoContent) => ({
        ...mapoContent,
        isLoading: true,
      }));
    case GET_TILE_TYPES_REQUEST_SUCCESS:
      return state.update('mapoContent', (mapoContent) => ({
        ...mapoContent,
        tileTypes: action.payload.data,
        isLoading: false,
      }));

    case GET_TILE_TYPES_REQUEST_FAIL:
      return state.update('mapoContent', (mapoContent) => ({
        ...mapoContent,
        isLoading: false,
      }));
    case CREATE_REQUEST:
      return state.update('mapoContent', (mapoContent) => ({
        ...mapoContent,
        create: {
          ...mapoContent.create,
          status: CREATE_STATUS.DEFAULT,
          isLoading: true,
        },
        isLoading: true,
      }));
    case CREATE_REQUEST_SUCCESS:
      return state.update('mapoContent', (mapoContent) => ({
        ...mapoContent,
        create: {
          status: CREATE_STATUS.SUCCESS,
          tileId: action.payload.data.id,
          isLoading: false,
        },
        isLoading: false,
      }));
    case CREATE_REQUEST_FAIL: {
      return state.update('mapoContent', (mapoContent) => ({
        ...mapoContent,
        create: {
          ...mapoContent.create,
          status: CREATE_STATUS.FAIL,
          isLoading: false,
        },
        isLoading: false,
      }));
    }
    case UPDATE_TILE_REQUEST:
      return state.update('mapoContent', (mapoContent) => ({
        ...mapoContent,
        update: {
          ...mapoContent.update,
          status: UPDATE_STATUS.DEFAULT,
          isLoading: true,
        },
      }));
    case UPDATE_TILE_SUCCESS:
      return state.update('mapoContent', (mapoContent) => ({
        ...mapoContent,
        update: {
          status: UPDATE_STATUS.SUCCESS,
          tileId: action.payload.data.id,
          isLoading: false,
        },
      }));
    case UPDATE_TILE_FAIL:
      return state.update('mapoContent', (mapoContent) => ({
        ...mapoContent,
        update: {
          ...mapoContent.update,
          status: UPDATE_STATUS.FAIL,
          isLoading: false,
        },
      }));
    case GET_TILE_BY_ID_SUCCESS: {
      return state.update('mapoContent', (mapoContent) => ({
        ...mapoContent,
        tileContent: action.payload.tile,
        tileTypes: action.payload.types,
        isLoading: false,
      }));
    }
    case UPDATE_PREVIEW_TILE: {
      const { payload } = action;
      return state.update('mapoContent', (mapoContent) => ({
        ...mapoContent,
        preview: {
          ...mapoContent.preview,
          ...payload,
        },
      }));
    }

    case RESET_MAPO_SINGLE_CONTENT:
      return state.update('mapoContent', () => ({
        tileTypes: [],
        isLoading: false,
        tileContent: undefined,
        create: {
          status: CREATE_STATUS.DEFAULT,
          tileId: undefined,
          isLoading: false,
        },
        update: {
          status: UPDATE_STATUS.DEFAULT,
          tileId: undefined,
          isLoading: false,
        },
        preview: undefined,
      }));
    case GET_TILE_BY_ID_FAIL:
    default:
      return state;
  }
}
