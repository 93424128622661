import { Tabs } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import PropType from 'prop-types';

const { TabPane } = Tabs;

const BaseTabs = (props) => {
  const { initialPanes, isResetKey = false, ...rest } = props;
  const [activeKey, setActiveKey] = useState(initialPanes[0].key);
  const [randomKey, setRandomKey] = useState(Date.now().toString());

  const onResetKey = useCallback((key) => {
    setActiveKey(key);
    setRandomKey(Date.now().toString());
  }, []);

  useEffect(() => {
    if (isResetKey) {
      setActiveKey(initialPanes[0].key);
      setRandomKey(Date.now().toString());
    }
  }, [isResetKey, initialPanes]);

  return (
    <div className="base-tabs">
      <Tabs
        type="card"
        onChange={onResetKey}
        activeKey={activeKey}
        key={randomKey}
        {...rest}>
        {initialPanes.map((pane) => (
          <TabPane tab={pane.title} key={pane.key} closable={pane.closable}>
            {pane.content}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

BaseTabs.propTypes = {
  initialPanes: PropType.arrayOf(PropType.object).isRequired,
  isResetKey: PropType.bool,
};

BaseTabs.defaultProps = {
  isResetKey: false,
};

export default BaseTabs;
