import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Auth, navigationRoutes } from 'utilities';
import { BaseButton } from 'components/UI/BaseButton';
import { Menu } from 'antd';
import PropType from 'prop-types';
import { CLEAR_USER_INFO } from 'redux/global/auth/action';
import { Template, UserIcon } from 'assets/images';

const LogoutMenu = ({ isInW2P }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    await Auth.signOut();
    dispatch({
      type: CLEAR_USER_INFO,
    });
    history.push('/');
  };

  const goMyTemplate = () => {
    history.push(navigationRoutes.MyTemplate);
  };

  const onViewProfile = () => {
    if (isInW2P) {
      history.push(navigationRoutes.Web2PublishMyProfile);
    } else {
      history.push(navigationRoutes.MyProfile);
    }
  };

  return (
    <Menu className="logout-menu d-flex flex-column justify-space-around">
      <Menu.Item className="logout-menu-item-icon" key="logout-menu-item">
        <div
          className="logout-menu_icon d-flex flex-column align-center"
          aria-hidden="true"
          onClick={onViewProfile}>
          <UserIcon className="icon-menu" />
          <span className="title">My Profile</span>
        </div>
        {isInW2P && (
          <div
            className="logout-menu_icon d-flex flex-column align-center ml-1"
            aria-hidden="true"
            onClick={goMyTemplate}>
            <Template className="icon-menu" />
            <span className="title">My Templates</span>
          </div>
        )}
      </Menu.Item>
      <Menu.Item className="text-center" key="logout-menu-button">
        <div className="logout-menu_button">
          <BaseButton
            className="base-yellow-btn"
            type="primary"
            onClick={handleLogout}>
            LOG OUT
          </BaseButton>
        </div>
      </Menu.Item>
    </Menu>
  );
};

LogoutMenu.propTypes = {
  isInW2P: PropType.bool.isRequired,
};

export default LogoutMenu;
