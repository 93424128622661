import { useState } from 'react';
import { Modal, Col, Layout, Row } from 'antd';
import PropType from 'prop-types';
import Breadcrumb from 'components/UI/Breadcrumb';
import { BaseButton } from 'components/UI/BaseButton';
import { logoBlack, UserIcon } from 'assets/images';
import { MailOutlined } from '@ant-design/icons';

const PreviewComponent = ({
  children,
  title,
  breadCrumbItems,
  contentClassName,
  modalClassName,
  isHideFooter = false,
  isHasModal = false,
}) => {
  const { Header, Content, Footer } = Layout;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onShowModal = () => {
    setIsModalVisible(true);
  };

  const onCloseModal = () => {
    setIsModalVisible(false);
  };

  const renderPreviewContent = (className) => (
    <>
      <Header
        className={`${className}-header middle-content dflex align-center justify-space-between`}>
        <div className={`${className}-header-content d-flex align-center`}>
          <img className="header-image" src={logoBlack} alt="logo" />
          <span className="header-title">MARKETING PORTAL</span>
          <div className="header-icon d-flex align-center">
            <UserIcon className="icon" />
          </div>
        </div>
      </Header>
      <Content
        className={`${className}-content ${
          className === 'preview-component' ? contentClassName : modalClassName
        }`}>
        {breadCrumbItems && breadCrumbItems.length > 0 ? (
          <Breadcrumb items={breadCrumbItems} />
        ) : null}
        {children}
      </Content>
      {!isHideFooter ? (
        <>
          <div className={`${className}-arrow d-flex align-center justify-end`}>
            <i className={`${className}-arrow_icon`} />
          </div>
          <Footer className={`${className}-footer`}>
            <div className={`${className}-footer-content`}>
              <div className="middle-content">
                <Row
                  className={`${className}-footer-row pt-2 pb-2`}
                  gutter={[10]}>
                  <Col
                    xs={24}
                    md={10}
                    sm={24}
                    className={`${className}-footer-item d-flex flex-column align-start`}>
                    <h3 className="bold-text">LEGAL INFORMATION</h3>
                    <p>Imprint</p>
                    <p>Disclaimer</p>
                    <p>Privacy Information</p>
                  </Col>
                  <Col
                    xs={24}
                    md={14}
                    sm={24}
                    className={`${className}-footer-item d-flex flex-column align-start`}>
                    <h3 className="bold-text">CONTACT</h3>
                    <p className="bold-text">
                      marketingportal@de.kaercher.com{' '}
                      <MailOutlined className="email-icon" />
                    </p>
                  </Col>
                </Row>
              </div>
            </div>

            <div className={`${className}-footer-tail d-flex align-center`}>
              <span className="bold-text middle-content">
                © {new Date().getFullYear()} Alfred Kärcher SE & Co. KG
              </span>
            </div>
          </Footer>
        </>
      ) : null}
    </>
  );

  return (
    <>
      <p className="preview-text">PREVIEW</p>
      <div
        aria-hidden="true"
        className="preview-component"
        onClick={onShowModal}>
        {renderPreviewContent('preview-component')}
      </div>
      {isHasModal ? (
        <Modal
          className="preview-component-modal"
          title={title}
          width="75%"
          visible={isModalVisible}
          onCancel={onCloseModal}
          footer={null}>
          {renderPreviewContent('preview-modal')}
          <div className="close-button">
            <BaseButton className="bold-text" onClick={onCloseModal}>
              CLOSE
            </BaseButton>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

PreviewComponent.propTypes = {
  title: PropType.string,
  breadCrumbItems: PropType.arrayOf(PropType.shape({})),
  children: PropType.oneOfType([
    PropType.arrayOf(PropType.node),
    PropType.node,
  ]),
  contentClassName: PropType.string,
  modalClassName: PropType.string,
  isHideFooter: PropType.bool,
  isHasModal: PropType.bool,
};

PreviewComponent.defaultProps = {
  title: '',
  breadCrumbItems: undefined,
  children: undefined,
  contentClassName: '',
  modalClassName: '',
  isHideFooter: false,
  isHasModal: false,
};

export default PreviewComponent;
