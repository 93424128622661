import { BaseUpload } from 'components/UI/BaseUpload';
import { Layout, Row, Col, Form, Input, Radio, Spin } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import BaseInput from 'components/UI/BaseInput';
import {
  CREATE_STATUS,
  navigationRoutes,
  STATUS,
  UPDATE_STATUS,
} from 'utilities';
import Breadcrumb from 'components/UI/Breadcrumb';
import { BaseButton } from 'components/UI/BaseButton';
import {
  createTile,
  getTileById,
  getTileTypes,
  resetTileContent,
  updatePreviewTile,
  updateTile,
} from 'redux/administration/content-management/mapo-single-content/operation';
import {
  selectIsLoading,
  selectTileContent,
  selectTileTypes,
  selectCreateTile,
  selectUpdateTile,
} from 'redux/administration/content-management/mapo-single-content/selector';
import { getAuthentication } from 'redux/home/operation';
import { TILE_FIELDS } from './util/constants';
import Preview from './Preview';

const MapoSingleContent = () => {
  const { Content } = Layout;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams();

  const typingTimeoutRef = useRef(null);

  const [formValue, setFormValue] = useState({
    title: null,
    body: null,
    tileTypeId: params.type,
    embeddedLink: null,
    image: null,
    status: 'inactive',
  });

  useEffect(() => {
    return () => {
      dispatch(resetTileContent());
    };
  }, [dispatch]);

  const { tileTypes, isLoading, tileContent, create, update } = useSelector(
    (state) => ({
      tileTypes: selectTileTypes(state) ?? [],
      isLoading: selectIsLoading(state),
      tileContent: selectTileContent(state),
      create: selectCreateTile(state),
      update: selectUpdateTile(state),
    }),
  );

  useEffect(() => {
    // dispatch(getAuthentication());
    if (params.id) {
      dispatch(getTileById(params.id));
    } else {
      dispatch(getTileTypes());
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (
      create.status === CREATE_STATUS.SUCCESS ||
      update.status === UPDATE_STATUS.SUCCESS
    ) {
      history.push(navigationRoutes.MapoContent);
    }
  }, [history, create, update]);

  useEffect(() => {
    if (params.id && tileContent) {
      dispatch(
        updatePreviewTile({
          id: tileContent.id,
          title: tileContent.title,
          body: tileContent.body,
          image: tileContent.image,
          status: tileContent.status,
          tileOrder: tileContent.tileOrder,
        }),
      );
    }
  }, [dispatch, tileContent, params.id]);

  const onValueChange = useCallback(
    (name, value) => {
      if (
        name === TILE_FIELDS.TITLE ||
        name === TILE_FIELDS.BODY ||
        name === TILE_FIELDS.IMAGE ||
        name === TILE_FIELDS.STATUS
      ) {
        clearTimeout(typingTimeoutRef.current);
        typingTimeoutRef.current = setTimeout(() => {
          dispatch(updatePreviewTile({ [name]: value }));
        }, 500);
      }
      switch (name) {
        case TILE_FIELDS.TITLE:
          setFormValue({ ...formValue, title: value });
          break;
        case TILE_FIELDS.BODY:
          setFormValue({ ...formValue, body: value });
          break;
        case TILE_FIELDS.EMBEDDEDLINK:
          setFormValue({ ...formValue, embeddedLink: value });
          break;
        case TILE_FIELDS.IMAGE:
          setFormValue({ ...formValue, image: value });
          break;
        case TILE_FIELDS.STATUS:
          setFormValue({ ...formValue, status: value });
          break;
        default:
      }
    },
    [dispatch, formValue],
  );

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Home,
      },
      {
        title: 'Administration',
        path: navigationRoutes.UserManagement,
      },
      {
        title: 'Content Management',
        path: navigationRoutes.MapoContent,
      },
      {
        title: 'Mapo Main Page',
        path: navigationRoutes.MapoContent,
      },
      {
        title: params.id && tileContent ? tileContent.title : '',
      },
    ],
    [params, tileContent],
  );

  const isEdit = useMemo(() => {
    return !!params.id;
  }, [params]);

  useEffect(() => {
    if (isEdit && tileContent) {
      setFormValue({
        title: tileContent.title,
        body: tileContent.body,
        tileTypeId: params.type,
        embeddedLink: tileContent.embeddedLink,
        image: tileContent.image,
        status: tileContent.status,
      });
    }
  }, [isEdit, tileContent, params.type]);

  const onFinish = () => {
    if (isEdit) {
      dispatch(updateTile(params.id, formValue));
    } else {
      dispatch(createTile(formValue));
    }
  };

  const type = useMemo(() => {
    if (tileTypes && tileTypes.length > 0) {
      // eslint-disable-next-line eqeqeq
      const check = tileTypes.filter((e) => e.id == params.type);
      if (check.length > 0) return check[0].name;
    }
    return 'N/A';
  }, [tileTypes, params]);

  if (isEdit && isLoading)
    return (
      <div className="loading middle-content">
        <Spin />
      </div>
    );

  return (
    <Content className="mapo-administrator-w2p-content-single">
      <Breadcrumb items={breadCrumbItems} />
      <div className="w2p-content-single">
        {isEdit && tileContent && (
          <div className="w2p-content-single-general">
            <div className="middle-content pt-5 pb-5">
              <h1 className="bold-text">{tileContent.title} </h1>
              <Row>
                <Col xs={24} md={8} lg={8}>
                  <div className="d-flex flex-column align-start">
                    <span className="bold-text label">ID</span>
                    <span>{tileContent.id}</span>
                  </div>
                </Col>
                <Col xs={24} md={8} lg={8}>
                  <div className="d-flex flex-column align-start">
                    <span className="bold-text label">CONTENT STATUS</span>
                    <span
                      className={`status-${
                        tileContent?.status?.toLowerCase() === STATUS.ACTIVE
                          ? 'active'
                          : 'inactive'
                      } width-20`}>
                      {tileContent.status}
                    </span>
                  </div>
                </Col>
                <Col xs={24} md={8} lg={8}>
                  <div className="d-flex flex-column align-start">
                    <span className="bold-text label">EMBEDDEDLINK</span>
                    <span>{tileContent.embeddedLink}</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
        <div className="mapo-content-single-information pt-5 pb-5">
          <div className="middle-content">
            <Form
              onFinish={onFinish}
              initialValues={
                isEdit && tileContent
                  ? {
                      title: tileContent.title,
                      body: tileContent.body,
                      tileTypeId: params.type,
                      embeddedLink: tileContent.embeddedLink,
                      image: tileContent.image,
                      status: tileContent.status,
                    }
                  : {
                      title: null,
                      body: null,
                      tileTypeId: params.type,
                      embeddedLink: null,
                      image: null,
                      status: 'inactive',
                    }
              }
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              autoComplete="off"
              className="w2p-content-single-form"
              layout="vertical">
              <Row>
                <Col xs={24} md={16} lg={16}>
                  <h3>CONTENT INFORMATION </h3>
                  <Row>
                    <Col md={24} lg={24} className="mt-4">
                      <Form.Item label="TILE TYPE" name="tileTypeId">
                        <span className="type-box">{type}</span>
                      </Form.Item>
                    </Col>
                    <Col md={24} lg={24} className="mt-4">
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'Please input your title!',
                          },
                        ]}
                        label="TITLE"
                        name="title">
                        <BaseInput
                          onChange={(e) =>
                            onValueChange('title', e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col md={24} lg={24} className="mt-4">
                      <Form.Item label="BODY" name="body">
                        <TextArea
                          onChange={(e) =>
                            onValueChange('body', e.target.value)
                          }
                          className="textarea-content-single"
                        />
                      </Form.Item>
                    </Col>
                    {params.type !== '2' && (
                      <Col md={24} lg={24} className="mt-4">
                        <Form.Item label="EMBEDDEDLINK" name="embeddedLink">
                          <BaseInput
                            className="long-text"
                            onChange={(e) =>
                              onValueChange('embeddedLink', e.target.value)
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col md={24} lg={24} className="mt-4">
                      <Form.Item label="MEDIA" name="image">
                        <BaseUpload
                          initialValue={isEdit ? tileContent?.image : null}
                          onUploadChange={(val) => onValueChange('image', val)}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} lg={12} className="mt-4">
                      <Form.Item>
                        <BaseButton
                          onClick={() =>
                            history.push(navigationRoutes.MapoContent)
                          }
                          className="btn-cancel">
                          CANCEL
                        </BaseButton>
                      </Form.Item>
                    </Col>
                    <Col md={12} lg={12} className="mt-4">
                      <Form.Item>
                        <BaseButton
                          className="base-yellow-btn btn-save-changes"
                          loading={isEdit ? update.isLoading : create.isLoading}
                          htmlType="submit">
                          SAVE CHANGES
                        </BaseButton>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={8} lg={8}>
                  <h3>CONTENT STATUS </h3>
                  <Form.Item name="status" className="group-status mt-4">
                    <Radio.Group
                      onChange={(e) => onValueChange('status', e.target.value)}>
                      <Radio.Button value="active">
                        <span className="label-radio">ACTIVE</span>
                      </Radio.Button>
                      <Radio.Button value="inactive" className="ml-4">
                        <span className="label-radio">INACTIVE</span>
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Col xs={24} md={8} lg={8}>
              <Preview />
            </Col>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default MapoSingleContent;
