import immutable from 'immutable';
import {
  GET_USER_TILE_ROLES,
  GET_USER_TILE_ROLES_FAIL,
  GET_USER_TILE_ROLES_SUCCESS,
} from './action';

const init = () => {
  const initValue = immutable.fromJS({
    tile: undefined,
  });

  return initValue.set('tile', {
    tiles: [],
    isLoading: false,
    userRole: null,
  });
};

export default function homeReducer(state = init(), action) {
  switch (action.type) {
    case GET_USER_TILE_ROLES:
      return state.update('tile', (tile) => ({
        ...tile,
        isLoading: true,
      }));
    case GET_USER_TILE_ROLES_SUCCESS:
      return state.update('tile', () => ({
        tiles: action.payload.tiles,
        isLoading: false,
        userRole: action.payload.userRole,
      }));
    case GET_USER_TILE_ROLES_FAIL:
      return state.update('tile', (tile) => ({
        ...tile,
        isLoading: false,
      }));
    default:
      return state;
  }
}
