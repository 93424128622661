import PropTypes from 'prop-types';

const BaseTagsDisplay = (props) => {
  const { tags } = props;

  return (
    <div className="group-tags">
      {tags?.map((tag) => (
        <span key={tag.id} className="tag">
          {tag.name}
        </span>
      ))}
    </div>
  );
};

BaseTagsDisplay.defaultProps = {
  tags: [],
};

BaseTagsDisplay.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object),
};

export default BaseTagsDisplay;
