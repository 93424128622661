import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { W2pService } from 'services';
import {
  GET_MY_TEMPLATE_FAIL,
  GET_MY_TEMPLATE_REQUEST,
  GET_MY_TEMPLATE_SUCCESS,
} from './action';

export const getMyTemplate = () => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_MY_TEMPLATE_REQUEST });
    const result = await W2pService.getMyTemplates();
    dispatch(hideLoading());
    dispatch({ type: GET_MY_TEMPLATE_SUCCESS, payload: result.data });
  } catch (err) {
    dispatch({ type: GET_MY_TEMPLATE_FAIL, err });
    dispatch(hideLoading());
  }
};
