export const selectReducer = (state) => state.mapoContentManagement;

export const selectIsLoading = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('mapoContent').isLoading
    : false;

export const selectIsDeleting = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('mapoContent').isDeleting
    : false;

export const selectTiles = (state) =>
  selectReducer(state) ? selectReducer(state).get('mapoContent').tiles : [];

export const selectPagination = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('mapoContent').pagination
    : { page: 1, limit: 10, total: 0 };
