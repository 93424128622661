import immutable from 'immutable';
import { UPDATE_HEADER_TITLE } from './action';

const init = () => {
  const initValue = immutable.fromJS({
    header: undefined,
  });

  return initValue.set('header', {
    title: '',
  });
};

export default function headerReducer(state = init(), action) {
  if (action.type === UPDATE_HEADER_TITLE) {
    return state.update('header', (header) => ({
      ...header,
      title: action.payload.title,
    }));
  } else {
    return state;
  }
}
