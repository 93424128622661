export const GET_W2P_SINGLE_CONTENT_REQUEST = 'GET_W2P_SINGLE_CONTENT_REQUEST';
export const GET_W2P_SINGLE_CONTENT_SUCCESS = 'GET_W2P_SINGLE_CONTENT_SUCCESS';
export const GET_W2P_SINGLE_CONTENT_FAIL = 'GET_W2P_SINGLE_CONTENT_FAIL';
export const RESET_W2P_SINGLE_CONTENT = 'RESET_W2P_SINGLE_CONTENT';
export const GET_MASTER_DATA = 'GET_MASTER_DATA';
export const GET_MASTER_DATA_SUCCESS = 'GET_MASTER_DATA_SUCCESS';
export const GET_MASTER_DATA_FAIL = 'GET_MASTER_DATA_FAIL';
export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
export const SAVE_TEMPLATE_FAIL = 'SAVE_TEMPLATE_FAIL';

export const GET_MAPPING_RULES_REQUEST = 'GET_MAPPING_RULES_REQUEST';
export const GET_MAPPING_RULES_SUCCESS = 'GET_MAPPING_RULES_SUCCESS';
export const GET_MAPPING_RULES_FAIL = 'GET_MAPPING_RULES_FAIL';

export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAIL = 'GET_COMPANY_FAIL';

export const RESET_COMPANY = 'RESET_COMPANY';

export const ADD_RULE_REQUEST = 'ADD_RULE_REQUEST';
export const ADD_RULE_SUCCESS = 'ADD_RULE_SUCCESS';
export const ADD_RULE_FAIL = 'ADD_RULE_FAIL';

export const DELETE_RULE_REQUEST = 'DELETE_RULE_REQUEST';
export const DELETE_RULE_SUCCESS = 'DELETE_RULE_SUCCESS';
export const DELETE_RULE_FAIL = 'DELETE_RULE_FAIL';

export const UPDATE_USER_TYPE = 'UPDATE_USER_TYPE';
export const UPDATE_JOB_TYPE = 'UPDATE_JOB_TYPE';
export const UPDATE_REGION = 'UPDATE_REGION';
export const UPDATE_ALL_REGION = 'UPDATE_ALL_REGION';
export const UPDATE_SINGLE_COMPANY = 'UPDATE_SINGLE_COMPANY';

export const UPDATE_SELECTED_REGION = 'UPDATE_SELECTED_REGION';

export const SAVE_MAPPING_RULE = 'SAVE_MAPPING_RULE';
export const SAVE_MAPPING_RULE_SUCCESS = 'SAVE_MAPPING_RULE_SUCCESS';
export const SAVE_MAPPING_RULE_FAIL = 'SAVE_MAPPING_RULE_FAIL';
