import http from 'utilities/httpService';

export class UserService {
  static async searchUsers(page, limit, params) {
    const strParams = [];
    if (params?.length > 0) {
      params.forEach((param) => {
        strParams.push(`${param.fieldName}=${param.value}`);
      });
      const joinedParam = strParams.join('&');

      return http.get(
        `/admin/users?_page=${page}&_limit=${limit}&${joinedParam}&_withs=region,company,userTileRoles,jobType,userType`,
      );
    }
    return http.get(
      `/admin/users?_page=${page}&_limit=${limit}&_withs=region,company,userTileRoles,jobType,userType`,
    );
  }

  static async getDetailUser(id) {
    return http.get(
      `/admin/users/${id}?_withs=region,company,userTileRoles,userRole,jobType`,
    );
  }

  static async updateUser(id, data) {
    return http.put(`/admin/users/${id}`, data);
  }

  static async deleteUser(id) {
    return http.delete(`/admin/users/${id}`);
  }

  static async updateUserLanguage(id, data) {
    return http.put(`/admin/users/lang/${id}`, data);
  }

  static async setUserAdminRole(id, set) {
    return http.put(`/admin/users/set-admin-role`, {
      userId: id,
      hasAdminAccess: set,
    });
  }

  static async setUserAccessAllTilesRole(id, set) {
    return http.put(`/admin/users/set-admin-role`, {
      userId: id,
      hasAllTilesAccess: set,
    });
  }
}
