import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { W2pService } from 'services';
import {
  GET_MY_DOCUMENT_FAIL,
  GET_MY_DOCUMENT_REQUEST,
  GET_MY_DOCUMENT_SUCCESS,
} from './action';

export const getMyDocuments = () => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_MY_DOCUMENT_REQUEST });
    const result = await W2pService.getMyDocuments();
    dispatch(hideLoading());
    dispatch({ type: GET_MY_DOCUMENT_SUCCESS, payload: result.data });
  } catch (err) {
    dispatch({ type: GET_MY_DOCUMENT_FAIL, err });
    dispatch(hideLoading());
  }
};
