export const selectReducer = (state) => state.w2pEditor;

export const selectEditorLink = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('editTemplate').editorLink
    : null;

export const selectUserTemplateId = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('editTemplate').userTemplateId
    : null;

export const selectIsLoading = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('editTemplate').isLoading
    : false;

export const selectIsGenerating = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('editTemplate').isGenerating
    : false;

export const selectDowloadLink = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('editTemplate').dowloadLink
    : null;
