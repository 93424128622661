import immutable from 'immutable';
import {
  GET_W2P_CONTENT_FAIL,
  GET_W2P_CONTENT_REQUEST,
  GET_W2P_CONTENT_SUCCESS,
  UPDATE_THUMBNAIL,
  UPDATE_THUMBNAIL_SUCCESS,
  UPDATE_THUMBNAIL_FAIL,
} from './action';

const init = () => {
  const initValue = immutable.fromJS({
    w2pContent: undefined,
  });

  return initValue.set('w2pContent', {
    info: [],
    isLoading: false,
  });
};

export default function w2pContentReducer(state = init(), action) {
  switch (action.type) {
    case GET_W2P_CONTENT_REQUEST:
    case UPDATE_THUMBNAIL:
      return state.update('w2pContent', (w2pContent) => ({
        ...w2pContent,
        isLoading: true,
      }));
    case GET_W2P_CONTENT_SUCCESS: {
      const { payload } = action;
      return state.update('w2pContent', (w2pContent) => ({
        ...w2pContent,
        info: payload,
        isLoading: false,
      }));
    }
    case GET_W2P_CONTENT_FAIL:
    case UPDATE_THUMBNAIL_FAIL:
    case UPDATE_THUMBNAIL_SUCCESS:
      return state.update('w2pContent', (w2pContent) => ({
        ...w2pContent,
        isLoading: false,
      }));
    default:
      return state;
  }
}
