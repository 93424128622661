import { Layout, Col, Select, Row } from 'antd';
import Breadcrumb from 'components/UI/Breadcrumb';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { navigationRoutes } from 'utilities';
import ScrollToTop from 'components/ScrollToTop';
import {
  getStartPage,
  resetStartPageData,
  updatePreviewStartPage,
} from 'redux/microSite/operation';
import { selectStartPageData } from 'redux/microSite/selector';
import PreviewStartPage from './PreviewStartPage';
import EditStartPage from './EditStartPage';
import ViewStartPage from './ViewStartPage';
import { ADD_SUB_PAGE_OPTIONS } from './util/constants';

const MicroSingleStartPage = () => {
  const { Content } = Layout;
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();
  const { startPage } = useSelector((state) => ({
    startPage: selectStartPageData(state),
  }));

  const [isEditable, setIsEditable] = useState(true);

  const onEditStartPage = useCallback(() => {
    setIsEditable(true);
  }, []);

  const onCancelEditStartPage = useCallback(() => {
    setIsEditable(false);
    dispatch(resetStartPageData());
    dispatch(getStartPage(id, true));
  }, [dispatch, id]);

  const onSelectChange = useCallback(
    (value) => {
      const redirectPath =
        value === ADD_SUB_PAGE_OPTIONS.TEXT_SUB_PAGE
          ? `${navigationRoutes.MicroSingleStartPage}/${id}/text-sub-page`
          : `${navigationRoutes.MicroSingleStartPage}/${id}/media-sub-page`;
      return history.push(redirectPath);
    },
    [history, id],
  );

  useEffect(() => {
    if (Object.keys(startPage).length) {
      dispatch(updatePreviewStartPage(startPage));
    }
  }, [dispatch, startPage]);

  useEffect(() => {
    dispatch(getStartPage(id, true));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      setIsEditable(true);
      dispatch(resetStartPageData());
    };
  }, [dispatch, id]);

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Home,
      },
      {
        title: 'Administration',
        path: navigationRoutes.UserManagement,
      },
      {
        title: 'Content Management',
        path: navigationRoutes.MapoContent,
      },
      {
        title: startPage.title || '',
      },
    ],
    [startPage],
  );

  return (
    <Content className="micro-edit-start-page">
      <Breadcrumb items={breadCrumbItems} />
      <div className="edit-start-page_banner mb-6">
        <div className="middle-content">
          <div className="d-flex align-center justify-space-between">
            <h1 className="edit-start-page_title bold-text uppercase-text mb-2">
              {startPage?.title}
            </h1>
            {!isEditable && (
              <Select
                className="edit-start-page_select"
                dropdownClassName="mapo-select-option"
                bordered={false}
                onChange={onSelectChange}
                placeholder="Add Content">
                <Select.Option value={ADD_SUB_PAGE_OPTIONS.TEXT_SUB_PAGE}>
                  Add Text Sub Page
                </Select.Option>
                <Select.Option value={ADD_SUB_PAGE_OPTIONS.MEDIA_SUB_PAGE}>
                  Add Media Sub Page
                </Select.Option>
              </Select>
            )}
          </div>
          <p className="edit-start-page_text mb-1">
            The first template provided for this Micro Site/CMS Tile is a Start
            Page.
          </p>
          <div className="edit-start-page_tab">START PAGE</div>
        </div>
      </div>
      <div className="middle-content">
        <Row span={24} gutter={24}>
          <Col xs={24} md={16} lg={16}>
            <h3 className="form-title">SITE INFORMATION</h3>
            {isEditable ? (
              <EditStartPage onCancel={onCancelEditStartPage} />
            ) : (
              <ViewStartPage onEdit={onEditStartPage} />
            )}
          </Col>
          <Col className="edit-start-page_preview" xs={24} md={8} lg={8}>
            <PreviewStartPage />
          </Col>
        </Row>
      </div>
      <ScrollToTop />
    </Content>
  );
};

export default MicroSingleStartPage;
