import immutable from 'immutable';
import {
  GET_USERS_REQUEST,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_DETAIL_FAIL,
  GET_USER_DETAIL_SUCCESS,
  UPDATE_USER_DETAIL_FAIL,
  UPDATE_USER_DETAIL_SUCCESS,
  GET_USER_COMPANY_FAIL,
  GET_USER_COMPANY_SUCCESS,
  GET_USER_REGION_FAIL,
  GET_USER_REGION_SUCCESS,
  RESET_USER_DETAIL,
  GET_JOB_TYPE_SUCCESS,
  GET_JOB_TYPE__FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  SET_USER_ADMIN_ROLE_FAIL,
  SET_USER_ADMIN_ROLE_SUCCESS,
  SET_USER_ADMIN_ROLE_REQUEST,
  SET_USER_VIEW_ALL_TILES_REQUEST,
  SET_USER_VIEW_ALL_TILES_SUCCESS,
  SET_USER_VIEW_ALL_TILES_FAIL,
} from './action';
import { ca } from 'date-fns/locale';

const init = () => {
  const initValue = immutable.fromJS({
    users: undefined,
  });

  return initValue.set('users', {
    info: {},
    isLoading: false,
    detailUser: null,
    search: '',
    startWith: '',
    region: [],
    company: [],
    country: [],
    jobTypes: [],
  });
};

export default function userManagementReducer(state = init(), action) {
  switch (action.type) {
    case GET_USERS_REQUEST:
    case DELETE_USER_REQUEST:
    case SET_USER_ADMIN_ROLE_REQUEST:
    case SET_USER_VIEW_ALL_TILES_REQUEST:
      return state.update('users', (users) => ({
        ...users,
        isLoading: true,
      }));

    case GET_USERS_SUCCESS: {
      const { payload } = action;
      return state.update('users', (users) => ({
        ...users,
        info: payload,
        search: payload.search,
        startWith: payload.startWith,
        isLoading: false,
      }));
    }
    case GET_USER_DETAIL_SUCCESS: {
      const { payload } = action;
      return state.update('users', (users) => ({
        ...users,
        detailUser: payload,
        isLoading: false,
      }));
    }
    case SET_USER_ADMIN_ROLE_SUCCESS:
      return state.update('users', (users) => {
        const { payload } = action;
        const detailUser = users.detailUser;
        detailUser.userRole.id = payload;
        return {
          ...users,
          detailUser,
          isLoading: false,
        };
      });

    case SET_USER_VIEW_ALL_TILES_SUCCESS:
      return state.update('users', (users) => {
        const { payload } = action;
        const detailUser = users.detailUser;
        detailUser.userRole.id = payload;
        return {
          ...users,
          detailUser,
          isLoading: false,
        };
      });
    case GET_USERS_FAIL:
    case GET_USER_DETAIL_FAIL:
    case UPDATE_USER_DETAIL_SUCCESS:
    case UPDATE_USER_DETAIL_FAIL:
    case GET_USER_REGION_FAIL:
    case GET_JOB_TYPE__FAIL:
    case GET_USER_COMPANY_FAIL:
    case SET_USER_VIEW_ALL_TILES_FAIL:
    case SET_USER_ADMIN_ROLE_FAIL:
    case DELETE_USER_FAIL: {
      return state.update('users', (users) => ({
        ...users,
        isLoading: false,
      }));
    }
    case GET_USER_REGION_SUCCESS: {
      const { payload } = action;
      return state.update('users', (users) => ({
        ...users,
        region: payload,
        isLoading: false,
      }));
    }
    case GET_USER_COMPANY_SUCCESS: {
      const { payload } = action;
      return state.update('users', (users) => ({
        ...users,
        company: payload,
        isLoading: false,
      }));
    }
    case GET_JOB_TYPE_SUCCESS: {
      const { payload } = action;
      return state.update('users', (users) => ({
        ...users,
        jobTypes: payload,
        isLoading: false,
      }));
    }
    case RESET_USER_DETAIL:
      return state.update('users', (users) => ({
        ...users,
        detailUser: null,
        region: [],
        company: [],
        country: [],
      }));
    case DELETE_USER_SUCCESS: {
      const {
        payload: { id },
      } = action;
      const { data } = state.get('users').info;
      const clonedData = [...data];
      const deletedUserIndex = clonedData.findIndex((user) => user.id === id);
      if (deletedUserIndex > -1) {
        clonedData.splice(deletedUserIndex, 1);
      }
      return state.update('users', (users) => ({
        ...users,
        isLoading: false,
        info: {
          ...users.info,
          data: clonedData,
        },
      }));
    }
    default:
      return state;
  }
}
