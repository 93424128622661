import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { BaseButton } from 'components/UI/BaseButton';
import { BaseUpload } from 'components/UI/BaseUpload';
import {
  selectStartPageData,
  selectStartPageUpdate,
  selectDeleteSubPage,
} from 'redux/microSite/selector';
import {
  updateStartPage,
  updatePreviewStartPage,
  deleteTextSubPage,
  deleteMediaSubPage,
} from 'redux/microSite/operation';
import { UPDATE_STATUS, SUB_PAGE_TYPES } from 'utilities';

const EditStartPage = ({ onCancel }) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const typingTimeoutRef = useRef(null);

  const { startPage, update, deleteSubPage } = useSelector((state) => ({
    startPage: selectStartPageData(state),
    update: selectStartPageUpdate(state),
    deleteSubPage: selectDeleteSubPage(state),
  }));

  const onFinish = useCallback(
    (values) => {
      const updateStartPageValues = {
        title: startPage.title,
        headline: values.headline,
        mediaHeadline: values.mediaHeadline,
        textHeadline: values.textHeadline,
      };

      dispatch(updateStartPage(startPage.id, updateStartPageValues));
    },
    [dispatch, startPage],
  );

  const onValuesChange = useCallback(
    (_, allValues) => {
      clearTimeout(typingTimeoutRef.current);
      typingTimeoutRef.current = setTimeout(() => {
        dispatch(updatePreviewStartPage(allValues));
      }, 500);
    },
    [dispatch],
  );

  const onUploadMediaHeadline = useCallback(
    (value) => {
      form.setFieldsValue({
        mediaHeadline: value,
      });
      dispatch(
        updatePreviewStartPage({
          mediaHeadline: value,
        }),
      );
    },
    [dispatch, form],
  );

  const onDeleteSubPage = useCallback(
    (subPage) => {
      const { id, type } = subPage;
      if (type === SUB_PAGE_TYPES.TEXT) {
        dispatch(deleteTextSubPage(id));
      } else {
        dispatch(deleteMediaSubPage(id));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    form.setFieldsValue({
      headline: startPage.headline,
      mediaHeadline: startPage.mediaHeadline,
      subPages: startPage.subPages,
      textHeadline: startPage.textHeadline,
    });
  }, [dispatch, startPage, form]);

  useEffect(() => {
    if (update.status === UPDATE_STATUS.SUCCESS) {
      onCancel();
    }
  }, [dispatch, update.status, onCancel]);

  return (
    <Form
      form={form}
      onValuesChange={onValuesChange}
      className="edit-start-page_form"
      layout="vertical"
      onFinish={onFinish}>
      <Form.Item label="HEADLINE" name="headline">
        <Input className="headline-title" placeholder="Headline" />
      </Form.Item>

      <Form.Item label="TEXT HEADLINE" name="textHeadline">
        <TextArea
          className="headline-text"
          placeholder="Text headline"
          autoSize
        />
      </Form.Item>
      <Form.Item name="mediaHeadline" label="MEDIA HEADLINE">
        <BaseUpload
          initialValue={startPage.mediaHeadline}
          onUploadChange={onUploadMediaHeadline}
        />
      </Form.Item>
      <Form.List name="subPages">
        {() => (
          <>
            {startPage?.subPages?.map((subPage, index) => {
              return (
                <div className="sub-headline" key={subPage.embededLink}>
                  <Form.Item label="SUBHEADLINE TITLE" name={[index, 'title']}>
                    <Input className="headline-title" disabled />
                  </Form.Item>

                  <Form.Item
                    label="TEXT SUBHEADLINE"
                    name={[index, 'textHeadline']}>
                    <TextArea
                      className="headline-text"
                      placeholder="Subheadline text."
                      autoSize
                      disabled
                    />
                  </Form.Item>

                  <Form.Item label="CONTENT LINK" name={[index, 'contentLink']}>
                    <Input className="headline-content-link" disabled />
                  </Form.Item>

                  <Form.Item label="STATUS" name={[index, 'status']}>
                    <Input className="subpage-status" disabled />
                  </Form.Item>

                  <div className="d-flex justify-space-between">
                    <Form.Item
                      label="MEDIA SUPPORT"
                      name={[index, 'mediaHeadline']}>
                      <BaseUpload
                        initialValue={subPage.mediaHeadline}
                        uploadProps={{ disabled: true }}
                      />
                    </Form.Item>
                    <div className="d-flex align-end">
                      <BaseButton
                        className="delete-sub-page-button"
                        loading={deleteSubPage.isLoading}
                        onClick={() => onDeleteSubPage(subPage)}>
                        DELETE
                      </BaseButton>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </Form.List>
      <div className="form-buttons d-flex justify-space-between">
        <BaseButton className="bold-text" htmlType="button" onClick={onCancel}>
          CANCEL
        </BaseButton>
        <BaseButton
          loading={update.isLoading}
          className="base-yellow-btn"
          type="primary"
          htmlType="submit">
          SAVE CONTENT
        </BaseButton>
      </div>
    </Form>
  );
};

EditStartPage.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default EditStartPage;
