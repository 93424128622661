export const W2P_TABS = {
  CATEGORIES: '1',
  LATEST: '2',
  TAGS: '3',
};

export const TABS = {
  [W2P_TABS.CATEGORIES]: {
    title: 'CATEGORIES',
    breadcrumb: 'Categories',
  },
  [W2P_TABS.LATEST]: {
    title: 'LATEST',
    breadcrumb: 'Latest',
  },
  [W2P_TABS.TAGS]: {
    title: 'TAGS',
    breadcrumb: 'Tags',
  },
};

export const SUB_HEADLINES = {
  [W2P_TABS.CATEGORIES]:
    'These templates are sorted by categories. Search for your template here so you can personalize it as you like.',
  [W2P_TABS.LATEST]:
    'Here you can find the most recently uploaded templates from Kärcher Marketing. They are sorted chronologically, the latest added template on top.',
  [W2P_TABS.TAGS]:
    'Enter the keywords for the template you are looking for here. There is also an option to search for your template by clicking the given tags instead.',
};
