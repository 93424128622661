export const PDF_VIEW_EXTENSION = 'PDF_VIEW';
export const PDF_PRINT_EXTENSION = 'PDF_PRINT';
export const PNG_EXTENSION = 'PNG';

export const DOWNLOAD_TEMPLATE_OPTIONS = [
  {
    name: 'Pdf View',
    value: PDF_VIEW_EXTENSION,
  },
  {
    name: 'Pdf Print',
    value: PDF_PRINT_EXTENSION,
  },
  {
    name: 'Image',
    value: PNG_EXTENSION,
  },
];
