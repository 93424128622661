import MapoRule from 'screens/MapoAdministration/mapo-rule';
import SingleUserInformation from 'screens/MapoAdministration/singleUser-information';
import UserManagement from 'screens/MapoAdministration/user-management';
import ScreenW2PContentManagement from 'screens/MapoAdministration/w2p-content-management';
import ScreenW2PSingleContent from 'screens/MapoAdministration/w2p-single-content';
import ScreenMapoContentManagement from 'screens/MapoAdministration/mapo-content-management';
import { navigationPaths } from 'utilities';
import ScreenMapoSingleContent from 'screens/MapoAdministration/mapo-single-content';
import ScreenMicroSingleStartPage from 'screens/MapoAdministration/micro-single-start-page';
import ScreenMicroTextSubPage from 'screens/MapoAdministration/micro-text-sub-page';
import ScreenMicroMediaSubPage from 'screens/MapoAdministration/micro-media-sub-page';

export default [
  {
    path: navigationPaths.UserManagement,
    component: UserManagement,
    exact: true,
  },
  {
    path: navigationPaths.MapoRule,
    component: MapoRule,
    exact: true,
  },
  {
    path: navigationPaths.SingleUserInformation,
    component: SingleUserInformation,
    exact: true,
  },
  {
    path: navigationPaths.W2PContent,
    component: ScreenW2PContentManagement,
    exact: true,
  },
  {
    path: navigationPaths.W2PSingleContent,
    component: ScreenW2PSingleContent,
    exact: true,
  },
  {
    path: navigationPaths.MapoContent,
    component: ScreenMapoContentManagement,
    exact: true,
  },
  {
    path: navigationPaths.MapoSingleContentCreate,
    component: ScreenMapoSingleContent,
    exact: true,
  },
  {
    path: navigationPaths.MapoSingleContentEdit,
    component: ScreenMapoSingleContent,
    exact: true,
  },
  {
    path: navigationPaths.MicroSingleStartPage,
    component: ScreenMicroSingleStartPage,
    exact: true,
  },
  {
    path: navigationPaths.MicroTextSubPageCreate,
    component: ScreenMicroTextSubPage,
    exact: true,
  },
  {
    path: navigationPaths.MicroTextSubPageEdit,
    component: ScreenMicroTextSubPage,
    exact: true,
  },
  {
    path: navigationPaths.MicroMediaSubPageCreate,
    component: ScreenMicroMediaSubPage,
    exact: true,
  },
  {
    path: navigationPaths.MicroMediaSubPageEdit,
    component: ScreenMicroMediaSubPage,
    exact: true,
  },
];
