import { Layout, Select, Modal } from 'antd';
import DraggableTable from 'components/UI/DraggableTable';
import Breadcrumb from 'components/UI/Breadcrumb';
import { SearchBox } from 'components/UI/SearchBox';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  deleteTile,
  getTiles,
  changeOrderTile,
} from 'redux/administration/content-management/mapo-content-management/operation';
import {
  selectIsDeleting,
  selectIsLoading,
  selectPagination,
  selectTiles,
} from 'redux/administration/content-management/mapo-content-management/selector';
import { navigationRoutes } from 'utilities';
import { renderColumns } from './util/render-columns';

const MapoContentManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Content } = Layout;
  const { Option } = Select;
  const { tiles, pagination, isLoading, isDeleting } = useSelector((state) => ({
    tiles: selectTiles(state),
    pagination: selectPagination(state),
    isLoading: selectIsLoading(state),
    isDeleting: selectIsDeleting(state),
  }));

  const [paginationData, setPaginationData] = useState({
    page: pagination.page,
    limit: pagination.limit,
  });
  const [searchParams, setSearchParams] = useState([]);
  const [modal, setModal] = useState({ isShow: false, id: null });

  useEffect(() => {
    dispatch(getTiles(paginationData, searchParams));
  }, [dispatch, paginationData, searchParams]);

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Home,
      },
      {
        title: 'Administration',
        path: navigationRoutes.UserManagement,
      },
      {
        title: 'Content Management',
        path: navigationRoutes.MapoContent,
      },
      {
        title: 'MAPO MAIN PAGE',
      },
    ],
    [],
  );

  const handleOpenModal = useCallback((id) => {
    setModal({ isShow: true, id });
  }, []);

  const columns = useMemo(
    () => renderColumns(history, handleOpenModal),
    [history, handleOpenModal],
  );

  const handleTableChange = (newPagination) => {
    setPaginationData({
      limit: newPagination.pageSize,
      page: newPagination.current,
    });
  };

  const tableProps = useMemo(
    () => ({
      columns,
      loading: isLoading,
      dataSource: tiles,
      rowKey: 'id',
      pagination: {
        current: pagination.page,
        pageSize: pagination.limit,
        total: pagination.total,
      },
      className: 'mapo-content-management-table',
      onChange: handleTableChange,
    }),
    [columns, isLoading, tiles, pagination],
  );

  const onSearch = useCallback((keyword) => {
    if (keyword && keyword.length) {
      setSearchParams([
        {
          fieldName: 'title',
          value: keyword,
        },
      ]);
    } else {
      setSearchParams([]);
    }
  }, []);

  const createTileWithType = (type) => {
    history.push(`${navigationRoutes.MapoSingleContentCreate}/${type}`);
  };

  const handleOk = useCallback(() => {
    dispatch(deleteTile(modal.id));
    setModal({ isShow: false, id: null });
  }, [dispatch, modal.id]);

  const handleCancel = useCallback(() => {
    setModal({ isShow: false, id: null });
  }, []);

  const onDrag = useCallback(
    (oldRecord, newOrder) => {
      dispatch(changeOrderTile(oldRecord.id, { tileOrder: newOrder }));
    },
    [dispatch],
  );

  return (
    <Content className="mapo-administrator-content-management">
      <Breadcrumb items={breadCrumbItems} />
      <div className="mapo-content-management_banner pt-8 mb-8">
        <div className="middle-content d-flex align-center justify-space-between">
          <h1 className="mapo-content-management_title bold-text">
            MAPO MAIN PAGE
          </h1>
          <Select
            className="mapo-content-management_select"
            dropdownClassName="mapo-select-option"
            bordered={false}
            onChange={createTileWithType}
            placeholder="Add Content">
            <Option value="1">add Application Tile</Option>
            <Option value="2">add Micro Site/CMS Tile</Option>
          </Select>
        </div>
      </div>

      <div className="middle-content mb-16">
        <SearchBox onSearch={onSearch} />
        <DraggableTable
          {...tableProps}
          onDrag={onDrag}
          helperClass="mapo-content-management-row-dragging"
        />
      </div>
      <Modal
        visible={modal.isShow}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ loading: isDeleting }}>
        <p>Warning</p>
        <div className="text-center">Are you sure to delete this item?</div>
      </Modal>
    </Content>
  );
};

export default MapoContentManagement;
