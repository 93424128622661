import http from 'utilities/httpService';

export class W2pService {
  static async getCategories() {
    return http.get(`/w2p/categories`);
  }

  static async getTags() {
    return http.get(`/w2p/tags`);
  }

  static async getTagsAssociateTemplate() {
    return http.get(`/w2p/tagsAssociateTemplate`);
  }

  static async getTemplatesByCat(id, tagIds) {
    let url = `/w2p/templates?_withs=category${
      tagIds ? ',tag' : ''
    }&_categoryId=${id}`;
    if (tagIds) {
      url += `&_tagId=${tagIds.join(',')}`;
    }
    return http.get(url);
  }

  static async getTemplatesByTag(tagIds, term) {
    if (tagIds.length > 0) {
      return http.get(
        `/w2p/templates?_withs=tag&_tagId=${tagIds.join(',')}&_term=${
          term || ''
        }`,
      );
    }
    return http.get(`/w2p/templates?_term=${term || ''}`);
  }

  static async getLatestTemplatesByTag(tagIds, isAll) {
    let url = '';
    if (isAll) {
      url = `/w2p/templates?latest=true`;
    } else {
      url = `/w2p/templates?_withs=tag&_tagId=${tagIds.join(',')}&latest=true`;
    }

    return http.get(url);
  }

  static async getTemplatesMappingRules(metadata) {
    const strParams = [];
    Object.keys(metadata).forEach((param) => {
      strParams.push(`${param}=${metadata[param]}`);
    });
    return http.get(`/w2p/templates/mapping-rules?${strParams.join('&')}`);
  }

  static async addTemplatesMappingRules(newRules) {
    return http.post(`/w2p/templates/mapping-rules`, newRules);
  }

  static async updateTemplatesMappingRules(newRules) {
    return http.put(`/w2p/templates/mapping-rules`, newRules);
  }

  static async deleteTemplatesMappingRules(ruleId) {
    return http.delete(`/w2p/templates/mapping-rules/${ruleId}`);
  }

  static async getTemplatesById(id) {
    return http.get(`/w2p/templates/${id}`);
  }

  static async getTemplates() {
    return http.get(`/w2p/templates`);
  }

  static async getTemplatesManagements(paginationData) {
    const title = paginationData.title ? `?title=${paginationData.title}` : '';
    return http.get(`/w2p/templates/management${title}`);
  }

  static async updateThumbnail(id) {
    return http.put(`/w2p/templates/update-thumbnail/${id}`);
  }

  static async getTemplateView(id, title) {
    return http.get(`/w2p/templates/view?id=${id}&title=${title}`);
  }

  static async saveTemplate(id, form) {
    return http.put(`/w2p/templates/${id}`, form);
  }

  static async editTemplate(templateId, edit) {
    return http.get(
      `/w2p/templates/editor/${templateId}${edit ? '?edit=true' : ''}`,
    );
  }

  static async getMyTemplates() {
    return http.get(`/w2p/templates/mine`);
  }

  static async generatePdf(id, isPrint) {
    const print = isPrint ? '?print=true' : '';
    return http.get(`/w2p/templates/pdf/${id}${print}`);
  }

  static async generateImage(id) {
    return http.get(`/w2p/templates/image/${id}`);
  }

  static async getMyDocuments() {
    return http.get(`/w2p/templates/my-documents`);
  }
}
