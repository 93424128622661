import { Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCategories } from 'redux/w2p/categories/operation';
import { navigationRoutes, CATEGORY } from 'utilities';
import { ITPortal, additionalInformation, travelPlane } from 'assets/images';
import {
  selectCategories,
  selectIsLoading,
} from 'redux/w2p/categories/selector';
import BaseOverlayItem from 'components/UI/BaseOverlayItem';

export const Web2PublishCategories = () => {
  const categories = useSelector((state) => selectCategories(state));
  const isLoading = useSelector((state) => selectIsLoading(state));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const getImageCategory = (name) => {
    switch (name) {
      case CATEGORY.WEB: {
        return ITPortal;
      }
      case CATEGORY.FLYER: {
        return travelPlane;
      }
      case CATEGORY.POSTER: {
        return additionalInformation;
      }

      default:
        break;
    }

    return null;
  };

  return (
    <div className="web2Publish-categories-content middle-content">
      {isLoading ? (
        <div className="loading">
          <Spin />
        </div>
      ) : (
        <Row
          gutter={[
            { xs: 8, sm: 16, md: 24 },
            { xs: 8, sm: 16, md: 40 },
          ]}>
          {categories.map((cat) => (
            <BaseOverlayItem
              xs={24}
              md={8}
              sm={24}
              className="category"
              key={cat.id}
              hoverText={cat.name}
              path={`${navigationRoutes.Web2PublishTemplatesByCat}/${cat.id}/${cat.name}`}
              image={getImageCategory(cat.name)}
              isSmallImage>
              <h2 className="cat-text bold-text">{cat.name}</h2>
            </BaseOverlayItem>
          ))}
        </Row>
      )}
    </div>
  );
};
