export const navigationPaths = {
  Home: '/home',
  LoginDealerName: '/submit-name',
  LoginDealerEmail: '/submit-email',
  LoginDealerNameEmail: '/submit-user-info',
  Mapo: '/mapo-administration',
  Web2Publish: '/web2Publish',
  UserManagement: '/mapo-administration/user-management',
  SingleUserInformation: '/mapo-administration/user-management/:userId',
  MapoRule: '/mapo-administration/mapo-rules',
  W2PRule: '/mapo-administration/w2p-rules',
  W2PContent: '/mapo-administration/w2p-content-management',
  W2PSingleContent: '/mapo-administration/w2p-content-management/:id/:title',
  Web2PublishWelcome: '/web2Publish/welcome',
  Web2PublishMain: '/web2Publish/main',
  Web2PublishTemplatesByCat: '/web2Publish/templates-by-cat/:templateId/:title',
  SingleAsset: '/web2Publish/single-asset/:templateId',
  MyTemplate: '/web2Publish/my-template',
  W2PTemplateEditor: '/web2Publish/editor/:id',
  W2PTemplateEditorEdit: '/web2Publish/editor/:id/:edit',
  MyProfile: '/my-profile',
  Web2PublishMyProfile: '/web2Publish/my-profile',
  MapoContent: '/mapo-administration/mapo-content-management',
  MapoSingleContentCreate: '/mapo-administration/mapo-content-management/:type',
  MapoSingleContentEdit:
    '/mapo-administration/mapo-content-management/:type/:id',
  MicroSite: '/micro-site',
  MicroSiteStartPage: '/micro-site/:id',
  MicroSingleStartPage: '/mapo-administration/micro-site/:id',
  MicroTextSubPageCreate:
    '/mapo-administration/micro-site/:tileId/text-sub-page',
  MicroTextSubPageEdit:
    '/mapo-administration/micro-site/:tileId/text-sub-page/:subPageId',
  MicroMediaSubPageCreate:
    '/mapo-administration/micro-site/:tileId/media-sub-page',
  MicroMediaSubPageEdit:
    '/mapo-administration/micro-site/:tileId/media-sub-page/:subPageId',
  MicroSiteTextSubPage: '/micro-site/:tileId/text-sub-page/:subPageId',
  MicroSiteMediaSubPage: '/micro-site/:tileId/media-sub-page/:subPageId',
  Error_403: '/error/permission-denied',
  Imprint: '/imprint',
  W2PImprint: '/web2Publish/imprint',
  Disclaimer: '/disclaimer',
  W2PDisclaimer: '/web2Publish/disclaimer',
  W2PTermsAndConditions: '/web2Publish/terms-and-conditions',
  PrivacyPolicy: '/privacy-information',
  W2PPrivacyPolicy: '/web2Publish/privacy-information',
};

export const navigationRoutes = {
  Home: '/home',
  LoginDealerName: '/submit-name',
  LoginDealerEmail: '/submit-email',
  LoginDealerNameAndEmail: '/submit-user-info',
  UserManagement: '/mapo-administration/user-management',
  SingleUserInformation: '/mapo-administration/user-management',
  MapoRule: '/mapo-administration/mapo-rules',
  W2PRule: '/mapo-administration/w2p-rules',
  W2PContent: '/mapo-administration/w2p-content-management',
  W2PSingleContent: '/mapo-administration/w2p-content-management',
  Web2PublishWelcome: '/web2Publish/welcome',
  Web2PublishMain: '/web2Publish/main',
  Web2PublishTemplatesByCat: '/web2Publish/templates-by-cat',
  SingleAsset: '/web2Publish/single-asset',
  MyTemplate: '/web2Publish/my-template',
  W2PTemplateEditor: '/web2Publish/editor',
  MyProfile: '/my-profile',
  Web2PublishMyProfile: '/web2Publish/my-profile',
  MapoContent: '/mapo-administration/mapo-content-management',
  MapoSingleContentCreate: '/mapo-administration/mapo-content-management',
  MapoSingleContentEdit: '/mapo-administration/mapo-content-management',
  MicroSite: '/micro-site',
  MicroSingleStartPage: '/mapo-administration/micro-site',
  MicroSiteTextSubPage: '/text-sub-page',
  MicroSiteMediaSubPage: '/media-sub-page',
  Error_403: '/error/permission-denied',
  Imprint: '/imprint',
  W2PImprint: '/web2Publish/imprint',
  Disclaimer: '/disclaimer',
  W2PDisclaimer: '/web2Publish/disclaimer',
  W2PTermsAndConditions: '/web2Publish/terms-and-conditions',
  PrivacyPolicy: '/privacy-information',
  W2PPrivacyPolicy: '/web2Publish/privacy-information',
};
