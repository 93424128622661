import { formatValidUrl, isExternalUrl, navigationRoutes } from 'utilities';

export const getTileUrl = (tile) => {
  let path =
    tile.tileTypeId !== 2
      ? tile.embeddedLink
      : `${navigationRoutes.MicroSite}/${tile.id}`;
  const isExternalLink =
    isExternalUrl(path) ||
    tile.embeddedLink === navigationRoutes.Web2PublishWelcome;
  if (
    isExternalLink &&
    tile.embeddedLink !== navigationRoutes.Web2PublishWelcome
  ) {
    path = formatValidUrl(path);
  }

  return { path, isExternalLink };
};
