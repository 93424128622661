import { Layout, Row, Col, Form, Input, Radio, Spin } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import BaseInput from 'components/UI/BaseInput';
import BaseGroupOptions from 'components/UI/BaseGroupOptions';
import { selectW2PSingleContent } from 'redux/administration/content-management/w2p-single-content/selector';
import { STATUS, navigationRoutes, toast } from 'utilities';
import {
  getW2PSingleContent,
  resetW2PSingleContent,
  getMasterData,
  saveTempate,
  addRule,
  deleteRule,
  saveMappingRule,
  getMappingRules,
} from 'redux/administration/content-management/w2p-single-content/operation';
import Breadcrumb from 'components/UI/Breadcrumb';
import { BaseButton } from 'components/UI/BaseButton';
import MappingRule from './MappingRule';
import { DEFAULT_RULE } from './util/constants';

const init = {
  tags: [],
  categories: [],
  bodyLong: null,
  bodyShort: null,
  status: null,
};

const W2PSingleContent = () => {
  const { Content } = Layout;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams();
  const { w2pSingleContent } = useSelector((state) => ({
    w2pSingleContent: selectW2PSingleContent(state),
  }));
  const [formValue, setFormValue] = useState(init);

  useEffect(() => {
    setFormValue(w2pSingleContent.info ?? init);
  }, [w2pSingleContent.info]);

  useEffect(() => {
    dispatch(resetW2PSingleContent());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMasterData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getW2PSingleContent(params.id, params.title));
    dispatch(getMappingRules(params.id));
  }, [dispatch, params]);

  const onValueChange = useCallback(
    (name, value) => {
      switch (name) {
        case 'tags':
          setFormValue({ ...formValue, tags: value });
          break;
        case 'categories':
          setFormValue({ ...formValue, categories: value });
          break;
        case 'bodyLong':
          setFormValue({ ...formValue, bodyLong: value });
          break;
        case 'bodyShort':
          setFormValue({ ...formValue, bodyShort: value });
          break;
        case 'status':
          setFormValue({ ...formValue, status: value });
          break;
        case 'workspaceId':
          setFormValue({ ...formValue, workspaceId: value });
          break;
        case 'assetSize':
          setFormValue({ ...formValue, assetSize: value });
          break;
        case 'title':
          setFormValue({ ...formValue, title: value });
          break;
        default:
      }
    },
    [formValue],
  );

  const onAddRule = () => {
    let newRule = '';
    if (w2pSingleContent.rules.length === 0) {
      newRule = DEFAULT_RULE;
    } else {
      const lastRule =
        w2pSingleContent.rules[w2pSingleContent.rules.length - 1];

      const arrayRuleCharacter = lastRule.name.split(' ');
      const newOrder = parseInt(arrayRuleCharacter[1], 8) + 1;
      newRule = `${arrayRuleCharacter[0]} ${newOrder}`;
    }

    dispatch(addRule(newRule, params.id));
  };

  const onDeleteRule = useCallback(
    (item) => {
      dispatch(deleteRule(item.id, params.id));
    },
    [dispatch, params],
  );

  const onSubmit = () => {
    formValue.id = params.id;

    const templateMappingRule = {
      templateAccessMapping: [],
    };
    let flagErrorUserType = false;

    w2pSingleContent.rules.forEach((rule) => {
      const { userTypes, jobTypes } = rule;
      const userTypeChecked = userTypes.find((item) => item.isChecked === true);
      const jobTypeChecked = jobTypes.find((item) => item.isChecked === true);

      if (userTypeChecked) {
        const { companiesByRegion } = rule;
        const templateAccessFinal = {
          ruleId: rule.id,
          templateAccess: [],
        };

        companiesByRegion.forEach((item) => {
          if (item.isAccess) {
            const model = {
              ruleId: rule.id,
              companyId: item.id,
              userTypeId: userTypeChecked.id,
            };
            if (jobTypeChecked) {
              model.jobTypeId = jobTypeChecked.id;
            }
            templateAccessFinal.templateAccess.push(model);
          }
        });

        templateMappingRule.templateAccessMapping.push(templateAccessFinal);
      } else {
        flagErrorUserType = true;
      }
    });
    if (!flagErrorUserType) {
      dispatch(saveTempate(formValue));
      dispatch(saveMappingRule(templateMappingRule));
    } else {
      toast('error', 'User Type', 'Please choose user type for any rule');
    }
  };

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Home,
      },
      {
        title: 'Administration',
        path: navigationRoutes.UserManagement,
      },
      {
        title: 'Content Management',
        path: navigationRoutes.MapoContent,
      },
      {
        title: 'W2P',
        path: navigationRoutes.W2PContent,
      },
      {
        title: params.title,
      },
    ],
    [params],
  );

  const renderMappingRule = useCallback(() => {
    const mappingRule = [];
    w2pSingleContent.rules.forEach((item, index) => {
      const checkedRegion = item.regions.filter(
        (region) => region.isChecked === true,
      );
      const selectAllRegion = checkedRegion.length === item.regions.length;
      mappingRule.push(
        <Form.Item name={`RULE ${index + 1}`} key={item.id}>
          <span className="bold-text mr-4">{`RULE ${index + 1}`}</span>
          <DeleteOutlined onClick={() => onDeleteRule(item)} />
          <MappingRule ruleItem={item} selectAllRegion={selectAllRegion} />
        </Form.Item>,
      );
    });

    return <>{mappingRule}</>;
  }, [w2pSingleContent.rules, onDeleteRule]);

  return (
    <>
      <Content className="mapo-administrator-w2p-content-single">
        <Breadcrumb items={breadCrumbItems} />
        {w2pSingleContent.isLoading ? (
          <div className="loading">
            <Spin />
          </div>
        ) : (
          w2pSingleContent.info && (
            <div className="w2p-content-single">
              <div className="w2p-content-single-general">
                <div className="middle-content pt-5 pb-5">
                  <h1>{params.title} </h1>
                  <Row>
                    <Col xs={24} md={8} lg={8}>
                      <div className="d-flex flex-column align-start">
                        <span className="bold-text label">ID</span>
                        <span>{w2pSingleContent.info.id}</span>
                      </div>
                    </Col>
                    <Col xs={24} md={8} lg={8}>
                      <div className="d-flex flex-column align-start">
                        <span className="bold-text label">CONTENT STATUS</span>
                        <span
                          className={`status-${
                            w2pSingleContent.info?.status?.toLowerCase() ===
                            STATUS.ACTIVE
                              ? 'active'
                              : 'inactive'
                          } width-20`}>
                          {w2pSingleContent.info.status}
                        </span>
                      </div>
                    </Col>
                    <Col xs={24} md={8} lg={8}>
                      <div className="d-flex flex-column align-start">
                        <span className="bold-text label">CHILI LINK</span>
                        <a
                          className="editor-link"
                          href={w2pSingleContent.info.editor}>
                          {w2pSingleContent.info.editor}
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="w2p-content-single-information pt-5 pb-5">
                <div className="middle-content">
                  <Form
                    initialValues={{
                      title: w2pSingleContent.info.title,
                      bodyShort: w2pSingleContent.info.bodyShort,
                      bodyLong: w2pSingleContent.info.bodyLong,
                      createdAt: w2pSingleContent.info.createdAt
                        ? format(
                            new Date(w2pSingleContent.info.createdAt),
                            'MM/dd/yyyy hh:mm',
                          )
                        : 'N/A',
                      status: w2pSingleContent.info.status,
                      workspaceId: w2pSingleContent.info.workspaceId,
                      assetSize: w2pSingleContent.info.assetSize,
                    }}
                    className="w2p-content-single-form"
                    layout="vertical">
                    <Row>
                      <Col xs={24} md={16} lg={16}>
                        <h3>CONTENT INFORMATION </h3>
                        <Row>
                          <Col md={12} lg={12} className="mt-4">
                            <Form.Item label="TITLE/HEADLINE" name="title">
                              <BaseInput
                                onChange={(e) =>
                                  onValueChange('title', e.target.value)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col md={12} lg={12} className="mt-4">
                            <Form.Item label="CREATION DATE" name="createdAt">
                              <BaseInput disabled />
                            </Form.Item>
                          </Col>
                          <Col md={12} lg={12} className="mt-4">
                            <Form.Item label="CATEGORY TAGS" name="categories">
                              <BaseGroupOptions
                                isLoading={w2pSingleContent.isLoadMaster}
                                values={formValue.categories}
                                options={w2pSingleContent.categories}
                                onValueChange={(val) =>
                                  onValueChange('categories', val)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col md={24} lg={24} className="mt-4">
                            <Form.Item label="WORKSPACE ID" name="workspaceId">
                              <BaseInput
                                onChange={(e) =>
                                  onValueChange('workspaceId', e.target.value)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col md={24} lg={24} className="mt-4">
                            <Form.Item label="ASSET SIZE" name="assetSize">
                              <BaseInput
                                onChange={(e) =>
                                  onValueChange('assetSize', e.target.value)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col md={24} lg={24} className="mt-4">
                            <Form.Item label="TAGS" name="tags">
                              <BaseGroupOptions
                                isLoading={w2pSingleContent.isLoadMaster}
                                values={formValue.tags}
                                options={w2pSingleContent.tags}
                                onValueChange={(val) =>
                                  onValueChange('tags', val)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col md={24} lg={24} className="mt-4">
                            <Form.Item label="BODY SHORT" name="bodyShort">
                              <TextArea
                                onChange={(e) =>
                                  onValueChange('bodyShort', e.target.value)
                                }
                                className="textarea-w2p-content-single"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={24} lg={24} className="mt-4">
                            <Form.Item label="BODY LONG" name="bodyLong">
                              <TextArea
                                onChange={(e) =>
                                  onValueChange('bodyLong', e.target.value)
                                }
                                className="textarea-w2p-content-single"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={24} lg={24} className="mt-6">
                            <Spin spinning={w2pSingleContent.isLoadingRules}>
                              <hr align="left" className="divider-role mb-3" />
                              <span>ROLES AND RIGHTS </span>
                              <BaseButton
                                className="base-yellow-btn btn-add-rule mb-3"
                                onClick={onAddRule}>
                                ADD RULE
                              </BaseButton>
                              {renderMappingRule()}
                              <hr align="left" className="divider-role" />
                            </Spin>
                          </Col>
                          <Col md={12} lg={12} className="mt-4">
                            <Form.Item>
                              <BaseButton
                                onClick={() =>
                                  history.push(navigationRoutes.W2PContent)
                                }
                                className="btn-cancel">
                                CANCEL
                              </BaseButton>
                            </Form.Item>
                          </Col>
                          <Col md={12} lg={12} className="mt-4">
                            <Form.Item>
                              <BaseButton
                                onClick={onSubmit}
                                className="base-yellow-btn btn-save-changes"
                                loading={w2pSingleContent.isSaving}
                                htmlType="submit">
                                SAVE CHANGES
                              </BaseButton>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} md={8} lg={8}>
                        <h3>THUMBNAIL </h3>
                        <img
                          className="image"
                          alt=""
                          src={w2pSingleContent.info.image}
                        />

                        <h3>CONTENT STATUS </h3>
                        <Form.Item name="status" className="group-status mt-4">
                          <Radio.Group
                            onChange={(e) =>
                              onValueChange('status', e.target.value)
                            }>
                            <Radio.Button value="active">
                              <span className="label-radio">ACTIVE</span>
                            </Radio.Button>
                            <Radio.Button value="inactive" className="ml-4">
                              <span className="label-radio">INACTIVE</span>
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          )
        )}
      </Content>
    </>
  );
};

export default W2PSingleContent;
