import http from 'utilities/httpService';

export class TileService {
  static async getTileRole(relation) {
    return http.get(`/admin/tiles?_withs=${relation}`);
  }

  static async getTiles(page, limit, params) {
    let url = `/admin/tiles?_withs=type&_page=${page}&_limit=${limit}&_sort=tileOrder`;
    if (params && params.length) {
      const strParams = [];
      params.forEach((param) => {
        strParams.push(`${param.fieldName}=${param.value}`);
      });
      const joinedParam = strParams.join('&');
      url += `&${joinedParam}`;
    }
    return http.get(url);
  }

  static async getTile(id) {
    return http.get(`/admin/tiles/${id}`);
  }

  static async createTile(tile) {
    return http.post(`/admin/tiles`, tile);
  }

  static async updateTile(id, tile) {
    return http.put(`/admin/tiles/${id}`, tile);
  }

  static async getTileById(id, isEditing) {
    return isEditing
      ? http.get(
          `/admin/tiles/${id}?_withs=startPage,textSubPages,mediaSubPages`,
        )
      : http.get(
          `/admin/tiles/${id}?_withs=startPage,activeTextSubPages,activeMediaSubPages`,
        );
  }

  static async deleteTile(id) {
    return http.delete(`/admin/tiles/${id}`);
  }

  static async getMediaSubPage(id) {
    return http.get(
      `/admin/micro-site-media-sub-pages/${id}?_withs=categories,tile`,
    );
  }

  static async getTextSubPage(id) {
    return http.get(
      `/admin/micro-site-text-sub-pages/${id}?_withs=categories,tile`,
    );
  }

  static async getMenu() {
    return http.get(`/admin/menu`);
  }
}
