import { Col, Row } from 'antd';
import { useMemo } from 'react';
import { BaseButton } from 'components/UI/BaseButton';
import { useSelector } from 'react-redux';
import { selectPreviewStartPage } from 'redux/microSite/selector';
import PreviewComponent from 'components/UI/PreviewComponent';
import { STATUS } from 'utilities';

const PreviewStartPage = () => {
  const startPage = useSelector((state) => selectPreviewStartPage(state));

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
      },
      {
        title: startPage.title || '',
      },
    ],
    [startPage.title],
  );

  const subPages = useMemo(
    () =>
      startPage && startPage.subPages && startPage.subPages.length > 0
        ? startPage.subPages.filter(
            (subPage) => subPage.status === STATUS.ACTIVE,
          )
        : [],
    [startPage],
  );

  return (
    <PreviewComponent
      title={startPage?.title}
      breadCrumbItems={breadCrumbItems}
      contentClassName="micro-site-start-page preview-start-page"
      modalClassName="preview-modal-start-page"
      isHideFooter
      isHasModal>
      <div className="preview-start-page_banner">
        <div className="middle-content">
          <h2 className="preview-start-page-headline bold-text">
            {startPage?.headline}
          </h2>
          <p className="preview-start-page-text-headline bold-text">
            {startPage?.textHeadline}
          </p>
        </div>
      </div>
      {startPage.mediaHeadline && (
        <div className="preview-start-page-image">
          <img src={startPage.mediaHeadline} alt="" />
        </div>
      )}
      <div className="middle-content">
        {subPages.map((subPage) => (
          <Row
            className="preview-start-page-sub-headline"
            // gutter={8}
            key={subPage.embededLink}>
            <Col span={12}>
              <h2 className="preview-sub-headline-title mb-0">
                {subPage.title}
              </h2>
              <p className="preview-sub-headline-description">
                {subPage.textHeadline}
              </p>
              <BaseButton className="base-yellow-btn">VIEW HERE</BaseButton>
            </Col>
            {subPage.mediaHeadline ? (
              <Col className="preview-sub-headline-image" span={12}>
                <img src={subPage.mediaHeadline} alt="" />
              </Col>
            ) : null}
            <Col span={24} className="preview-sub-headline-divider">
              <hr />
            </Col>
          </Row>
        ))}
      </div>
    </PreviewComponent>
  );
};

export default PreviewStartPage;
