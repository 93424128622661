import axios from 'axios';

export class S3Service {
  static async uploadTos3(signedUrl, file) {
    return axios.put(signedUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  }
}
