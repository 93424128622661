import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { ADD_CMS_MENU, EDIT_CMS_MENU } from 'redux/administration/menu/action';
import { TileTypeService, TileService } from 'services';
import { toast } from 'utilities';
import {
  GET_TILE_TYPES_REQUEST,
  GET_TILE_TYPES_REQUEST_SUCCESS,
  GET_TILE_TYPES_REQUEST_FAIL,
  UPDATE_TILE_REQUEST,
  UPDATE_TILE_SUCCESS,
  UPDATE_TILE_FAIL,
  CREATE_REQUEST,
  CREATE_REQUEST_SUCCESS,
  CREATE_REQUEST_FAIL,
  GET_TILE_BY_ID,
  GET_TILE_BY_ID_SUCCESS,
  GET_TILE_BY_ID_FAIL,
  RESET_MAPO_SINGLE_CONTENT,
  UPDATE_PREVIEW_TILE,
} from './action';

export const getTileTypes = () => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_TILE_TYPES_REQUEST });
    const result = await TileTypeService.getTypeTiles();
    dispatch(hideLoading());
    dispatch({ type: GET_TILE_TYPES_REQUEST_SUCCESS, payload: result });
  } catch (err) {
    dispatch({ type: GET_TILE_TYPES_REQUEST_FAIL, err });
    dispatch(hideLoading());
  }
};

export const getTileById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: GET_TILE_BY_ID });
    const result = await TileService.getTile(id);
    const resTypes = await TileTypeService.getTypeTiles();
    dispatch(hideLoading());
    dispatch({
      type: GET_TILE_BY_ID_SUCCESS,
      payload: { tile: result.data, types: resTypes.data },
    });
  } catch (err) {
    dispatch({ type: GET_TILE_BY_ID_FAIL, err });
    dispatch(hideLoading());
  }
};

export const updateTile = (id, tile) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: UPDATE_TILE_REQUEST });
    const result = await TileService.updateTile(id, tile);
    dispatch(hideLoading());
    dispatch({ type: UPDATE_TILE_SUCCESS, payload: result });
    dispatch({ type: EDIT_CMS_MENU, payload: result.data });
    toast('success', 'Tile', 'Updated Successfully');
  } catch (err) {
    dispatch({ type: UPDATE_TILE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const createTile = (tile) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: CREATE_REQUEST });
    const result = await TileService.createTile(tile);
    dispatch(hideLoading());
    dispatch({ type: CREATE_REQUEST_SUCCESS, payload: result });
    const cmsData = result.data;
    dispatch({
      type: ADD_CMS_MENU,
      payload: {
        id: cmsData.id,
        title: cmsData.title,
        isCMS: true,
        children: [],
        embeddedLink: `/mapo-administration/micro-site/${cmsData.id}`,
      },
    });
    toast('success', 'Tile', 'Create Successfully');
  } catch (err) {
    dispatch({ type: CREATE_REQUEST_FAIL, err });
    dispatch(hideLoading());
  }
};

export const resetTileContent = () => (dispatch) => {
  dispatch({ type: RESET_MAPO_SINGLE_CONTENT });
};

export const updatePreviewTile = (data) => (dispatch) => {
  dispatch({ type: UPDATE_PREVIEW_TILE, payload: data });
};
