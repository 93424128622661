export const selectReducer = (state) => state.mappingRules;

export const selectBusinessInfo = (state) =>
  selectReducer(state) ? selectReducer(state).get('businessInfo') : undefined;

export const selectAllRegions = (state) =>
  selectReducer(state) ? selectReducer(state).get('regions').data : [];

export const selectIsLoadingRegions = (state) =>
  selectReducer(state) ? selectReducer(state).get('regions').isLoading : false;
