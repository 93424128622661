import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { TileService } from 'services';

import {
  GET_CMS_MENU,
  GET_CMS_MENU_SUCCESS,
  GET_CMS_MENU_FAIL,
} from './action';

export const getCmsMenu = () => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_CMS_MENU, payload: true });
    const result = await TileService.getMenu();
    dispatch(hideLoading());
    dispatch({ type: GET_CMS_MENU_SUCCESS, payload: result.data });
  } catch (err) {
    dispatch({ type: GET_CMS_MENU_FAIL, err });
    dispatch(hideLoading());
  }
};
