import immutable from 'immutable';
import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
} from './action';

const init = () => {
  const initValue = immutable.fromJS({
    category: undefined,
  });

  return initValue.set('category', {
    categories: [],
    isLoading: false,
  });
};

export default function categoriesReducer(state = init(), action) {
  switch (action.type) {
    case GET_CATEGORIES:
      return state.update('category', (category) => ({
        ...category,
        isLoading: true,
      }));
    case GET_CATEGORIES_SUCCESS:
      return state.update('category', (category) => ({
        ...category,
        categories: action.payload.data,
        isLoading: false,
      }));
    case GET_CATEGORIES_FAIL:
      return state;
    default:
      return state;
  }
}
