import immutable from 'immutable';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import groupBy from 'lodash/groupBy';

import {
  GET_MASTER_DATA,
  GET_MASTER_DATA_FAIL,
  GET_MASTER_DATA_SUCCESS,
  GET_W2P_SINGLE_CONTENT_FAIL,
  GET_W2P_SINGLE_CONTENT_REQUEST,
  GET_W2P_SINGLE_CONTENT_SUCCESS,
  RESET_W2P_SINGLE_CONTENT,
  SAVE_TEMPLATE,
  SAVE_TEMPLATE_FAIL,
  SAVE_TEMPLATE_SUCCESS,
  GET_COMPANY_FAIL,
  GET_COMPANY_SUCCESS,
  RESET_COMPANY,
  ADD_RULE_REQUEST,
  ADD_RULE_FAIL,
  ADD_RULE_SUCCESS,
  DELETE_RULE_SUCCESS,
  DELETE_RULE_REQUEST,
  DELETE_RULE_FAIL,
  UPDATE_REGION,
  UPDATE_SINGLE_COMPANY,
  UPDATE_USER_TYPE,
  UPDATE_JOB_TYPE,
  SAVE_MAPPING_RULE,
  SAVE_MAPPING_RULE_FAIL,
  SAVE_MAPPING_RULE_SUCCESS,
  GET_MAPPING_RULES_REQUEST,
  GET_MAPPING_RULES_FAIL,
  GET_MAPPING_RULES_SUCCESS,
  UPDATE_SELECTED_REGION,
  UPDATE_ALL_REGION,
} from './action';

const init = () => {
  const initValue = immutable.fromJS({
    w2pSingleContent: undefined,
  });

  return initValue.set('w2pSingleContent', {
    info: null,
    isLoading: false,
    isSaving: false,
    isLoadMaster: false,
    tags: [],
    categories: [],
    rules: [],
    isLoadingRules: false,
  });
};

export default function w2pSingleContentReducer(state = init(), action) {
  switch (action.type) {
    case GET_W2P_SINGLE_CONTENT_REQUEST:
      return state.update('w2pSingleContent', (w2pSingleContent) => ({
        ...w2pSingleContent,
        isLoading: true,
      }));
    case GET_W2P_SINGLE_CONTENT_SUCCESS: {
      const { payload } = action;
      return state.update('w2pSingleContent', (w2pSingleContent) => ({
        ...w2pSingleContent,
        info: payload,
        isLoading: false,
      }));
    }
    case GET_MASTER_DATA: {
      return state.update('w2pSingleContent', (w2pSingleContent) => ({
        ...w2pSingleContent,
        isLoadMaster: true,
      }));
    }
    case GET_MASTER_DATA_SUCCESS: {
      const { tags, categories } = action.payload;
      return state.update('w2pSingleContent', (w2pSingleContent) => {
        return {
          ...w2pSingleContent,
          tags,
          categories,
          isLoadMaster: false,
        };
      });
    }
    case GET_W2P_SINGLE_CONTENT_FAIL:
    case GET_MASTER_DATA_FAIL:
      return state.update('w2pSingleContent', (w2pSingleContent) => ({
        ...w2pSingleContent,
        isLoading: false,
        isLoadMaster: false,
      }));
    case RESET_W2P_SINGLE_CONTENT:
      return state.update('w2pSingleContent', (w2pSingleContent) => ({
        ...w2pSingleContent,
        info: null,
        isLoading: false,
      }));
    case SAVE_TEMPLATE:
    case SAVE_MAPPING_RULE:
      return state.update('w2pSingleContent', (w2pSingleContent) => ({
        ...w2pSingleContent,
        isSaving: true,
      }));
    case SAVE_TEMPLATE_FAIL:
    case SAVE_TEMPLATE_SUCCESS:
    case SAVE_MAPPING_RULE_FAIL:
    case SAVE_MAPPING_RULE_SUCCESS:
      return state.update('w2pSingleContent', (w2pSingleContent) => ({
        ...w2pSingleContent,
        isSaving: false,
      }));

    case GET_MAPPING_RULES_REQUEST:
    case DELETE_RULE_REQUEST:
    case ADD_RULE_REQUEST:
      return state.update('w2pSingleContent', (w2pSingleContent) => ({
        ...w2pSingleContent,
        isLoadingRules: true,
      }));
    case GET_MAPPING_RULES_SUCCESS: {
      const { payload } = action;
      const newRules = payload;
      return state.update('w2pSingleContent', (w2pSingleContent) => {
        let currentRules = [...w2pSingleContent.rules];
        if (currentRules.length < newRules.length) {
          const item = newRules.filter(
            (newRule) =>
              !currentRules.find(
                (currentRule) => currentRule.id === newRule.id,
              ),
          );
          if (item && item.length > 0) {
            currentRules.push(...item);
          }

          return {
            ...w2pSingleContent,
            rules: currentRules,
            isLoadingRules: false,
          };
        }

        if (currentRules.length > newRules.length) {
          const item = currentRules.find(
            (currentRule) =>
              !newRules.find((newRule) => newRule.id === currentRule.id),
          );
          if (item) {
            currentRules = currentRules.filter(
              (currentRule) => currentRule.id !== item.id,
            );
          }

          return {
            ...w2pSingleContent,
            rules: currentRules,
            isLoadingRules: false,
          };
        }

        return {
          ...w2pSingleContent,
          rules: newRules,
          isLoadingRules: false,
        };
      });
    }
    case GET_MAPPING_RULES_FAIL:
    case DELETE_RULE_FAIL:
    case ADD_RULE_FAIL:
    case DELETE_RULE_SUCCESS:
    case ADD_RULE_SUCCESS:
      return state.update('w2pSingleContent', (w2pSingleContent) => ({
        ...w2pSingleContent,
        isLoadingRules: false,
      }));
    case GET_COMPANY_SUCCESS: {
      const { payload } = action;
      return state.update('w2pSingleContent', (w2pSingleContent) => {
        const rules = w2pSingleContent.rules.map((item) => {
          if (item.id === payload.ruleItem.id) {
            const newCompanies = payload.companies.map((company) => {
              const foundCompany = item.templateAccess.find(
                (template) => template.id === company.id,
              );

              const foundRegion = item.regions.find(
                (region) => region.id === company.regionId,
              );

              if (foundCompany || foundRegion.isChecked === true) {
                return {
                  ...company,
                  isAccess: true,
                };
              }
              return company;
            });

            const groupCompaniesByRegion = groupBy(newCompanies, 'regionId');
            const newRegions = item.regions.map((region) => {
              const companyByRegion = groupCompaniesByRegion[region.id];
              if (companyByRegion) {
                const companyHasAccess = companyByRegion.filter(
                  (company) => company.isAccess === true,
                );
                if (companyHasAccess.length === companyByRegion.length) {
                  region.isChecked = true;
                }
              }

              return region;
            });

            return {
              ...item,
              companiesByRegion: newCompanies,
              regions: newRegions,
            };
          }
          return item;
        });

        return {
          ...w2pSingleContent,
          rules,
        };
      });
    }

    case GET_COMPANY_FAIL: {
      const { payload } = action;
      return state.update('w2pSingleContent', (w2pSingleContent) => {
        const rules = w2pSingleContent.rules.map((item) => {
          if (item.id === payload.ruleItem.id) {
            return {
              ...item,
              companiesByRegion: payload.companies,
            };
          }
          return item;
        });

        return {
          ...w2pSingleContent,
          rules,
        };
      });
    }
    case RESET_COMPANY: {
      const { payload } = action;
      return state.update('w2pSingleContent', (w2pSingleContent) => {
        const rules = w2pSingleContent.rules.map((item) => {
          if (item.id === payload.ruleItem.id) {
            return {
              ...item,
              companiesByRegion: [],
            };
          }
          return item;
        });

        return {
          ...w2pSingleContent,
          rules,
        };
      });
    }
    case UPDATE_REGION: {
      const { payload } = action;
      return state.update('w2pSingleContent', (w2pSingleContent) => {
        const rules = w2pSingleContent.rules.map((item) => {
          if (item.id === payload.ruleItem.id) {
            const newRegions = item.regions.map((region) => {
              if (region.id === payload.region.id) {
                region.isChecked = payload.hasAccess;
              }
              return region;
            });

            const newCompanies = item.companiesByRegion.map((company) => {
              if (company.regionId === payload.region.id) {
                return {
                  ...company,
                  isAccess: payload.hasAccess,
                };
              }
              return company;
            });

            return {
              ...item,
              regions: newRegions,
              companiesByRegion: newCompanies,
            };
          }
          return item;
        });

        return {
          ...w2pSingleContent,
          rules,
        };
      });
    }

    case UPDATE_ALL_REGION: {
      const { payload } = action;
      return state.update('w2pSingleContent', (w2pSingleContent) => {
        const rules = w2pSingleContent.rules.map((item) => {
          if (item.id === payload.ruleItem.id) {
            const newRegions = item.regions.map((region) => {
              region.isChecked = payload.hasAccess;
              return region;
            });

            const newCompanies = item.companiesByRegion.map((company) => {
              return {
                ...company,
                isAccess: payload.hasAccess,
              };
            });

            return {
              ...item,
              regions: newRegions,
              companiesByRegion: newCompanies,
            };
          }
          return item;
        });

        return {
          ...w2pSingleContent,
          rules,
        };
      });
    }

    case UPDATE_SINGLE_COMPANY: {
      const { payload } = action;
      return state.update('w2pSingleContent', (w2pSingleContent) => {
        const rules = w2pSingleContent.rules.map((item) => {
          if (item.id === payload.ruleItem.id) {
            const newCompanies = item.companiesByRegion.map((company) => {
              if (company.id === payload.company.id) {
                return {
                  ...company,
                  isAccess: payload.hasAccess,
                };
              }
              return company;
            });

            const groupCompanies = newCompanies.reduce((r, a) => {
              r[a.regionId] = [...(r[a.regionId] || []), a];
              return r;
            }, {});

            const companyByRegion = groupCompanies[payload.company.regionId];

            const companyHasAccess = companyByRegion.filter(
              (company) => company.isAccess === true,
            );

            let newRegions = null;
            if (companyHasAccess.length === companyByRegion.length) {
              newRegions = item.regions.map((region) => {
                if (region.id === payload.company.regionId) {
                  region.isChecked = true;
                }
                return region;
              });
            } else {
              newRegions = item.regions.map((region) => {
                if (region.id === payload.company.regionId) {
                  region.isChecked = false;
                }
                return region;
              });
            }

            return {
              ...item,
              companiesByRegion: newCompanies,
              regions: newRegions,
            };
          }
          return item;
        });

        return {
          ...w2pSingleContent,
          rules,
        };
      });
    }
    case UPDATE_USER_TYPE: {
      const { payload } = action;
      return state.update('w2pSingleContent', (w2pSingleContent) => {
        const rules = w2pSingleContent.rules.map((item) => {
          if (item.id === payload.ruleItem.id) {
            const newUserTypes = item.userTypes.map((userType) => {
              if (userType.id === payload.userType.id) {
                userType.isChecked = payload.checked;
              } else {
                userType.isChecked = false;
              }
              return userType;
            });
            return {
              ...item,
              userTypes: newUserTypes,
            };
          }
          return item;
        });

        return {
          ...w2pSingleContent,
          rules,
        };
      });
    }
    case UPDATE_JOB_TYPE: {
      const { payload } = action;
      return state.update('w2pSingleContent', (w2pSingleContent) => {
        const rules = w2pSingleContent.rules.map((item) => {
          if (item.id === payload.ruleItem.id) {
            const newJobTypes = item.jobTypes.map((jobType) => {
              if (jobType.id === payload.jobType.id) {
                jobType.isChecked = payload.checked;
              } else {
                jobType.isChecked = false;
              }
              return jobType;
            });
            return {
              ...item,
              jobTypes: newJobTypes,
            };
          }
          return item;
        });

        return {
          ...w2pSingleContent,
          rules,
        };
      });
    }
    case UPDATE_SELECTED_REGION: {
      const { payload } = action;
      return state.update('w2pSingleContent', (w2pSingleContent) => {
        const rules = w2pSingleContent.rules.map((item) => {
          if (item.id === payload.ruleItem.id) {
            let newSelectedRegions = [...item.selectedRegions];
            if (payload.regionId) {
              newSelectedRegions.push(payload.regionId);
            }

            if (payload.regions) {
              newSelectedRegions = payload.regions;
            }

            newSelectedRegions = uniqWith(newSelectedRegions, isEqual);
            return {
              ...item,
              selectedRegions: newSelectedRegions,
            };
          }
          return item;
        });

        return {
          ...w2pSingleContent,
          rules,
        };
      });
    }
    default:
      return state;
  }
}
