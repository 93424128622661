import { Layout } from 'antd';
import Breadcrumb from 'components/UI/Breadcrumb';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { headerTitle, navigationRoutes, ROUTE } from 'utilities';
import { useLocation } from 'react-router-dom';
import { UPDATE_HEADER_TITLE } from 'redux/global/header/action';
import ScrollToTop from '../ScrollToTop';

export const Imprint = () => {
  const { Content } = Layout;
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isInW2P = useMemo(() => {
    return pathname.indexOf(ROUTE.W2P) !== -1;
  }, [pathname]);

  useEffect(() => {
    if (!isInW2P) {
      dispatch({
        type: UPDATE_HEADER_TITLE,
        payload: { title: headerTitle.mapo },
      });
    }
  }, [dispatch, isInW2P]);

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: isInW2P
          ? navigationRoutes.Web2PublishMain
          : navigationRoutes.Home,
      },
      {
        title: 'Imprint',
      },
    ],
    [isInW2P],
  );

  return (
    <Content className="imprint-container">
      <Breadcrumb items={breadCrumbItems} />
      <div className="imprint-banner">
        <h1 className="middle-content font-size-big bold-text">IMPRINT</h1>
      </div>
      <div className="imprint-content">
        <div className="middle-content">
          <h2 className="font-size-medium bold-text">
            Alfred Kärcher SE & Co. KG
          </h2>
          <p>Alfred-Kärcher-Strasse 28-40</p>
          <p>
            71364 Winnenden / Germany
            <br />
            P.O. Box 160
            <br />
            71349 Winnenden / Germany
          </p>
          <p>
            Tel. +49 7195 / 14-0
            <br />
            Fax +49 7195 / 14-2212
            <br />
            E-mail: info@karcher.com
          </p>
          <p>
            Alfred Kärcher SE & Co. Kommanditgesellschaft (private limited
            partnership)
            <br />
            Registered office: Winnenden
            <br />
            Court of Registration: Stuttgart, HRA 260 169
          </p>
          <p>
            General partner:
            <br />
            Kärcher SE, registered office: Winnenden,
            <br />
            Court of Registration: Stuttgart, HRB 765 434
          </p>
          <p>
            VAT ID no: DE 147223775
            <br />
            ILN: 4039784000001
          </p>
          <p>
            Board of Management:
            <br />
            Hartmut Jenner (Chairman),
            <br />
            Christian May (Deputy Chairman),
            <br />
            Dieter Grajer,
            <br />
            Stefan Patzke,
            <br />
            Michael Häusermann
          </p>
          <p>
            Chairman of the Supervisory Board:
            <br />
            Johannes Kärcher
          </p>
        </div>
      </div>
      <ScrollToTop />
    </Content>
  );
};
