import http from 'utilities/httpService';

export class MicroSiteService {
  static async updateStartPage(id, startPage) {
    return http.put(`/admin/micro-site-start-pages/${id}`, startPage);
  }

  static async createTextSubPage(subPage) {
    return http.post(`/admin/micro-site-text-sub-pages`, subPage);
  }

  static async updateTextSubPage(id, subPage) {
    return http.put(`/admin/micro-site-text-sub-pages/${id}`, subPage);
  }

  static async deleteTextSubPage(id) {
    return http.delete(`/admin/micro-site-text-sub-pages/${id}`);
  }

  static async createMediaSubPage(subPage) {
    return http.post(`/admin/micro-site-media-sub-pages`, subPage);
  }

  static async updateMediaSubPage(id, subPage) {
    return http.put(`/admin/micro-site-media-sub-pages/${id}`, subPage);
  }

  static async deleteMediaSubPage(id) {
    return http.delete(`/admin/micro-site-media-sub-pages/${id}`);
  }
}
