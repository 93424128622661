export const selectReducer = (state) => state.home;

export const selectIsLoading = (state) =>
  selectReducer(state) ? selectReducer(state).get('tile').isLoading : false;

export const selectTiles = (state) =>
  selectReducer(state) ? selectReducer(state).get('tile').tiles : [];

export const selectUserRole = (state) =>
  selectReducer(state) ? selectReducer(state).get('tile').userRole : null;
