import { navigationPaths } from 'utilities';
import Web2PublishWelcome from 'screens/Web2Publish/welcome';
import Web2PublishMain from 'screens/Web2Publish/main';
import Web2PublishTemplatesByTagAndCat from 'screens/Web2Publish/templateByCatAndTag';
import SingleAsset from 'screens/Web2Publish/singleAsset';
import MyTemplate from 'screens/Web2Publish/myTemplates';
import ScreenChiliEditor from 'screens/Web2Publish/chiliEditor';
import ScreenMyProfile from 'screens/MyProfile';
import ScreenImprint from 'screens/Imprint';
import ScreenDisclaimer from 'screens/Disclaimer';
import ScreenTermsAndConditions from 'screens/TermsAndConditions';
import ScreenPrivacyPolicy from 'screens/PrivacyPolicy';

export default [
  {
    path: navigationPaths.Web2PublishWelcome,
    component: Web2PublishWelcome,
    exact: true,
  },
  {
    path: navigationPaths.Web2PublishMain,
    component: Web2PublishMain,
    exact: true,
  },
  {
    path: navigationPaths.Web2PublishTemplatesByCat,
    component: Web2PublishTemplatesByTagAndCat,
    exact: true,
  },
  {
    path: navigationPaths.SingleAsset,
    component: SingleAsset,
    exact: true,
  },
  {
    path: navigationPaths.W2PTemplateEditor,
    component: ScreenChiliEditor,
    exact: true,
  },
  {
    path: navigationPaths.W2PTemplateEditorEdit,
    component: ScreenChiliEditor,
    exact: true,
  },
  {
    path: navigationPaths.MyTemplate,
    component: MyTemplate,
    exact: true,
  },
  {
    path: navigationPaths.Web2PublishMyProfile,
    component: ScreenMyProfile,
    exact: true,
  },
  {
    path: navigationPaths.W2PImprint,
    component: ScreenImprint,
    exact: true,
  },
  {
    path: navigationPaths.W2PDisclaimer,
    component: ScreenDisclaimer,
    exact: true,
  },
  {
    path: navigationPaths.W2PTermsAndConditions,
    component: ScreenTermsAndConditions,
    exact: true,
  },
  {
    path: navigationPaths.W2PPrivacyPolicy,
    component: ScreenPrivacyPolicy,
    exact: true,
  },
];
