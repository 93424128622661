export const convertDataResponse = (dataResponse) => {
  const result = dataResponse.map((user) => {
    user.userTileRoles.forEach((userTileRole) => {
      user[userTileRole.tile?.title?.replace(/\s/g, '_')] =
        userTileRole.role.name;
    });

    return {
      ...user,
      region: user.region ? user.region.name : '',
      company: user.company ? user.company.id : '',
      jobType: user.jobType ? user.jobType.name : '',
    };
  });

  return result;
};

export const singleDataResponse = (dataResponse) => {
  dataResponse.userTileRoles.forEach((tileRole) => {
    dataResponse[tileRole.tile?.title?.replace(/\s/g, '_')] = {
      id: tileRole.tile.id,
      name: tileRole.role.name,
      idTileRole: tileRole.role.id,
      typeId: tileRole.tileTypeRoleId,
    };
  });

  return {
    ...dataResponse,
    region: dataResponse.region ? dataResponse.region : { id: '', name: '' },
    company: dataResponse.company ? dataResponse.company : { id: '', name: '' },
    jobType: dataResponse.jobType ? dataResponse.jobType : { id: '', name: '' },
  };
};
