import immutable from 'immutable';
import {
  GET_MY_DOCUMENT_FAIL,
  GET_MY_DOCUMENT_REQUEST,
  GET_MY_DOCUMENT_SUCCESS,
} from './action';

const init = () => {
  const initValue = immutable.fromJS({
    myDocument: undefined,
  });

  return initValue.set('myDocument', {
    info: [],
    isLoading: false,
  });
};

export default function myDocumentReducer(state = init(), action) {
  switch (action.type) {
    case GET_MY_DOCUMENT_REQUEST:
      return state.update('myDocument', (myDocument) => ({
        ...myDocument,
        isLoading: true,
      }));
    case GET_MY_DOCUMENT_SUCCESS: {
      const { payload } = action;
      return state.update('myDocument', (myDocument) => ({
        ...myDocument,
        info: payload,
        isLoading: false,
      }));
    }
    case GET_MY_DOCUMENT_FAIL:
      return state.update('myDocument', (myDocument) => ({
        ...myDocument,
        isLoading: false,
      }));
    default:
      return state;
  }
}
