import { useMemo } from 'react';
import { Layout } from 'antd';
import Breadcrumb from 'components/UI/Breadcrumb';
import BaseTabs from 'components/UI/BaseTabs';
import { navigationRoutes } from 'utilities';
import UserManagementList from './UserManagementList';

const UserManagement = () => {
  const { Content } = Layout;

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Home,
      },
      {
        title: 'Administration',
        path: navigationRoutes.UserManagement,
      },
      {
        title: 'User Management',
      },
    ],
    [],
  );

  return (
    <Content className="mapo-administrator-user_content">
      <Breadcrumb items={breadCrumbItems} />
      <div className="user-management-content_banner">
        <div className="user-management-content middle-content">
          <h1>USER MANAGEMENT</h1>
        </div>
      </div>
      <div className="user-management-tabs">
        <BaseTabs
          initialPanes={[
            { title: 'Users', content: <UserManagementList />, key: '1' },
          ]}
        />
      </div>
    </Content>
  );
};

export default UserManagement;
