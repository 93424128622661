import { Row, Col, Select } from 'antd';
import PropType from 'prop-types';
import { useDispatch } from 'react-redux';
import { BaseButton } from 'components/UI/BaseButton';
import { updateUserLanguage } from 'redux/userProfile/operation';
import { useCallback, useState } from 'react';
import { displayUserLanguage } from '../util/commons';

export const UserInformation = ({ user }) => {
  const { Option } = Select;
  const [isEditable, setIsEditable] = useState(false);
  const [langCode, setLangCode] = useState(user.langCode || undefined);
  const dispatch = useDispatch();

  const onChangeLanguage = useCallback((value) => {
    setLangCode(value);
  }, []);

  const onUpdateUserLanguage = useCallback(() => {
    if (isEditable) {
      dispatch(updateUserLanguage(user.id, { langCode }));
      setIsEditable(false);
    } else {
      setIsEditable(true);
    }
  }, [dispatch, isEditable, langCode, user.id]);

  const onCancel = useCallback(() => {
    setIsEditable(false);
    setLangCode(user.langCode || undefined);
  }, [user.langCode]);

  return (
    <>
      <h2 className="user-information--title bold-text uppercase-text">
        USER INFORMATION
      </h2>
      <Col span={12}>
        <Row className="user-information_row">
          <Col className="user-information_item" lg={12} sm={24}>
            <p className="bold-text">{user.fullname}</p>
            <p>{user.email}</p>
            <p>{user.phoneNumber}</p>
          </Col>
          <Col className="user-information_item" lg={12} sm={24}>
            <p className="bold-text">INFORMATION ID</p>
            <p>{user.id}</p>
          </Col>
        </Row>
        <Row className="user-information_row">
          <Col className="user-information_item" lg={12} sm={24}>
            <p className="bold-text">Company</p>
            <p>{user.company || 'N/A'}</p>
          </Col>
          <Col className="user-information_item" lg={12} sm={24}>
            <p className="bold-text">Language</p>
            {isEditable ? (
              <Select
                className="base-select"
                dropdownClassName="user-language-select"
                placeholder="Select language"
                defaultValue={user.langCode}
                onChange={onChangeLanguage}>
                {user.languages.map((language) => (
                  <Option key={language.id} value={language.id}>
                    {language.name}
                  </Option>
                ))}
              </Select>
            ) : (
              <p>{displayUserLanguage(user.languages, user.langCode)}</p>
            )}
          </Col>
        </Row>
        <Row className="user-information_row d-flex justify-space-between mt-6">
          {isEditable ? (
            <BaseButton onClick={onCancel}>CANCEL</BaseButton>
          ) : null}
          <BaseButton
            onClick={onUpdateUserLanguage}
            className="base-yellow-btn">
            {isEditable ? 'SAVE CHANGES' : 'EDIT PROFILE'}
          </BaseButton>
        </Row>
      </Col>
    </>
  );
};

UserInformation.propTypes = {
  user: PropType.shape({
    fullname: PropType.string,
    email: PropType.string,
    phoneNumber: PropType.string,
    id: PropType.string,
    company: PropType.string,
    langCode: PropType.string,
    languages: PropType.arrayOf(PropType.shape({})),
  }),
};

UserInformation.defaultProps = {
  user: {
    fullname: '',
    email: '',
    phoneNumber: '',
    id: '',
    company: '',
    langCode: '',
    languages: [],
  },
};
