export const headerTitle = {
  mapo: 'MARKETING PORTAL',
  web2Publish: 'WEB 2 PUBLISH',
};

export const CREATE_STATUS = {
  DEFAULT: 0,
  FAIL: 1,
  SUCCESS: 2,
};

export const UPDATE_STATUS = {
  DEFAULT: 0,
  FAIL: 1,
  SUCCESS: 2,
};

export const SUB_PAGE_TYPES = {
  TEXT: 'text',
  MEDIA: 'media',
};

export const UPDATE_TYPE = {
  UNCHANGED: 0,
  ADDED: 1,
  DELETED: 2,
  UPDATED: 3,
};

export const ERROR = {
  PERMISSION_DENIED: 403,
  NOT_ACCEPTABLE_USERNAME: 406,
};

export const NOT_ACCEPTABLE_ERROR = {
  MISSING_EMAIL: 'MISSING_EMAIL',
  MISSING_NAME: 'MISSING_NAME',
  MISSING_NAME_EMAIL: 'MISSING_NAME_EMAIL',
};

export const ROUTE = {
  HOME: 'home',
  W2P: 'web2Publish',
  MAPO: 'mapo-administration',
  MICRO_SITE: 'micro-site',
  MY_PROFILE: 'my-profile',
  IMPRINT: 'imprint',
  DISCLAIMER: 'disclaimer',
  SUBMIT_INFO: 'submit-info',
};

export const CATEGORY = {
  WEB: 'Web',
  FLYER: 'Flyer',
  POSTER: 'Poster',
};

export const INTERNAL_PROVIDER_NAME =
  process.env.REACT_APP_AWS_USER_POOLS_INTERNAL_PROVIDER_NAME;
export const EXTERNAL_PROVIDER_NAME =
  process.env.REACT_APP_AWS_USER_POOLS_EXTERNAL_PROVIDER_NAME;
export const DEALER_PROVIDER_NAME =
  process.env.REACT_APP_AWS_USER_POOLS_DEALER_PROVIDER_NAME;

export const BUSINESS_TYPE = {
  REGION: 'Region',
  COMPANY: 'Company',
  JOB_TYPE: 'Job Types',
};

export const pickWithType = (type) => {
  switch (type.toLowerCase()) {
    case 'region':
      return 'region';
    case 'company':
      return 'company';
    case 'job types':
      return 'jobType';
    default:
  }
  return null;
};

export const BROWSER_TITLE = {
  MAPO: 'Marketing Portal',
  W2P: 'Web 2 Publish',
};

export const INACTIVE_USER_ERRORS = ['Unrecognizable+lambda+output+'];

export const APP_DOMAIN = 'kaercher.com';

export const LOGIN_TYPE = {
  DEFAULT: 'DEFAULT',
  DEALER_NAME: 'DEALER_NAME',
  DEALER_EMAIL: 'DEALER_EMAIL',
  DEALER_NAME_AND_EMAIL: 'DEALER_NAME_AND_EMAIL',
};
