export const GET_BUSINESS_INFOS = 'GET_BUSINESS_INFOS';
export const GET_BUSINESS_INFOS_SUCCESS = 'GET_BUSINESS_INFOS_SUCCESS';
export const GET_BUSINESS_INFOS_FAIL = 'GET_BUSINESS_INFOS_FAIL';
export const UPDATE_BUSINESS_INFOS_RULES = 'UPDATE_BUSINESS_INFOS_RULES';
export const UPDATE_BUSINESS_INFOS_RULES_SUCCESS =
  'UPDATE_BUSINESS_INFOS_RULES_SUCCESS';
export const UPDATE_BUSINESS_INFOS_RULES_FAIL =
  'UPDATE_BUSINESS_INFOS_RULES_FAIL';
export const UPDATE_ALL_BUSINESS_INFOS_RULES =
  'UPDATE_ALL_BUSINESS_INFOS_RULES';
export const UPDATE_ALL_BUSINESS_INFOS_RULES_SUCCESS =
  'UPDATE_ALL_BUSINESS_INFOS_RULES_SUCCESS';
export const UPDATE_ALL_BUSINESS_INFOS_RULES_FAIL =
  'UPDATE_ALL_BUSINESS_INFOS_RULES_FAIL';
export const UPDATE_TILE_BUSINESS_INFOS_RULES =
  'UPDATE_TILE_BUSINESS_INFOS_RULES';
export const UPDATE_TILE_BUSINESS_INFOS_RULES_SUCCESS =
  'UPDATE_TILE_BUSINESS_INFOS_RULES_SUCCESS';
export const UPDATE_TILE_BUSINESS_INFOS_RULES_FAIL =
  'UPDATE_TILE_BUSINESS_INFOS_RULES_FAIL';

export const GET_ALL_REGIONS_REQUEST = 'GET_ALL_REGIONS_REQUEST';
export const GET_ALL_REGIONS_SUCCESS = 'GET_ALL_REGIONS_SUCCESS';
export const GET_ALL_REGIONS_ERROR = 'GET_ALL_REGIONS_ERROR';
