import { pickWithType } from 'utilities';
import http from 'utilities/httpService';

const pickType = (type) => {
  switch (type.toLowerCase()) {
    case 'region':
      return 'regions';
    case 'company':
      return 'companies';
    case 'job types':
      return 'job-types';
    default:
  }
  return null;
};

export class BusinessInfosService {
  static async getBusinessInfos(type, metadata) {
    const strParams = [];
    Object.keys(metadata).forEach((param) => {
      strParams.push(`${param}=${metadata[param]}`);
    });
    const newType = pickType(type);
    const withType = pickWithType(type);
    return http.get(
      `/admin/${newType}?_withs=${withType}TileAccess&_sort=name&${strParams.join(
        '&',
      )}`,
    );
  }

  static async updateBusinessInfos(type, newRules) {
    const newType = pickType(type);
    return http.put(`/admin/${newType}/update-access`, newRules);
  }

  static async updateAllBusinessInfos(type, newRules) {
    const newType = pickType(type);
    return http.put(`/admin/${newType}/update-all-access`, newRules);
  }

  static async updateTileBusinessInfos(type, newRules) {
    const newType = pickType(type);
    return http.put(`/admin/${newType}/update-tile-access`, newRules);
  }

  static async getRegions() {
    return http.get(`/admin/regions?_limit=100`);
  }

  static async getCompaniesByRegions(id) {
    return http.get(`/admin/companies?_limit=100&_withs=region&regionId=${id}`);
  }
}
