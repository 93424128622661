import { Spin, Row, Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropType from 'prop-types';
import { selectMyTemplate } from 'redux/w2p/my-templates/selector';
import { getMyTemplate } from 'redux/w2p/my-templates/operation';
import { navigationRoutes } from 'utilities';
import MyTemplateItem from './item';

const MyTemplate = ({ breadCrumbItems }) => {
  const myTemplate = useSelector((state) => selectMyTemplate(state));

  const onClickBaseTemplateItem = (templateItem) => {
    history.push(
      `${navigationRoutes.W2PTemplateEditor}/${templateItem.userTemplate}/true`,
      breadCrumbItems,
    );
  };

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getMyTemplate());
  }, [dispatch]);

  return (
    <div className="middle-content">
      {myTemplate.isLoading ? (
        <div className="loading">
          <Spin />
        </div>
      ) : (
        [
          myTemplate.info && myTemplate.info.length > 0 ? (
            <Row
              className="my-templates"
              gutter={[
                { xs: 8, sm: 16, md: 24 },
                { xs: 8, sm: 16, md: 24 },
              ]}>
              {myTemplate.info
                .sort((a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt))
                .map((temp) => (
                  <MyTemplateItem
                    tempId={temp.id}
                    temp={temp}
                    key={temp.id}
                    buttonName="View"
                    modifiedAt={temp.modifiedAt}
                    onClick={() => onClickBaseTemplateItem(temp)}
                  />
                ))}
            </Row>
          ) : (
            <div className="empty">
              <Empty description={<span>No templates found.</span>} />
            </div>
          ),
        ]
      )}
    </div>
  );
};

MyTemplate.propTypes = {
  breadCrumbItems: PropType.arrayOf(PropType.object).isRequired,
};

export default MyTemplate;
