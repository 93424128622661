import { BusinessInfosService } from 'services';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { pickWithType, toast } from 'utilities';
import {
  GET_BUSINESS_INFOS,
  GET_BUSINESS_INFOS_FAIL,
  GET_BUSINESS_INFOS_SUCCESS,
  UPDATE_BUSINESS_INFOS_RULES_SUCCESS,
  UPDATE_BUSINESS_INFOS_RULES_FAIL,
  UPDATE_ALL_BUSINESS_INFOS_RULES,
  UPDATE_ALL_BUSINESS_INFOS_RULES_SUCCESS,
  UPDATE_ALL_BUSINESS_INFOS_RULES_FAIL,
  UPDATE_BUSINESS_INFOS_RULES,
  UPDATE_TILE_BUSINESS_INFOS_RULES,
  UPDATE_TILE_BUSINESS_INFOS_RULES_SUCCESS,
  UPDATE_TILE_BUSINESS_INFOS_RULES_FAIL,
  GET_ALL_REGIONS_REQUEST,
  GET_ALL_REGIONS_SUCCESS,
  GET_ALL_REGIONS_ERROR,
} from './action';

export const getAllRegions = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: GET_ALL_REGIONS_REQUEST });
    const result = await BusinessInfosService.getBusinessInfos('region', {
      _page: 1,
      _limit: 9999,
    });
    result.data = result.data.map((region) => ({
      id: region.id,
      name: region.name,
    }));
    dispatch({ type: GET_ALL_REGIONS_SUCCESS, payload: result });
    dispatch(hideLoading());
  } catch (error) {
    dispatch({ type: GET_ALL_REGIONS_ERROR, error });
    dispatch(hideLoading());
  }
};

export const getBusinessInfos = (type, metadata) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_BUSINESS_INFOS, payload: true });
    const params = { ...metadata };
    const getType = type.toLowerCase();
    const result = await BusinessInfosService.getBusinessInfos(getType, params);
    const getWithType = pickWithType(getType);
    result.data = result.data.map((item) => {
      const accessTiles = item[`${getWithType}TileAccess`].map(
        (tileBusiness) => {
          return {
            id: tileBusiness.tileId,
            name: tileBusiness.tile.title,
            hasAccess: tileBusiness.isAccess,
            allCompanyChecked: tileBusiness.allCompanyChecked,
            isDisabled:
              tileBusiness.isEnabled !== undefined &&
              tileBusiness.isEnabled === false,
          };
        },
      );
      const objectMappingRule = {
        id: item.id,
        name: item.name,
        accessTiles,
      };
      return objectMappingRule;
    });

    dispatch(hideLoading());
    dispatch({ type: GET_BUSINESS_INFOS_SUCCESS, payload: result });
  } catch (err) {
    dispatch({ type: GET_BUSINESS_INFOS_FAIL, err });
    dispatch(hideLoading());
  }
};

export const updateBusinessInfo =
  (type, businessInfo, hasAccess) => async (dispatch) => {
    try {
      const newBusinessInfo = { ...businessInfo };
      // Update all tile by row
      if (hasAccess !== undefined) {
        newBusinessInfo.accessTiles.forEach((e) => {
          e.hasAccess = hasAccess;
        });
        newBusinessInfo.checked = hasAccess;
      }
      const getType = type.toLowerCase();

      const updated = newBusinessInfo.accessTiles.map((item) => {
        const newMap = {
          tileId: item.id,
          isAccess: item.hasAccess,
        };
        newMap[`${pickWithType(getType)}Id`] = newBusinessInfo.id;
        return newMap;
      });

      dispatch({ type: UPDATE_BUSINESS_INFOS_RULES, payload: newBusinessInfo });
      dispatch(showLoading());

      const result = await BusinessInfosService.updateBusinessInfos(
        getType,
        updated,
      );

      dispatch(hideLoading());
      toast('success', type, 'Updated Successfully');
      dispatch({
        type: UPDATE_BUSINESS_INFOS_RULES_SUCCESS,
        payload: result.data,
      });
    } catch (err) {
      dispatch({ type: UPDATE_BUSINESS_INFOS_RULES_FAIL, err });
      dispatch(hideLoading());
    }
  };

export const updateAllBusinessInfos = (type, hasAccess) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ALL_BUSINESS_INFOS_RULES, payload: hasAccess });
    dispatch(showLoading());

    const updated = {
      isAccess: hasAccess,
    };

    await BusinessInfosService.updateAllBusinessInfos(type, updated);
    dispatch(hideLoading());
    toast('success', `All ${type}`, 'Updated Successfully');
    dispatch({
      type: UPDATE_ALL_BUSINESS_INFOS_RULES_SUCCESS,
      payload: hasAccess,
    });
  } catch (err) {
    dispatch({ type: UPDATE_ALL_BUSINESS_INFOS_RULES_FAIL, err });
    dispatch(hideLoading());
  }
};

export const updateTileBusinessInfos =
  (type, tileId, hasAccess) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_TILE_BUSINESS_INFOS_RULES,
        payload: { hasAccess, tileId },
      });
      dispatch(showLoading());

      const updated = {
        isAccess: hasAccess,
        tileId,
      };

      await BusinessInfosService.updateTileBusinessInfos(type, updated);
      dispatch(hideLoading());
      toast('success', `All ${type}`, 'Updated Successfully');
      dispatch({
        type: UPDATE_TILE_BUSINESS_INFOS_RULES_SUCCESS,
        payload: hasAccess,
      });
    } catch (err) {
      dispatch({ type: UPDATE_TILE_BUSINESS_INFOS_RULES_FAIL, err });
      dispatch(hideLoading());
    }
  };
