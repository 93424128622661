import { Layout, Row, Spin, Col } from 'antd';
import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectGlobalUserInfo } from 'redux/global/auth/selector';
import { selectIsLoading, selectTiles } from 'redux/home/selector';
import { getAuthentication } from 'redux/home/operation';
import BaseOverlayItem from 'components/UI/BaseOverlayItem';
import {
  navigationRoutes,
  headerTitle,
  STATUS,
  BROWSER_TITLE,
} from 'utilities';
import useHeader from 'hooks/useHeader';
import Breadcrumb from 'components/UI/Breadcrumb';
import useTitle from 'hooks/useTitle';
import { getTileUrl } from '../util/commons';

const ContentComponent = () => {
  useHeader(headerTitle.mapo);
  useTitle(BROWSER_TITLE.MAPO);
  const { Content } = Layout;
  const globalUserInfo = useSelector((state) => selectGlobalUserInfo(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  let tiles = useSelector((state) => selectTiles(state));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAuthentication());
  }, [dispatch]);

  tiles = tiles?.filter(
    (t) => t.role.isViewable === true && t.tile.status === STATUS.ACTIVE,
  );

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Home,
      },
    ],
    [],
  );

  return (
    <Content className="home-content">
      <Breadcrumb items={breadCrumbItems} />
      <div className="home-content_welcome d-flex align-center">
        <h1 className="middle-content bold-text welcome-user-name">
          {globalUserInfo?.fullname}
        </h1>
      </div>
      {isLoading ? (
        <div className="loading">
          <Spin />
        </div>
      ) : (
        <div className="middle-content">
          <Row className="home-content_pages mt-12" gutter={[24, 24]}>
            <Col
              xs={24}
              md={8}
              sm={24}
              key="home-content_welcome-content"
              className="welcome-text d-flex flex-column">
              <h2 className="welcome-text_title bold-text">Welcome!</h2>
              <h3 className="welcome-text_description">
                Welcome to the Kärcher Marketing Portal. From here, you can
                access various pieces of marketing information and applications.
                This portal will be expanded gradually.
              </h3>
            </Col>
            {tiles &&
              tiles?.length > 0 &&
              tiles
                .filter((item) => item.tile)
                .sort((a, b) => a.tile.tileOrder - b.tile.tileOrder)
                .map(({ tile }) => {
                  const { path, isExternalLink } = getTileUrl(tile);
                  return (
                    <BaseOverlayItem
                      xs={24}
                      md={8}
                      sm={24}
                      key={tile.id}
                      hoverText={tile.title}
                      path={path}
                      isExternalLink={isExternalLink}
                      image={tile.image}>
                      <div className="home-content_item-content d-flex flex-column">
                        <Link
                          className="title"
                          target={isExternalLink ? '_blank' : '_self'}
                          rel={isExternalLink ? 'noreferrer' : ''}
                          to={{ pathname: path }}>
                          <h2 className="bold-text font-size-medium">
                            {tile.title}
                          </h2>
                        </Link>
                        <h3 className="description overflow-hidden">
                          {tile.body}
                        </h3>
                      </div>
                    </BaseOverlayItem>
                  );
                })}
          </Row>
        </div>
      )}
    </Content>
  );
};

export default ContentComponent;
