import { useMemo, useState } from 'react';
import { Layout } from 'antd';
import Breadcrumb from 'components/UI/Breadcrumb';
import BaseTabs from 'components/UI/BaseTabs';
import { Web2PublishCategories } from '../Categories/index';
import { Web2PublishTemplatesByTag } from '../Tags/index';
import { Web2PublishLatestTemplatesByTag } from '../LatestTemplate';
import { TABS, SUB_HEADLINES, W2P_TABS } from './util/constant';

export const Web2PublishMain = () => {
  const { Content } = Layout;
  const [breadcrumbRoute, setBreadcrumbRoute] = useState('Categories');
  const [subHeadline, setSubHeadline] = useState(
    SUB_HEADLINES[W2P_TABS.CATEGORIES],
  );
  const [isResetKey, setIsResetKey] = useState(false);

  const onTabClick = (key) => {
    const tabActive = TABS[key];
    setSubHeadline(SUB_HEADLINES[key]);
    setBreadcrumbRoute(tabActive.breadcrumb);
    setIsResetKey(false);
  };

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        onClick: () => {
          onTabClick(W2P_TABS.CATEGORIES);
          setIsResetKey(true);
        },
      },
      {
        title: breadcrumbRoute,
      },
    ],
    [breadcrumbRoute],
  );

  return (
    <Content className="web2Publish-main-container">
      <div className="web2Publish-main-container_banner">
        <Breadcrumb items={breadCrumbItems} />
        <div className="middle-content web2Publish-main-content">
          <h1 className="web2Publish-main-content--title bold-text font-size-big">
            {breadcrumbRoute}
          </h1>
          <h3 className="web2Publish-main-content--text bold-text">
            {subHeadline}
          </h3>
        </div>
      </div>
      <div className="web2Publish-main-tabs">
        <BaseTabs
          onTabClick={onTabClick}
          isResetKey={isResetKey}
          initialPanes={[
            {
              title: 'CATEGORIES',
              content: <Web2PublishCategories />,
              key: W2P_TABS.CATEGORIES,
            },
            {
              title: 'LATEST',
              content: <Web2PublishLatestTemplatesByTag />,
              key: W2P_TABS.LATEST,
            },
            {
              title: 'TAGS',
              content: <Web2PublishTemplatesByTag />,
              key: W2P_TABS.TAGS,
            },
          ]}
        />
      </div>
    </Content>
  );
};
