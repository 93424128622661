import {
  ERROR,
  INACTIVE_USER_ERRORS,
  UPDATE_TYPE,
  NOT_ACCEPTABLE_ERROR,
} from './constants';
import { navigationRoutes } from './navigation';
import { toast } from './toastService';

const isPrimitive = (obj) => obj !== Object(obj);

export const compareUpdatedObject = (oldObject, newObject, result) => {
  let isUpdated = false;

  const oldObj = oldObject || {};
  const newObj = newObject || {};

  const allowedFields = Object.keys(newObj);

  const clonedResult = { ...result };

  if (oldObj.id || newObj.id) {
    clonedResult.id = oldObj.id || newObj.id;
  }

  for (const fields of allowedFields) {
    const field = fields;
    let oldValue = oldObj[field];
    let newValue = newObj[field];
    if (
      (isPrimitive(oldValue) && isPrimitive(newValue)) ||
      oldValue instanceof Date ||
      newValue instanceof Date
    ) {
      oldValue = oldValue === undefined ? null : oldValue;
      newValue = newValue === undefined ? null : newValue;
      if (oldValue && oldValue instanceof Date) {
        oldValue = oldValue.toISOString();
      }
      if (newValue && newValue instanceof Date) {
        newValue = newValue.toISOString();
      }
      if (typeof oldValue !== typeof newValue) {
        oldValue = oldValue && oldValue.toString();
        newValue = newValue && newValue.toString();
      }
      if (oldValue !== newValue) {
        isUpdated = true;
      }
    } else {
      let updatedResult = {};
      let isArrayPropertiesUpdated = true;
      if (Array.isArray(oldValue) || Array.isArray(newValue)) {
        [updatedResult, isArrayPropertiesUpdated] = compareArrayObject(
          oldValue,
          newValue,
        );
        isUpdated = isArrayPropertiesUpdated;
      } else {
        updatedResult = compareUpdatedObject(
          oldObj[field],
          newObj[field],
          clonedResult[field],
        );
        if (updatedResult.type !== UPDATE_TYPE.UNCHANGED) isUpdated = true;
      }
      if (Object.keys(updatedResult).length > 0) {
        clonedResult[field] = updatedResult;
      }
    }
  }

  if (isUpdated) clonedResult.type = UPDATE_TYPE.UPDATED;
  else clonedResult.type = UPDATE_TYPE.UNCHANGED;

  return clonedResult;
};

export const compareArrayObject = (oldObjects = [], newObjects = []) => {
  const result = {};
  let isUpdated = false;
  const oldIds = oldObjects.map((obj) => obj.id);
  const newIds = newObjects.map((obj) => obj.id);

  const createdObjects = newObjects.filter((obj) => !obj.id);
  if (createdObjects.length) {
    isUpdated = true;
    result.create = createdObjects;
  }

  const deletedIds = oldIds.filter((id) => !newIds.includes(id));
  if (deletedIds.length) {
    isUpdated = true;
    result.delete = deletedIds;
  }

  const updatedIds = oldIds.filter((id) => !deletedIds.includes(id));
  const updatedResult = [];
  updatedIds.forEach((id) => {
    const oldObj = oldObjects.find((obj) => obj.id === id);
    const newObj = newObjects.find((obj) => obj.id === id);
    const updatedObjectResult = compareUpdatedObject(oldObj, newObj);
    if (updatedObjectResult.type !== UPDATE_TYPE.UNCHANGED) {
      const updatedObject = newObjects.find((obj) => obj.id === id);
      updatedResult.push(updatedObject);
    }
  });
  if (updatedResult.length) {
    isUpdated = true;
    result.update = updatedResult;
  }

  return [result, isUpdated];
};

export const errorHandle = (error) => {
  if (error?.response?.status === ERROR.PERMISSION_DENIED) {
    window.location.href = `${navigationRoutes.Error_403}`;
  } else if (error?.response?.status === ERROR.NOT_ACCEPTABLE_USERNAME) {
    switch (error?.response?.data?.data) {
      case NOT_ACCEPTABLE_ERROR.MISSING_EMAIL:
        window.location.href = `${navigationRoutes.LoginDealerEmail}`;
        break;
      case NOT_ACCEPTABLE_ERROR.MISSING_NAME:
        window.location.href = `${navigationRoutes.LoginDealerName}`;
        break;
      case NOT_ACCEPTABLE_ERROR.MISSING_NAME_EMAIL:
        window.location.href = `${navigationRoutes.LoginDealerNameAndEmail}`;
        break;
      default:
        break;
    }
  } else {
    let message = 'Something went wrong!';
    const errorData =
      error?.response?.data?.data || error?.response?.data || error?.message;

    // if there is a specific error, please update the error message above

    if (
      Object.prototype.hasOwnProperty.call(errorData, 'message') &&
      typeof errorData.message === 'string'
    ) {
      message = errorData.message;
    }

    toast('error', '', message);
  }
};

export const authErrorHandle = (error) => {
  let message = 'Something went wrong!';
  const errorData =
    error?.response?.data?.data ||
    error?.response?.data ||
    error?.message ||
    error;
  if (INACTIVE_USER_ERRORS.includes(errorData)) {
    message =
      'Your account has been deactivated. Please contact your site administrator!';

    toast('error', '', message);
  }
};

export const hubListener = ({ payload: { event, data } }) => {
  switch (event) {
    case 'signIn_failure':
      authErrorHandle(data);
      break;
    default:
      break;
  }
};

export const isExternalUrl = (url) => {
  if (!url) return false;
  const internalRoutes = Object.values(navigationRoutes);

  return !internalRoutes.find((route) => url.includes(route));
};

export const isUrlHasProtocol = (url) => {
  const pattern = /^(ftp|http|https):\/\//i;
  return pattern.test(url);
};

export const formatValidUrl = (url) => {
  if (!url) return '';
  if (!isUrlHasProtocol(url)) {
    const { protocol } = window.location;
    url = `${protocol}//${url}`;
  }
  return url;
};
