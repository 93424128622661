import { Layout, Spin, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { selectIsLoading, selectUserProfile } from 'redux/userProfile/selector';
import { getUserProfile } from 'redux/userProfile/operation';
import Breadcrumb from 'components/UI/Breadcrumb';
import { headerTitle, navigationRoutes, ROUTE } from 'utilities';
import { useLocation } from 'react-router-dom';
import { UPDATE_HEADER_TITLE } from 'redux/global/header/action';
import { UserInformation } from './UserInformation';

export const MyProfile = () => {
  const { Content } = Layout;
  const { isLoading, userProfile } = useSelector((state) => ({
    isLoading: selectIsLoading(state),
    userProfile: selectUserProfile(state),
  }));
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isInApp = useMemo(() => pathname.indexOf(ROUTE.W2P) !== -1, [pathname]);

  useEffect(() => {
    if (!isInApp) {
      dispatch({
        type: UPDATE_HEADER_TITLE,
        payload: { title: headerTitle.mapo },
      });
    }
  }, [dispatch, isInApp]);

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: isInApp
          ? navigationRoutes.Web2PublishMain
          : navigationRoutes.Home,
      },
      {
        title: 'My Profile',
      },
    ],
    [isInApp],
  );

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const userProfiles = useMemo(
    () => [
      {
        title: 'INFORMATION ID',
        value: userProfile.id,
      },
      {
        title: 'USER NAME',
        value: userProfile.fullname,
      },
      {
        title: 'E-MAIL ADDRESS',
        value: userProfile.email,
      },
    ],
    [userProfile],
  );

  return (
    <Content className="my-profile-container">
      <Breadcrumb items={breadCrumbItems} />
      <div className="my-profile_banner">
        <div className="middle-content">
          <h1 className="bold-text uppercase-text font-size-big my-profile--title">
            My Profile
          </h1>
          <Row className="my-profile_wrapper">
            {userProfiles.map((item) => (
              <Col lg={8} sm={24} key={item.title}>
                <section className="my-profile_item d-flex flex-column">
                  <span className="my-profile_item--title">{item.title}</span>
                  <span className="my-profile_item--text">{item.value}</span>
                </section>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <div className="user-information middle-content">
        {isLoading ? (
          <div className="loading">
            <Spin />
          </div>
        ) : (
          <UserInformation user={userProfile} />
        )}
      </div>
    </Content>
  );
};
