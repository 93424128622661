import { Layout } from 'antd';
import Breadcrumb from 'components/UI/Breadcrumb';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { UPDATE_HEADER_TITLE } from 'redux/global/header/action';
import { headerTitle, navigationRoutes, ROUTE } from 'utilities';
import ScrollToTop from '../ScrollToTop';

export const PrivacyPolicy = () => {
  const { Content } = Layout;
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isInW2P = useMemo(() => {
    return pathname.indexOf(ROUTE.W2P) !== -1;
  }, [pathname]);

  useEffect(() => {
    if (!isInW2P) {
      dispatch({
        type: UPDATE_HEADER_TITLE,
        payload: { title: headerTitle.mapo },
      });
    }
  }, [dispatch, isInW2P]);

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: isInW2P
          ? navigationRoutes.Web2PublishMain
          : navigationRoutes.Home,
      },
      {
        title: 'Privacy Information',
      },
    ],
    [isInW2P],
  );

  return (
    <Content className="privacy-policy">
      <Breadcrumb items={breadCrumbItems} />
      <div className="privacy-policy-banner">
        <h1 className="middle-content font-size-big bold-text">
          PRIVACY INFORMATION AND COOKIES
        </h1>
      </div>
      <div
        className={`privacy-policy-content ${
          isInW2P ? 'privacy-policy-content-w2p' : ''
        }`}>
        <div className="middle-content">
          <section>
            <h2 className="bold-text">
              § 1 Name and address of the controller and the data protection
              officer
            </h2>
            <p>Name and contact information of the controller:</p>
            <p>Alfred Kärcher SE &amp; Co. KG</p>
            <p>Alfred-Kärcher-Strasse 28-40</p>
            <p>71364 Winnenden, Germany</p>
            <p>
              Tel.{' '}
              <a className="bold-text" href="tel:+497195140">
                +49 7195 / 14-0
              </a>
            </p>
            <p>Email: info@karcher.com</p>

            <p>
              (hereinafter referred to as &apos;Kärcher&apos;) carries out all
              data processing operations (e.g. collection, processing and
              transfer) in accordance with the statutory regulations. The
              following Privacy Policy gives you an overview of the types of
              data collected on and via our Kärcher Marketing Portal at
              www.marketingportal.kaercher.com (hereinafter referred to as the
              &apos;Platform&apos;), how they are processed, which security
              measures Kärcher takes to protect your data and how to exercise
              your rights.
            </p>

            <p>
              For questions regarding data protection, and regarding your rights
              as a data subject, please contact the Kärcher data protection
              officer at:
            </p>

            <p>Alfred Kärcher SE &amp; Co. KG</p>
            <p>Data protection officer</p>
            <p>Alfred-Kärcher-Strasse 28-40</p>
            <p>71364 Winnenden, Germany</p>
            <p>
              Email:{' '}
              <a
                className="bold-text"
                href="mailto:datenschutzbeauftragter@de.kaercher.com">
                datenschutzbeauftragter@de.kaercher.com
              </a>
            </p>
          </section>

          <section>
            <h2 className="bold-text">
              § 2 General information on data processing
            </h2>
            <ol>
              <li className="bold-text">
                Scope of the processing of personal data
              </li>
              <p>
                Unless otherwise stated, we process your personal data as a user
                of our Platform (hereinafter referred to as the &apos;Data
                Subject&apos;) in order to make available and provide you with a
                functional and user-friendly Platform as well as our content and
                services.
              </p>

              <li className="bold-text">
                Legal basis for the processing of personal data
              </li>
              <p>
                To the extent that we obtain the consent of the Data Subject for
                personal data processing operations, Article 6 (1)a of the
                General Data Protection Regulation (GDPR) serves as the legal
                basis.
              </p>
              <p>
                Article 6 (1)b GDPR serves as the legal basis for processing the
                personal data required to use the Platform. This is also the
                case for the processing operations required for carrying out
                pre-contractual measures.
              </p>
              <p>
                To the extent that the processing of personal data is required
                for compliance with a legal obligation to which our company is
                subject, Article 6 (1)c GDPR serves as the legal basis.
              </p>
              <p>
                If the processing is necessary to protect a legitimate interest
                of our company or a third party, and if the interests,
                fundamental rights and freedoms of the Data Subject do not
                outweigh the former interest, Article 6 (1)f GDPR serves as the
                legal basis for the processing.
              </p>

              <li className="bold-text">Data erasure and storage period</li>
              <p>
                The personal data of the Data Subject will be erased or blocked
                as soon as the purpose of storage no longer applies.
              </p>
              <p>
                Storage can also take place if this has been provided for by the
                European or national legislator in EU regulations, laws or other
                regulations to which the controller is subject. The data will
                also be blocked or erased if a storage period prescribed by the
                standards mentioned expires, unless there is a need for further
                storage of the data for the conclusion or fulfillment of a
                contract.
              </p>
            </ol>
          </section>

          <section>
            <h2 className="bold-text">§ 3 Registration on the Platform</h2>
            <ol>
              <li className="bold-text">
                Description and scope of data processing
              </li>
              <p>
                Registration is necessary in order to be able to use the Kärcher
                Marketing Portal. You can choose a password linked to your email
                address. Together, the two enable you to log in more easily when
                subsequently using the Platform, without having to re-enter your
                data. External users can also register via a social login via
                Google or Microsoft (also see 2. Social login).
              </p>
              <p>
                Kärcher saves the data you have entered in order to set up a
                user account for your company, which is used to record your
                visits on behalf of your company. Kärcher will store the data
                you have entered for further visits as long as you or your
                company maintain the registration.
              </p>
              <p>
                In addition, further voluntary information (e.g. company name
                and contact details) may be provided. The data are entered into
                a web form, transmitted to us and stored.
              </p>

              <li className="bold-text">Social login</li>
              <p>
                For registration and login, you also have the option of
                authenticating yourself with your existing profile on Google or
                Microsoft (hereinafter referred to as &apos;Third-Party
                Providers&apos;), then registering or logging in.
              </p>
              <p>
                For this purpose, you will find the corresponding icons of the
                Third-Party Providers we support on the registration or login
                page.
              </p>
              <p>
                Before a connection is established with a Third-Party Provider,
                you must expressly agree to the process and data transfer
                described below:
              </p>
              <p>
                If you click on the respective icon, a new window will open in
                which you must log in to the Third-Party Provider with your
                login data. After you have successfully logged in, the
                Third-Party Provider will inform you which data (name and email
                address) will be transmitted to us for authentication as part of
                the registration or login process. If you have agreed to this
                data transmission, the fields required by us for registration
                will be filled with the transmitted data. The information we
                require for registration or login is your email address.
              </p>
              <p>
                Only after your express consent has been granted for the use of
                the transmitted and required data will your data be stored by us
                and used for the purposes stated in this Privacy Policy. Apart
                from the authentication process, no other link is made between
                the user accounts created with us.
              </p>
              <p>
                Your IP address will be transmitted to the Third-Party Provider
                in order to be able to carry out the authentication process for
                registration and login. We have no influence on the purpose and
                scope of the data collection and on the further processing of
                the data by the Third-Party Provider. For more information in
                this regard, please read the respective Third-Party
                Provider&apos;s privacy policy, which they provided to you when
                you registered on the respective platform.
              </p>

              <li className="bold-text">Single sign-on service (SSO)</li>
              <p>
                The data collected during registration is also stored in a
                single sign-on service connected to the website. This allows you
                to authenticate yourself in order to use other services offered
                by Kärcher. During that process, personal data are transmitted
                to these services once you have agreed to the connection. Before
                you connect, the data that are passed on to the respective
                service are displayed and only transmitted to the service with
                your confirmation. After transmission, data processing is the
                responsibility of the respective service and is independent of
                this platform. The respective controller will inform you of the
                handling of your data on their site. You can therefore obtain
                information on this from the respective services and their
                privacy policies.
              </p>

              <li className="bold-text">Legal basis for data processing </li>
              <p>
                The legal basis for the processing of employees&apos; personal
                data is their consent (Sec. 26 (1) of the German Data Protection
                Act, BDSG).
              </p>
              <p>
                Since the processing of personal data is otherwise necessary for
                the use of the Platform or for the implementation of
                pre-contractual measures, the legal basis for any other
                processing of the data is Article 6 (1)b GDPR.
              </p>

              <li className="bold-text">Purpose of the data processing</li>
              <p>
                Using the Platform or creating a user account simplifies the use
                of the functions provided. The data may also be processed when
                the functions selected by the user are activated.
              </p>

              <li className="bold-text">Duration of storage</li>
              <p>
                When you create a user account, we generally store your data for
                as long as you maintain the account.
              </p>
              <p>
                Connected services independently manage the data transmitted by
                the SSO service. Erasure of your data on the website is
                synchronized with the SSO service but not with the connected
                services. Please note that erasing your account on the website
                may result in the loss of access to the connected services and
                the data stored by them.
              </p>

              <li className="bold-text">Opportunity to erase data</li>
              <p>
                As a user of the Platform, you can change the data stored there
                at any time. If the data are required to fulfill a contract or
                to carry out pre-contractual measures, the data can only be
                erased prematurely if there are no contractual or legal
                obligations to the contrary.
              </p>
            </ol>
          </section>

          <section>
            <h2 className="bold-text">§ 4 Provision of the Platform</h2>
            <ol>
              <li className="bold-text">
                Description and scope of data processing
              </li>
              <p>
                Each time our platform is accessed, our system automatically
                collects data and information from the accessing computer
                system.
              </p>
              <p>
                The following data are collected in this regard:
                <ul className="unordered-list">
                  <li>date and time of the request;</li>
                  <li>name of the requested file;</li>
                  <li>time of the last login/order;</li>
                  <li>page from which the file was requested;</li>
                  <li>access status (transfer file, file not found, etc.);</li>
                  <li>web browser and operating system used;</li>
                  <li>complete IP address of the requesting computer;</li>
                  <li>volume of data transmitted;</li>
                </ul>
              </p>
              <p>The data are also stored in our system&apos;s log files.</p>

              <li className="bold-text">Legal basis for data processing</li>
              <p>
                The legal basis for the temporary storage of data and log files
                is Article 6 (1)f GDPR.
              </p>

              <li className="bold-text">Purpose of the data processing</li>
              <p>
                The temporary storage of the IP address by the system is
                necessary in order to deliver the Platform to the user&apos;s
                computer. For this purpose, the IP address of the user must
                remain stored for the duration of the session.
              </p>
              <p>
                Storage in log files takes place to ensure the functionality of
                the Platform. In addition, we use the data to optimize the
                Platform and to ensure the security of our information
                technology systems.
              </p>
              <p>
                These purposes also form the basis for our legitimate interest
                in data processing in accordance with Article 6 (1)f GDPR.
              </p>

              <li className="bold-text">Duration of storage</li>
              <p>
                The data will be erased as soon as they are no longer required
                to achieve the purpose for which they were collected.
              </p>
            </ol>
          </section>

          <section>
            <h2 className="bold-text">§ 5 Use of cookies</h2>
            <ol type="a">
              <li className="bold-text">
                Description and scope of data processing
              </li>
              <p>
                Our platform uses cookies. Cookies are text files that are
                stored in the Internet browser, or by the Internet browser on
                the user&apos;s computer system. When a user accesses a
                platform, a cookie can be stored on the user&apos;s operating
                system. This cookie contains a characteristic character string
                that enables the browser to be uniquely identified when the
                platform is called up again.
              </p>
              <p>
                We only use functional or technically necessary cookies which
                enable us to make our Platform as user-friendly as possible.
              </p>

              <li className="bold-text">Legal basis for data processing</li>
              <p>
                The legal basis for the processing of personal data using
                technically necessary cookies is Article 6 (1)f GDPR.
              </p>

              <li className="bold-text">Purpose of the data processing</li>
              <p>
                The purpose of using technically necessary cookies is to
                simplify the use of the Platform for users. Some functions of
                our Platform cannot be offered without the use of cookies. The
                user data collected by technically necessary cookies are not
                used to create user profiles. These purposes also form the basis
                for our legitimate interest in the processing of personal data
                pursuant to Article 6 (1)f GDPR.
              </p>

              <li className="bold-text">Opportunity to object</li>
              <p>
                Cookies are stored on the user&apos;s computer and transmitted
                to our site from there. As a user, you therefore also have full
                control over the use of cookies. You can deactivate or restrict
                the transmission of cookies by changing the settings in your
                Internet browser. Cookies that have already been saved can be
                erased at any time. This can also be done automatically. If
                cookies for our Platform are deactivated, it may no longer be
                possible to use all of the Platform&apos;s functions to their
                full extent.
              </p>
            </ol>
          </section>

          <section>
            <h2 className="bold-text">§ 6 Disclosure to third parties</h2>
            <p>
              We exchange personal data within the Kärcher Group if and to the
              extent that such is necessary for the execution of a contract or
              for the implementation of pre-contractual measures pursuant to
              Article 6 (1)b GDPR or a legitimate interest arises in an
              intra-Group transfer in accordance with Article 6 (1)f GDPR.
            </p>
            <p>
              In addition to the cases explicitly mentioned in this Privacy
              Policy, your personal data will only be passed on to third parties
              without your express prior consent if such is legally permissible
              or required
            </p>
            <p>
              If necessary to investigate illegal or abusive use of the Platform
              or for legal prosecution, personal data will be forwarded to the
              law enforcement authorities or other public authorities and, if
              necessary, to injured third parties. However, this will only
              happen if there are indications of illegal or abusive conduct. We
              are also legally obligated to provide information to certain
              public bodies upon request. These may be criminal prosecution
              authorities, public authorities that prosecute administrative
              offenses subject to fines, and the financial authorities.
            </p>
            <p>
              Any transfer of personal data is justified by the fact that (1)
              the processing is necessary for compliance with a legal obligation
              to transfer data to law enforcement authorities to which we are
              subject in accordance with Article 6 (1)c GDPR in conjunction with
              national legal requirements, or (2) we have a legitimate interest
              in passing the data on to the named third parties if there are
              indications of abusive conduct or to enforce legal claims and your
              rights and interests in the protection of your personal data
              within the meaning of Article 6 (1)f GDPR do not prevail.
            </p>
            <p>
              External companies and external service providers are used to
              enable us to offer the services on this Platform. Under Article 28
              (1) GDPR, any third-party companies and external service providers
              used are carefully selected as processors regularly reviewed and
              contractually obligated to process all personal data exclusively
              in accordance with our instructions.
            </p>
          </section>

          <section>
            <h2 className="bold-text">§ 7 Data transfer to third countries</h2>
            <p>
              Unless otherwise stated, data will only be transferred to offices
              in countries outside of the European Union (so-called third
              countries) if:
            </p>
            <ul>
              <li>
                it is necessary for the execution of your orders (e.g. payment
                orders);
              </li>
              <li>an adequate level of data protection is ensured; or</li>
              <li>you have granted your consent.</li>
            </ul>
          </section>

          <section>
            <h2 className="bold-text">§ 8 Data security</h2>
            <ol>
              <li className="bold-text">
                General technical and organizational measures:
              </li>
              <p>
                Kärcher has taken a variety of security measures to protect
                personal information appropriately and adequately. All data
                stored by Kärcher are protected by physical and technical
                measures as well as procedural measures that restrict access to
                the information to specially authorized persons in accordance
                with this Privacy Policy.
              </p>

              <li className="bold-text">Secure data transmission</li>
              <p>
                In the course of an order being processed, your personal data
                are transferred in encrypted form using the industry standard
                Secure Socket Layer (&apos;SSL&apos;) technology, (SSL
                encryption version
              </p>

              <li className="bold-text">Passwords</li>
              <p>
                You should never pass on your password for accessing our
                customer portal to third parties and you should also change it
                regularly. When you have finished using your user account, you
                should log out and close your browser to avoid someone gaining
                unauthorized access.
              </p>
            </ol>
          </section>

          <section>
            <h2 className="bold-text">§ 9 Rights of the Data Subject</h2>
            <ol className="mb-0">
              <li className="bold-text">Right to information</li>
              <p>
                Pursuant to Article 15 GDPR, you can request information on your
                personal data that we process.
              </p>

              <li className="bold-text">Right to object:</li>
              <p>
                You have the right to object for good cause (see Section 8).
              </p>

              <li className="bold-text">Right to rectification:</li>
              <p>
                If information concerning you is not (or is no longer) correct,
                you can request rectification pursuant to Article 16 GDPR. If
                your data are incomplete, you can request to have the data
                completed.
              </p>

              <li className="bold-text">Right to erasure:</li>
              <p>
                You can request the erasure of your personal data pursuant to
                Article 17 GDPR.
              </p>

              <li className="bold-text">Right to restriction of processing</li>
              <p>
                According to Article 18 GDPR, you have the right to request a
                restriction of processing of your personal data.
              </p>

              <li className="bold-text">Right to lodge a complaint:</li>
              <p>
                If you believe that the processing of your personal data
                violates data protection law, you have the right under Article
                77 (1) GDPR to lodge a complaint with a data protection
                supervisory authority of your choice, or with the data
                protection supervisory authority that is responsible for the
                controller.
              </p>

              <li className="bold-text">Right to data portability</li>
              <p>
                In the event that the requirements of Article 20 (1) GDPR are
                met, you have the right to have data that we process
                automatically on the basis of your consent or in performance of
                a contract handed over to you or to third parties.
              </p>

              <li className="bold-text">
                Right to object pursuant to Article 21 (1) GDPR:
              </li>
              <p className="mb-0">
                You have the right, for reasons arising from your particular
                situation, to object to the processing of your personal data at
                any time pursuant to Article 6 (1)f GDPR. The controller will
                then no longer process the personal data unless the controller
                demonstrates compelling legitimate grounds for the processing
                which override the interests, rights and freedoms of the Data
                Subject, or if the processing takes place for the establishment,
                exercise or defense of legal claims.
              </p>
            </ol>
          </section>
        </div>
      </div>
      <ScrollToTop />
    </Content>
  );
};
