export const selectReducer = (state) => state.latestTemplatesByTag;

export const selectTemplates = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('templatesTag').templates
    : [];

export const selectTags = (state) =>
  selectReducer(state) ? selectReducer(state).get('templatesTag').tags : [];

export const selectIsLoading = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('templatesTag').isLoading
    : false;
