import immutable from 'immutable';
import { CREATE_STATUS, UPDATE_STATUS } from 'utilities';
import {
  GET_START_PAGE_REQUEST,
  GET_START_PAGE_SUCCESS,
  GET_START_PAGE_FAIL,
  UPDATE_START_PAGE_REQUEST,
  UPDATE_START_PAGE_SUCCESS,
  UPDATE_START_PAGE_FAIL,
  UPDATE_PREVIEW_START_PAGE,
  RESET_START_PAGE_DATA,
  DELETE_SUB_PAGE_REQUEST,
  DELETE_SUB_PAGE_SUCCESS,
  DELETE_SUB_PAGE_FAIL,
  GET_TEXT_SUB_PAGE_REQUEST,
  GET_TEXT_SUB_PAGE_SUCCESS,
  GET_TEXT_SUB_PAGE_FAIL,
  CREATE_TEXT_SUB_PAGE_REQUEST,
  CREATE_TEXT_SUB_PAGE_SUCCESS,
  CREATE_TEXT_SUB_PAGE_FAIL,
  UPDATE_TEXT_SUB_PAGE_REQUEST,
  UPDATE_TEXT_SUB_PAGE_SUCCESS,
  UPDATE_TEXT_SUB_PAGE_FAIL,
  UPDATE_PREVIEW_TEXT_SUB_PAGE,
  RESET_TEXT_SUB_PAGE_DATA,
  GET_MEDIA_SUB_PAGE_REQUEST,
  GET_MEDIA_SUB_PAGE_SUCCESS,
  GET_MEDIA_SUB_PAGE_FAIL,
  CREATE_MEDIA_SUB_PAGE_REQUEST,
  CREATE_MEDIA_SUB_PAGE_SUCCESS,
  CREATE_MEDIA_SUB_PAGE_FAIL,
  UPDATE_MEDIA_SUB_PAGE_REQUEST,
  UPDATE_MEDIA_SUB_PAGE_SUCCESS,
  UPDATE_MEDIA_SUB_PAGE_FAIL,
  UPDATE_PREVIEW_MEDIA_SUB_PAGE,
  RESET_MEDIA_SUB_PAGE_DATA,
} from './action';

const init = () => {
  const initValue = immutable.fromJS({
    startPage: undefined,
    textSubPage: undefined,
    mediaSubPage: undefined,
    isLoading: undefined,
  });

  return initValue
    .set('startPage', {
      data: {},
      update: {
        status: UPDATE_STATUS.DEFAULT,
        isLoading: false,
      },
      deleteSubPage: {
        isLoading: false,
      },
      preview: {},
    })
    .set('textSubPage', {
      data: null,
      create: {
        status: CREATE_STATUS.DEFAULT,
        isLoading: false,
      },
      update: {
        status: UPDATE_STATUS.DEFAULT,
        isLoading: false,
      },
      preview: {},
    })
    .set('mediaSubPage', {
      data: null,
      create: {
        status: CREATE_STATUS.DEFAULT,
        isLoading: false,
      },
      update: {
        status: UPDATE_STATUS.DEFAULT,
        isLoading: false,
      },
      preview: {},
    })
    .set('isLoading', false);
};

export default function microSiteReducer(state = init(), action) {
  switch (action.type) {
    case GET_START_PAGE_REQUEST:
      return state.set('isLoading', true);
    case GET_START_PAGE_SUCCESS: {
      const { payload } = action;
      return state
        .update('startPage', (startPage) => ({
          ...startPage,
          data: payload,
        }))
        .set('isLoading', false);
    }
    case GET_START_PAGE_FAIL:
      return state.set('isLoading', false);
    case UPDATE_START_PAGE_REQUEST:
      return state.update('startPage', (startPage) => ({
        ...startPage,
        update: {
          status: UPDATE_STATUS.DEFAULT,
          isLoading: true,
        },
      }));
    case UPDATE_START_PAGE_SUCCESS:
      return state.update('startPage', (startPage) => ({
        ...startPage,
        update: {
          status: UPDATE_STATUS.SUCCESS,
          isLoading: false,
        },
      }));
    case UPDATE_START_PAGE_FAIL:
      return state.update('startPage', (startPage) => ({
        ...startPage,
        update: {
          status: UPDATE_STATUS.FAIL,
          isLoading: false,
        },
      }));
    case UPDATE_PREVIEW_START_PAGE: {
      const { payload } = action;
      return state.update('startPage', (startPage) => ({
        ...startPage,
        preview: {
          ...startPage.preview,
          ...payload,
        },
      }));
    }
    case RESET_START_PAGE_DATA:
      return state.set('startPage', {
        data: {},
        update: {
          status: UPDATE_STATUS.DEFAULT,
          isLoading: false,
        },
        preview: {},
      });
    case GET_TEXT_SUB_PAGE_REQUEST:
      return state.set('isLoading', true);
    case GET_TEXT_SUB_PAGE_SUCCESS: {
      const { payload } = action;
      return state
        .update('textSubPage', (textSubPage) => ({
          ...textSubPage,
          data: payload,
        }))
        .set('isLoading', false);
    }
    case GET_TEXT_SUB_PAGE_FAIL:
      return state.set('isLoading', false);
    case CREATE_TEXT_SUB_PAGE_REQUEST:
      return state.update('textSubPage', (textSubPage) => ({
        ...textSubPage,
        create: {
          status: CREATE_STATUS.DEFAULT,
          isLoading: true,
        },
      }));
    case CREATE_TEXT_SUB_PAGE_SUCCESS:
      return state.update('textSubPage', (textSubPage) => ({
        ...textSubPage,
        create: {
          status: CREATE_STATUS.SUCCESS,
          isLoading: false,
        },
      }));
    case CREATE_TEXT_SUB_PAGE_FAIL:
      return state.update('textSubPage', (textSubPage) => ({
        ...textSubPage,
        create: {
          status: CREATE_STATUS.FAIL,
          isLoading: false,
        },
      }));
    case UPDATE_TEXT_SUB_PAGE_REQUEST:
      return state.update('textSubPage', (textSubPage) => ({
        ...textSubPage,
        update: {
          status: UPDATE_STATUS.DEFAULT,
          isLoading: true,
        },
      }));
    case DELETE_SUB_PAGE_REQUEST:
      return state.update('startPage', (startPage) => ({
        ...startPage,
        deleteSubPage: {
          isLoading: true,
        },
      }));
    case DELETE_SUB_PAGE_SUCCESS: {
      const {
        payload: { id, type },
      } = action;
      const { subPages } = state.get('startPage').data;
      const cloneSubPages = [...subPages];
      const deletedIndex = cloneSubPages.findIndex(
        (subPage) => subPage.id === id && subPage.type === type,
      );
      if (deletedIndex || deletedIndex === 0) {
        cloneSubPages.splice(deletedIndex, 1);
      }
      return state.update('startPage', (startPage) => ({
        ...startPage,
        data: {
          ...startPage.data,
          subPages: cloneSubPages,
        },
        deleteSubPage: {
          isLoading: false,
        },
      }));
    }
    case DELETE_SUB_PAGE_FAIL:
      return state.update('startPage', (startPage) => ({
        ...startPage,
        deleteSubPage: {
          isLoading: false,
        },
      }));
    case UPDATE_TEXT_SUB_PAGE_SUCCESS:
      return state.update('textSubPage', (textSubPage) => ({
        ...textSubPage,
        update: {
          status: UPDATE_STATUS.SUCCESS,
          isLoading: false,
        },
      }));
    case UPDATE_TEXT_SUB_PAGE_FAIL:
      return state.update('textSubPage', (textSubPage) => ({
        ...textSubPage,
        update: {
          status: UPDATE_STATUS.FAIL,
          isLoading: false,
        },
      }));
    case UPDATE_PREVIEW_TEXT_SUB_PAGE: {
      const { payload } = action;
      return state.update('textSubPage', (textSubPage) => ({
        ...textSubPage,
        preview: {
          ...textSubPage.preview,
          ...payload,
        },
      }));
    }
    case RESET_TEXT_SUB_PAGE_DATA:
      return state.set('textSubPage', {
        data: null,
        create: {
          status: CREATE_STATUS.DEFAULT,
          isLoading: false,
        },
        update: {
          status: UPDATE_STATUS.DEFAULT,
          isLoading: false,
        },
        preview: {},
      });
    case GET_MEDIA_SUB_PAGE_REQUEST:
      return state.set('isLoading', true);
    case GET_MEDIA_SUB_PAGE_SUCCESS: {
      const { payload } = action;
      return state
        .update('mediaSubPage', (mediaSubPage) => ({
          ...mediaSubPage,
          data: payload,
        }))
        .set('isLoading', false);
    }
    case GET_MEDIA_SUB_PAGE_FAIL:
      return state.set('isLoading', false);
    case CREATE_MEDIA_SUB_PAGE_REQUEST:
      return state.update('mediaSubPage', (mediaSubPage) => ({
        ...mediaSubPage,
        create: {
          status: CREATE_STATUS.DEFAULT,
          isLoading: true,
        },
      }));
    case CREATE_MEDIA_SUB_PAGE_SUCCESS:
      return state.update('mediaSubPage', (mediaSubPage) => ({
        ...mediaSubPage,
        create: {
          status: CREATE_STATUS.SUCCESS,
          isLoading: false,
        },
      }));
    case CREATE_MEDIA_SUB_PAGE_FAIL:
      return state.update('mediaSubPage', (mediaSubPage) => ({
        ...mediaSubPage,
        create: {
          status: CREATE_STATUS.FAIL,
          isLoading: false,
        },
      }));
    case UPDATE_MEDIA_SUB_PAGE_REQUEST:
      return state.update('mediaSubPage', (mediaSubPage) => ({
        ...mediaSubPage,
        update: {
          status: UPDATE_STATUS.DEFAULT,
          isLoading: true,
        },
      }));
    case UPDATE_MEDIA_SUB_PAGE_SUCCESS:
      return state.update('mediaSubPage', (mediaSubPage) => ({
        ...mediaSubPage,
        update: {
          status: UPDATE_STATUS.SUCCESS,
          isLoading: false,
        },
      }));
    case UPDATE_MEDIA_SUB_PAGE_FAIL:
      return state.update('mediaSubPage', (mediaSubPage) => ({
        ...mediaSubPage,
        update: {
          status: UPDATE_STATUS.FAIL,
          isLoading: false,
        },
      }));
    case UPDATE_PREVIEW_MEDIA_SUB_PAGE: {
      const { payload } = action;
      return state.update('mediaSubPage', (mediaSubPage) => ({
        ...mediaSubPage,
        preview: {
          ...mediaSubPage.preview,
          ...payload,
        },
      }));
    }
    case RESET_MEDIA_SUB_PAGE_DATA:
      return state.set('mediaSubPage', {
        data: null,
        create: {
          status: CREATE_STATUS.DEFAULT,
          isLoading: false,
        },
        update: {
          status: UPDATE_STATUS.DEFAULT,
          isLoading: false,
        },
        preview: {},
      });
    default:
      return state;
  }
}
