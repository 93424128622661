import { Layout } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { headerTitle, BROWSER_TITLE } from 'utilities';
import SideBar from 'components/UI/Sider';
import useHeader from 'hooks/useHeader';
import useTitle from 'hooks/useTitle';
import routes from './mapoNavigations';

const MapoRoutes = () => {
  useHeader(headerTitle.mapo);
  useTitle(BROWSER_TITLE.MAPO);
  return (
    <Layout className="mapo-administration">
      <SideBar />
      <Switch>
        {routes.map((route) => {
          return (
            <Route
              path={route.path}
              key={route.path}
              component={route.component}
              {...route}
            />
          );
        })}
      </Switch>
    </Layout>
  );
};

export default MapoRoutes;
