import { Layout } from 'antd';
import Breadcrumb from 'components/UI/Breadcrumb';
import ScrollToTop from 'components/ScrollToTop';
import { useEffect, useMemo } from 'react';
import { navigationRoutes } from 'utilities';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getTextSubPage } from 'redux/microSite/operation';
import { selectTextSubPage } from 'redux/microSite/selector';

export const MicroSiteTextSubPage = () => {
  const { Content } = Layout;
  const dispatch = useDispatch();
  const { tileId, subPageId } = useParams();

  const { textSubPage } = useSelector((state) => ({
    textSubPage: selectTextSubPage(state),
  }));

  useEffect(() => {
    dispatch(getTextSubPage(subPageId));
  }, [dispatch, tileId, subPageId]);

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Home,
      },
      {
        title: textSubPage ? textSubPage.tile.title : '',
        path: `${navigationRoutes.MicroSite}/${tileId}`,
      },
      {
        title: textSubPage ? textSubPage.title : '',
      },
    ],
    [tileId, textSubPage],
  );

  return (
    <Content className="micro-site-text-sub-page">
      {textSubPage && (
        <>
          <Breadcrumb items={breadCrumbItems} />
          <div className="text-sub-page_banner">
            <div className="middle-content">
              <h2 className="text-sub-page-headline font-size-big bold-text">
                {textSubPage.title}
              </h2>
              <p className="text-sub-page-headline-text bold-text">
                {textSubPage.textHeadline}
              </p>
            </div>
          </div>
          {textSubPage.mediaHeadline && (
            <div className="text-sub-page_image">
              <img src={textSubPage.mediaHeadline} alt="" />
            </div>
          )}
          <div className="text-sub-page_sub-headline">
            <div className="middle-content">
              {textSubPage.categories.length > 0 &&
                textSubPage.categories.map((item) => (
                  <div className="text-sub-page-categories" key={item.id}>
                    <h2 className="text-sub-page-sub-headline font-size-medium bold-text">
                      {item.title}
                    </h2>
                    <p className="text-sub-page-sub-headline-text">
                      {item.text}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
      <ScrollToTop />
    </Content>
  );
};
