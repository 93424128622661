import { Layout, Spin, Row, Empty, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumb from 'components/UI/Breadcrumb';
import {
  selectTags,
  selectTemplates,
  selectIsLoading,
} from 'redux/w2p/templates-by-cat-and-tag/selector';
import {
  getTemplatesByCat,
  getTemplatesByCatAndTag,
} from 'redux/w2p/templates-by-cat-and-tag/operation';
import { navigationRoutes } from 'utilities';
import BaseGroupOptions from 'components/UI/BaseGroupOptions';
import { ChevronRight, imagePlaceHolder } from 'assets/images';

export const Web2PublishTemplatesByTagAndCat = () => {
  const { Content } = Layout;

  const templates = useSelector((state) => selectTemplates(state));
  const tags = useSelector((state) => selectTags(state));
  const isLoading = useSelector((state) => selectIsLoading(state));

  const dispatch = useDispatch();
  const history = useHistory();
  const { templateId, title } = useParams();

  useEffect(() => {
    dispatch(getTemplatesByCat(templateId));
  }, [dispatch, templateId]);

  const onTagChange = (newTags) => {
    dispatch(
      getTemplatesByCatAndTag(
        templateId,
        newTags.map((tag) => tag.id),
      ),
    );
  };

  const onClickBaseTemplateItem = (templateItem) => {
    breadCrumbItems[breadCrumbItems.length - 1] = {
      title,
    };
    history.push(
      `${navigationRoutes.SingleAsset}/${templateItem.id}`,
      breadCrumbItems,
    );
  };

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Web2PublishMain,
      },
      {
        title: 'Categories',
        path: navigationRoutes.Web2PublishMain,
      },
      {
        title,
      },
    ],
    [title],
  );

  return (
    <Content className="web2Publish-templates-by-cat-container">
      <Breadcrumb items={breadCrumbItems} />

      <div className="middle-content group-options pt-4 pb-10">
        <h1 className="bold-text font-size-big">{title}</h1>

        <BaseGroupOptions
          options={tags ?? []}
          onValueChange={(value) => onTagChange(value)}
        />

        {isLoading ? (
          <div className="loading">
            <Spin />
          </div>
        ) : (
          [
            templates?.length === 0 ? (
              <Empty description={<span>No templates found.</span>} />
            ) : (
              <Row
                className="templates"
                gutter={[
                  { xs: 8, sm: 16, md: 24 },
                  { xs: 8, sm: 16, md: 24 },
                ]}>
                {templates.map((temp) => (
                  <Col
                    key={temp.id}
                    xs={12}
                    md={12}
                    lg={6}
                    onClick={() => onClickBaseTemplateItem(temp)}>
                    <div className="template-image_wrapper">
                      <img
                        className="template-image"
                        src={temp.image ? temp.image : imagePlaceHolder}
                        alt=""
                      />
                      <div className="template-image_overlay">
                        <div className="template-image_overlay-content">
                          <h2 className="template-image_overlay-text bold-text mr-2">
                            View
                          </h2>
                          <ChevronRight />
                        </div>
                      </div>
                    </div>
                    <h2 className="template-title bold-text">{temp.title}</h2>
                    <h2 className="template-size">{`Size: ${temp.assetSize}`}</h2>
                    <h3 className="template-description">{temp.bodyShort}</h3>
                  </Col>
                ))}
              </Row>
            ),
          ]
        )}
      </div>
    </Content>
  );
};
