import { useMemo } from 'react';
import useCheckLocation from 'hooks/useCheckLocation';

const ScrollToTopButton = () => {
  const {
    isInW2PWelcomPage,
    isInAdministration,
    isInMicroSite,
    isInW2P,
    isInSubmitInfoPage,
  } = useCheckLocation();

  const isWhiteBackground = useMemo(
    () => isInAdministration || isInMicroSite || isInW2P,
    [isInAdministration, isInMicroSite, isInW2P],
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return !isInW2PWelcomPage ? (
    <div
      className={`scroll-to-top_wrapper ${
        isWhiteBackground ? 'scroll-to-top_wrapper-white' : ''
      } ${isInSubmitInfoPage ? 'd-none' : ''}`}>
      <button
        className="scroll-to-top_button"
        type="button"
        onClick={scrollToTop}>
        <i className="scroll-to-top_icon" />
      </button>
    </div>
  ) : null;
};

export default ScrollToTopButton;
