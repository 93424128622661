export const selectReducer = (state) => state.mapoSingleContent;

export const selectIsLoading = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('mapoContent').isLoading
    : false;

export const selectTileTypes = (state) =>
  selectReducer(state) ? selectReducer(state).get('mapoContent').tileTypes : [];

export const selectTileContent = (state) =>
  selectReducer(state)
    ? selectReducer(state).get('mapoContent').tileContent
    : undefined;

export const selectCreateTile = (state) =>
  selectReducer(state).get('mapoContent').create || {};

export const selectUpdateTile = (state) =>
  selectReducer(state).get('mapoContent').update || {};

export const selectPreviewTile = (state) =>
  selectReducer(state).get('mapoContent').preview || undefined;
