import { W2pService } from 'services';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
  GET_TEMPLATE_DETAIL_FAIL,
  GET_TEMPLATE_DETAIL_REQUEST,
  GET_TEMPLATE_DETAIL_SUCCESS,
  RESET_TEMPLATE_DETAIL,
} from './action';

export const getSingleAsset = (id) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_TEMPLATE_DETAIL_REQUEST });
    const result = await W2pService.getTemplatesById(id);
    dispatch(hideLoading());
    dispatch({
      type: GET_TEMPLATE_DETAIL_SUCCESS,
      payload: result.data && result.data.length > 0 ? result.data[0] : null,
    });
  } catch (err) {
    dispatch({ type: GET_TEMPLATE_DETAIL_FAIL, err });
    dispatch(hideLoading());
  }
};

export const resetTemplateDetail = () => (dispatch) => {
  dispatch({ type: RESET_TEMPLATE_DETAIL });
};
