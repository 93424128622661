import { W2pService } from 'services';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
  GENERATE_PDF,
  GENERATE_PDF_SUCCESS,
  GENERATE_PDF_FAIL,
  GENERATE_IMAGE,
  GENERATE_IMAGE_SUCCESS,
  GENERATE_IMAGE_FAIL,
  GET_EDITOR,
  GET_EDITOR_FAIL,
  GET_EDITOR_SUCCESS,
} from './action';

export const getEditor = (id, edit) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_EDITOR, payload: true });
    const res = await W2pService.editTemplate(id, edit);
    dispatch(hideLoading());
    dispatch({ type: GET_EDITOR_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: GET_EDITOR_FAIL, err });
    dispatch(hideLoading());
  }
};

export const generatePdf = (id, isPrint) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GENERATE_PDF, payload: true });
    const res = await W2pService.generatePdf(id, isPrint);
    dispatch(hideLoading());
    dispatch({ type: GENERATE_PDF_SUCCESS, payload: res });
  } catch (err) {
    dispatch({ type: GENERATE_PDF_FAIL, err });
    dispatch(hideLoading());
  }
};

export const generateImage = (id) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GENERATE_IMAGE });
    const res = await W2pService.generateImage(id);
    dispatch(hideLoading());
    dispatch({ type: GENERATE_IMAGE_SUCCESS, payload: res });
  } catch (err) {
    dispatch({ type: GENERATE_IMAGE_FAIL, err });
    dispatch(hideLoading());
  }
};
