import { navigationPaths } from 'utilities';
import MicroSiteStartPage from 'screens/MicroSite/start-page';
import MicroSiteTextSubPage from 'screens/MicroSite/text-sub-page';
import MicroSiteMediaSubPage from 'screens/MicroSite/media-sub-page';

export default [
  {
    path: navigationPaths.MicroSiteStartPage,
    component: MicroSiteStartPage,
    exact: true,
  },
  {
    path: navigationPaths.MicroSiteTextSubPage,
    component: MicroSiteTextSubPage,
    exact: true,
  },
  {
    path: navigationPaths.MicroSiteMediaSubPage,
    component: MicroSiteMediaSubPage,
    exact: true,
  },
];
