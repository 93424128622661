import immutable from 'immutable';
import {
  GET_BUSINESS_INFOS,
  GET_BUSINESS_INFOS_FAIL,
  GET_BUSINESS_INFOS_SUCCESS,
  UPDATE_BUSINESS_INFOS_RULES,
  UPDATE_TILE_BUSINESS_INFOS_RULES,
  UPDATE_TILE_BUSINESS_INFOS_RULES_SUCCESS,
  UPDATE_BUSINESS_INFOS_RULES_SUCCESS,
  UPDATE_TILE_BUSINESS_INFOS_RULES_FAIL,
  UPDATE_BUSINESS_INFOS_RULES_FAIL,
  GET_ALL_REGIONS_REQUEST,
  GET_ALL_REGIONS_SUCCESS,
  GET_ALL_REGIONS_ERROR,
} from './action';

const init = () => {
  const initValue = immutable.fromJS({
    businessInfo: undefined,
    regions: undefined,
  });

  return initValue
    .set('businessInfo', {
      businessInfos: [],
      isLoading: false,
      metadata: {
        _page: 1,
        _limit: 10,
      },
    })
    .set('regions', {
      data: [],
      isLoading: false,
    });
};

export default function mappingRulesReducer(state = init(), action) {
  switch (action.type) {
    case GET_BUSINESS_INFOS:
      return state.update('businessInfo', (businessInfo) => ({
        ...businessInfo,
        isLoading: true,
      }));
    case GET_BUSINESS_INFOS_SUCCESS:
      return state.update('businessInfo', (businessInfo) => ({
        ...businessInfo,
        businessInfos: action.payload.data,
        metadata: action.payload.metadata,
        isLoading: false,
      }));
    case GET_BUSINESS_INFOS_FAIL:
    case UPDATE_TILE_BUSINESS_INFOS_RULES_FAIL:
    case UPDATE_BUSINESS_INFOS_RULES_FAIL:
    case UPDATE_TILE_BUSINESS_INFOS_RULES_SUCCESS:
    case UPDATE_BUSINESS_INFOS_RULES_SUCCESS:
      return state.update('businessInfo', (businessInfo) => ({
        ...businessInfo,
        isLoading: false,
      }));
    case UPDATE_TILE_BUSINESS_INFOS_RULES:
      return state.update('businessInfo', (businessInfo) => {
        const updated = [...businessInfo.businessInfos];
        const { payload } = action;
        updated.forEach((info) => {
          info.accessTiles.forEach((tile) => {
            if (tile.id === payload.tileId) tile.hasAccess = payload.hasAccess;
          });
        });
        return {
          ...businessInfo,
          isLoading: true,
          businessInfos: updated,
        };
      });
    case UPDATE_BUSINESS_INFOS_RULES: {
      const updated = action.payload;
      return state.update('businessInfo', (businessInfo) => {
        const updateBusinessInfos = [...businessInfo.businessInfos];
        const index = updateBusinessInfos.findIndex((e) => {
          return e.id === businessInfo.id;
        });
        updateBusinessInfos[index] = updated;
        return {
          ...businessInfo,
          isLoading: true,
          businessInfos: updateBusinessInfos,
        };
      });
    }

    case GET_ALL_REGIONS_REQUEST:
      return state.update('regions', (regions) => ({
        ...regions,
        isLoading: true,
      }));

    case GET_ALL_REGIONS_SUCCESS:
      return state.set('regions', {
        data: action.payload.data,
        isLoading: false,
      });

    case GET_ALL_REGIONS_ERROR:
      return state.update('regions', (regions) => ({
        ...regions,
        isLoading: false,
      }));
    default:
      return state;
  }
}
