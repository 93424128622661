import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_BROWSER_TITLE } from 'redux/global/browser/action';
import { selectBrowserTitle } from 'redux/global/browser/selector';
import { BROWSER_TITLE } from 'utilities';

/**
 * React Hook to update browser page title.
 *
 * @param {string} title - The prototype of title.
 */

const useTitle = (title) => {
  const dispatch = useDispatch();
  const currentTitle = useSelector((state) => selectBrowserTitle(state));

  useEffect(() => {
    if (title !== currentTitle) {
      dispatch({ type: UPDATE_BROWSER_TITLE, payload: { title } });
    }
  }, [dispatch, title, currentTitle]);

  useEffect(() => {
    document.title = currentTitle;

    // default
    return () => {
      document.title = BROWSER_TITLE.MAPO;
    };
  }, [currentTitle]);
};

export default useTitle;
