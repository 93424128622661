import { Empty, Input, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import { SearchIcon } from 'assets/images';

const { Search } = Input;

export const SearchBox = (props) => {
  const [currentFilter, setCurrentFilter] = useState('ALL');
  const { onSearch, onFilter, onSelect, selectProps } = props;

  const onFiltering = useCallback(
    (item) => {
      setCurrentFilter(item);
      onFilter(item);
    },
    [onFilter],
  );

  return (
    <div className="base-search-box">
      {onSearch && (
        <Search
          placeholder="Search"
          allowClear
          size="large"
          prefix={<SearchIcon />}
          onSearch={onSearch}
        />
      )}
      <div className="filter-area d-flex align-center justify-space-between">
        {onFilter && (
          <div className="filter-alphabet">
            <button
              type="button"
              className={currentFilter === 'ALL' ? 'active' : ''}
              onClick={() => onFiltering('ALL')}>
              ALL
            </button>
            {Array.from(Array(26), (e, i) => {
              return (
                <button
                  key={i}
                  type="button"
                  onClick={() => onFiltering(String.fromCharCode(65 + i))}
                  className={
                    currentFilter === String.fromCharCode(65 + i)
                      ? 'active'
                      : ''
                  }>
                  {String.fromCharCode(65 + i)}
                </button>
              );
            })}
          </div>
        )}
        {onSelect && (
          <Select
            className="base-select"
            placeholder={selectProps.placeHolder}
            notFoundContent={
              selectProps.loading ? <Spin size="small" /> : <Empty />
            }
            onSelect={onSelect}
            allowClear
            onClear={selectProps.onClear}>
            {selectProps.options.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </div>
    </div>
  );
};

SearchBox.defaultProps = {
  onSearch: undefined,
  onFilter: undefined,
  onSelect: undefined,
  selectProps: {},
};

SearchBox.propTypes = {
  onSearch: PropTypes.func,
  onFilter: PropTypes.func,
  onSelect: PropTypes.func,
  selectProps: PropTypes.shape({
    loading: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({})),
    placeHolder: PropTypes.string,
    onClear: PropTypes.func,
  }),
};
