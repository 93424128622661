import { useMemo } from 'react';
import { Row, Col, Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { STATUS } from 'utilities';
import BaseInput from 'components/UI/BaseInput';
import { BaseButton } from 'components/UI/BaseButton';
import { selectUserManagements } from 'redux/administration/user-management/selector';

const ViewSingleUser = ({ onHandleEditUser }) => {
  const { userManagementInfo } = useSelector((state) => ({
    userManagementInfo: selectUserManagements(state),
  }));
  const isAdmin = userManagementInfo.detailUser?.userRole?.id === 2;
  const isViewAllTiles = userManagementInfo.detailUser?.userRole?.id === 3;

  const TileRole = useMemo(() => {
    if (userManagementInfo.detailUser) {
      const { userTileRoles } = userManagementInfo.detailUser;
      const itemTileRoles = [];
      userTileRoles.forEach((item) => {
        const result = Object.keys(userManagementInfo.detailUser).find(
          (key) => key === item.tile.title?.replace(/\s/g, '_'),
        );
        if (result) {
          itemTileRoles.push(
            <Col key={item.tile.title} md={12} lg={12} className="mt-4">
              <div className="d-flex flex-column align-start">
                <span className="bold-text label">{item.tile.title}</span>
                <BaseInput
                  disabled
                  defaultValue={userManagementInfo.detailUser[result].name}
                />
              </div>
            </Col>,
          );
        }
      });

      return () => <>{itemTileRoles}</>;
    }
    return null;
  }, [userManagementInfo.detailUser]);

  return (
    <>
      {userManagementInfo.detailUser && (
        <>
          <div className="single-user-management-general pt-5 pb-5">
            <div className="middle-content">
              <h1>{userManagementInfo.detailUser.fullname} </h1>
              <Row>
                <Col xs={24} md={8} lg={8}>
                  <div className="d-flex flex-column align-start">
                    <span className="bold-text label">LOGIN ID</span>
                    <span>{userManagementInfo.detailUser.id}</span>
                  </div>
                </Col>
                <Col xs={24} md={8} lg={8}>
                  <div className="d-flex flex-column align-start">
                    <span className="bold-text label">USER STATUS</span>
                    {userManagementInfo.detailUser.status === STATUS.ACTIVE && (
                      <span className="status-active width-20">
                        {userManagementInfo.detailUser.status}
                      </span>
                    )}
                    {userManagementInfo.detailUser.status ===
                      STATUS.INACTIVE && (
                      <span className="status-inactive width-20">
                        {userManagementInfo.detailUser.status}
                      </span>
                    )}
                  </div>
                </Col>
                <Col xs={24} md={8} lg={8}>
                  <div className="d-flex flex-column align-start">
                    <span className="bold-text label">E-MAIL ADDRESS</span>
                    <span>{userManagementInfo.detailUser.email}</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="single-user-management-information middle-content pt-5 pb-5">
            <Row>
              <Col xs={24} md={16} lg={16}>
                <h3 className="bold-text">USER INFORMATION </h3>
                <Row>
                  <Col md={12} lg={12} className="mt-4">
                    <div className="d-flex flex-column align-start">
                      <span className="bold-text label">
                        {userManagementInfo.detailUser.fullname}
                      </span>
                      <span>{userManagementInfo.detailUser.email}</span>
                    </div>
                  </Col>
                  <Col md={12} lg={12} className="mt-4" />
                  <Col md={12} lg={12} className="mt-4">
                    <div className="d-flex flex-column align-start">
                      <span className="bold-text label">Region</span>
                      <BaseInput
                        disabled
                        defaultValue={userManagementInfo.detailUser.region.name}
                      />
                    </div>
                  </Col>
                  <Col md={12} lg={12} className="mt-4">
                    <div className="d-flex flex-column align-start">
                      <span className="bold-text label">Company</span>
                      <BaseInput
                        disabled
                        defaultValue={userManagementInfo.detailUser.company.id}
                      />
                    </div>
                  </Col>
                  <Col md={12} lg={12} className="mt-4">
                    <div className="d-flex flex-column align-start">
                      <span className="bold-text label">Job Type</span>
                      <BaseInput
                        disabled
                        defaultValue={
                          userManagementInfo.detailUser.jobType.name
                        }
                      />
                    </div>
                  </Col>
                  <Col md={12} lg={12} className="mt-4">
                    <Checkbox checked={isAdmin} id="admin" disabled>
                      {' '}
                      Admin
                    </Checkbox>{' '}
                    <br></br>
                    <Checkbox
                      checked={isViewAllTiles}
                      id="viewAllTiles"
                      disabled>
                      {' '}
                      View all tiles
                    </Checkbox>
                  </Col>
                  <TileRole />
                </Row>
              </Col>
              <Col xs={24} md={8} lg={8}>
                <h3 className="bold-text">USER STATUS </h3>
                <div>
                  <BaseButton
                    className={
                      userManagementInfo.detailUser.status === STATUS.ACTIVE
                        ? 'base-yellow-btn'
                        : ''
                    }>
                    ACTIVE
                  </BaseButton>
                  <BaseButton
                    className={`ml-3 ${
                      userManagementInfo.detailUser.status === STATUS.INACTIVE
                        ? 'base-yellow-btn'
                        : ''
                    }`}>
                    INACTIVE
                  </BaseButton>
                </div>
                <hr align="left" className="divider-user-status mt-13" />
              </Col>
            </Row>
          </div>
          <div className="single-user-management-button middle-content pt-5 pb-5 d-flex flex-row align-center">
            <BaseButton
              className="base-yellow-btn btn-edit"
              onClick={onHandleEditUser}>
              EDIT USER INFORMATION
            </BaseButton>
          </div>
        </>
      )}
    </>
  );
};

ViewSingleUser.propTypes = {
  onHandleEditUser: PropTypes.func.isRequired,
};

export default ViewSingleUser;
