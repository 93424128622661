import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
  W2pService,
  BusinessInfosService,
  UserTypeService,
  JobTypeService,
} from 'services';
import { toast } from 'utilities';
import { getSelectedRegions } from './util';

import {
  GET_MASTER_DATA,
  GET_MASTER_DATA_FAIL,
  GET_MASTER_DATA_SUCCESS,
  GET_W2P_SINGLE_CONTENT_FAIL,
  GET_W2P_SINGLE_CONTENT_REQUEST,
  GET_W2P_SINGLE_CONTENT_SUCCESS,
  RESET_W2P_SINGLE_CONTENT,
  SAVE_TEMPLATE_FAIL,
  SAVE_TEMPLATE,
  SAVE_TEMPLATE_SUCCESS,
  GET_COMPANY_FAIL,
  GET_COMPANY_SUCCESS,
  RESET_COMPANY,
  ADD_RULE_FAIL,
  ADD_RULE_REQUEST,
  ADD_RULE_SUCCESS,
  DELETE_RULE_FAIL,
  DELETE_RULE_REQUEST,
  DELETE_RULE_SUCCESS,
  UPDATE_REGION,
  UPDATE_SINGLE_COMPANY,
  UPDATE_USER_TYPE,
  SAVE_MAPPING_RULE_SUCCESS,
  SAVE_MAPPING_RULE_FAIL,
  GET_MAPPING_RULES_REQUEST,
  GET_MAPPING_RULES_FAIL,
  GET_MAPPING_RULES_SUCCESS,
  UPDATE_SELECTED_REGION,
  UPDATE_JOB_TYPE,
  UPDATE_ALL_REGION,
} from './action';

export const getW2PSingleContent = (id, title) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_W2P_SINGLE_CONTENT_REQUEST });
    const result = await W2pService.getTemplateView(id, title);
    dispatch(hideLoading());
    dispatch({ type: GET_W2P_SINGLE_CONTENT_SUCCESS, payload: result.data });
  } catch (err) {
    dispatch({ type: GET_W2P_SINGLE_CONTENT_FAIL, err });
    dispatch(hideLoading());
  }
};

export const saveTempate = (data) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({
      type: SAVE_TEMPLATE,
    });
    const sendData = { ...data };
    // Serialize data
    sendData.tags = sendData.tags?.map((e) => e.id);
    sendData.categories = sendData.categories?.map((e) => e.id);
    const { id } = sendData;
    delete sendData.id;
    await W2pService.saveTemplate(id, sendData);
    dispatch(hideLoading());
    dispatch({
      type: SAVE_TEMPLATE_SUCCESS,
    });
    toast('success', 'Template', 'Updated Successfully');
  } catch (err) {
    dispatch({ type: SAVE_TEMPLATE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const getMasterData = () => async (dispatch) => {
  try {
    // Loading
    dispatch({
      type: GET_MASTER_DATA,
    });
    const cats = await W2pService.getCategories();
    const tags = await W2pService.getTags();
    dispatch(hideLoading());
    dispatch({
      type: GET_MASTER_DATA_SUCCESS,
      payload: {
        categories: cats.data,
        tags: tags.data,
      },
    });
  } catch (err) {
    dispatch({ type: GET_MASTER_DATA_FAIL, err });
    dispatch(hideLoading());
  }
};

export const getMappingRules = (templateId) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_MAPPING_RULES_REQUEST });
    const response = await Promise.all([
      BusinessInfosService.getRegions(),
      UserTypeService.getUserTypes(),
      W2pService.getTemplatesMappingRules({ templateId }),
      JobTypeService.getJobTypes(100),
    ]);

    const mappingRules = response[2].data.map((item) => {
      const regions = response[0].data.map((region) => {
        return {
          ...region,
          isChecked: false,
        };
      });

      const selectedRegions = getSelectedRegions(item.templateAccess);

      const userTypes = response[1].data.map((userType) => {
        const userTypeChecked =
          item.templateAccess.length > 0
            ? item.templateAccess[0].userTypeId
            : null;
        return {
          ...userType,
          isChecked: userType.id === userTypeChecked,
        };
      });

      const jobTypes = response[3].data.map((jobType) => {
        const jobTypeChecked =
          item.templateAccess.length > 0
            ? item.templateAccess[0].jobTypeId
            : null;
        return {
          ...jobType,
          isChecked: jobType.id === jobTypeChecked,
        };
      });

      return {
        ...item,
        selectedRegions,
        regions,
        userTypes,
        jobTypes,
        companiesByRegion: [],
      };
    });
    dispatch(hideLoading());
    dispatch({ type: GET_MAPPING_RULES_SUCCESS, payload: mappingRules });
  } catch (err) {
    dispatch({ type: GET_MAPPING_RULES_FAIL, err });
    dispatch(hideLoading());
  }
};

export const resetW2PSingleContent = () => (dispatch) => {
  dispatch({ type: RESET_W2P_SINGLE_CONTENT });
};

export const getCompany = (regionIds, ruleItem) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    const result = await BusinessInfosService.getCompaniesByRegions(regionIds);

    const companies = result.data
      .map((item) => {
        return {
          id: item.id,
          name: item.name,
          regionId: item.regionId,
          isAccess: false,
        };
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    dispatch({
      type: GET_COMPANY_SUCCESS,
      payload: {
        ruleItem,
        companies,
      },
    });
    dispatch(hideLoading());
  } catch (err) {
    dispatch({
      type: GET_COMPANY_FAIL,
      payload: {
        ruleItem,
        companies: [],
      },
    });
    dispatch(hideLoading());
  }
};

export const resetCompany = (ruleItem) => (dispatch) => {
  dispatch({ type: RESET_COMPANY, payload: { ruleItem } });
};

export const addRule = (name, templateId) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: ADD_RULE_REQUEST });
    const newRule = {
      name,
      templateId,
    };

    await W2pService.addTemplatesMappingRules(newRule);

    dispatch(hideLoading());
    dispatch({ type: ADD_RULE_SUCCESS });
    dispatch(getMappingRules(templateId));
  } catch (err) {
    dispatch({ type: ADD_RULE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const deleteRule = (ruleId, templateId) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: DELETE_RULE_REQUEST });

    await W2pService.deleteTemplatesMappingRules(ruleId);

    dispatch(hideLoading());
    dispatch({ type: DELETE_RULE_SUCCESS });
    dispatch(getMappingRules(templateId));
  } catch (err) {
    dispatch({ type: DELETE_RULE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const updateRegion = (hasAccess, ruleItem, region) => (dispatch) => {
  dispatch({
    type: UPDATE_REGION,
    payload: { hasAccess, ruleItem, region },
  });
};

export const updateAllRegion = (hasAccess, ruleItem) => (dispatch) => {
  dispatch({
    type: UPDATE_ALL_REGION,
    payload: { hasAccess, ruleItem },
  });
};

export const updateUserType = (checked, ruleItem, userType) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_TYPE,
    payload: { checked, ruleItem, userType },
  });
};

export const updateJobType = (checked, ruleItem, jobType) => (dispatch) => {
  dispatch({
    type: UPDATE_JOB_TYPE,
    payload: { checked, ruleItem, jobType },
  });
};

export const updateSingleCompany =
  (hasAccess, ruleItem, company) => (dispatch) => {
    dispatch({
      type: UPDATE_SINGLE_COMPANY,
      payload: { hasAccess, ruleItem, company },
    });
  };

export const saveMappingRule = (data) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    await W2pService.updateTemplatesMappingRules(data);
    dispatch(hideLoading());
    dispatch({
      type: SAVE_MAPPING_RULE_SUCCESS,
    });
  } catch (err) {
    dispatch({ type: SAVE_MAPPING_RULE_FAIL, err });
    dispatch(hideLoading());
  }
};

export const updateSelectedRegions =
  (regions, ruleItem, regionId) => (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_REGION,
      payload: { regions, ruleItem, regionId },
    });
  };
