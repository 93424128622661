import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const Callback = () => {
  const history = useHistory();

  useEffect(() => {
    history.push('/');
  }, [history]);

  return <div>Redirecting...</div>;
};
