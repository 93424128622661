const awsConfig = {
  Auth: {
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    region: process.env.REACT_APP_AWS_DEFAULT_REGION,
    oauth: {
      domain: process.env.REACT_APP_AWS_USER_POOLS_HOST_NAME,
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: `${window.location.origin}/callback`,
      redirectSignOut: `${window.location.origin}/logout`,
      responseType: 'code',
    },
  },
};

export default awsConfig;
