import { Col, Layout, Row, Spin } from 'antd';
import Breadcrumb from 'components/UI/Breadcrumb';
import ScrollToTop from 'components/ScrollToTop';
import { useEffect, useMemo } from 'react';
import { navigationRoutes } from 'utilities';
import { BaseButton } from 'components/UI/BaseButton';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getStartPage, resetStartPageData } from 'redux/microSite/operation';
import { selectIsLoading, selectStartPageData } from 'redux/microSite/selector';
import { imagePlaceHolder } from 'assets/images';

export const MicroSiteStartPage = () => {
  const { Content } = Layout;
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const { isLoading, startPage } = useSelector((state) => ({
    isLoading: selectIsLoading(state),
    startPage: selectStartPageData(state),
  }));

  useEffect(() => {
    dispatch(getStartPage(id, false));

    return () => {
      dispatch(resetStartPageData());
    };
  }, [dispatch, id]);

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Home,
      },
      {
        title: startPage.title || '',
      },
    ],
    [startPage.title],
  );

  const onClickAction = (subPage) => {
    history.push(subPage.embededLink);
  };

  return (
    <Content className="micro-site-start-page">
      {isLoading ? (
        <div className="loading">
          <Spin />
        </div>
      ) : (
        <>
          <Breadcrumb items={breadCrumbItems} />
          <div className="start-page_banner">
            <div className="middle-content">
              <h2 className="start-page-headline font-size-big bold-text">
                {startPage?.headline}
              </h2>
              <p className="start-page-text-headline bold-text">
                {startPage?.textHeadline}
              </p>
            </div>
          </div>
          <div className="start-page-image">
            <img src={startPage.mediaHeadline || imagePlaceHolder} alt="" />
          </div>
          <div className="middle-content">
            {startPage.subPages &&
              startPage.subPages.length > 0 &&
              startPage.subPages.map((subPage) => (
                <Row
                  className="start-page-sub-headline"
                  key={subPage.embededLink}
                  gutter={[24, 0]}>
                  <Col span={12}>
                    <h2 className="sub-headline-title mb-0">{subPage.title}</h2>
                    <p className="sub-headline-description mb-8">
                      {subPage.textHeadline}
                    </p>
                    <BaseButton
                      className="base-yellow-btn"
                      onClick={() => onClickAction(subPage)}>
                      VIEW HERE
                    </BaseButton>
                  </Col>
                  {subPage.mediaHeadline ? (
                    <Col className="sub-headline-image" span={12}>
                      <img src={subPage.mediaHeadline} alt="" />
                    </Col>
                  ) : null}
                  <Col span={24} className="sub-headline-divider mt-8 mb-8">
                    <hr />
                  </Col>
                </Row>
              ))}
          </div>
        </>
      )}
      <ScrollToTop />
    </Content>
  );
};
