import { Hub, Amplify } from 'aws-amplify';
import LoadingBar from 'react-redux-loading-bar';
import { useAuth } from 'hooks/useAuth';

import Routes from './routes';
import { APP_DOMAIN, awsConfig, hubListener } from './utilities';

Hub.listen('auth', hubListener);

Amplify.configure(awsConfig);

const App = () => {
  const [isCheckingAuth, isLoggedIn] = useAuth();

  if (isCheckingAuth) {
    return <p>Checking authentication...</p>;
  }

  if (window.location.hostname.indexOf(APP_DOMAIN) >= 0) {
    document.domain = APP_DOMAIN;
  }

  return (
    <div className="App">
      <LoadingBar className="loading-bar" />
      <Routes isLoggedIn={isLoggedIn} />
    </div>
  );
};

export default App;
