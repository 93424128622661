import axios from 'axios';
import Auth from 'utilities/auth';
import { errorHandle } from './commons';
// create an axios instance
const http = axios.create({
  baseURL: process.env.REACT_APP_AWS_API_GETWAY_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  async (config) => {
    const token = await Auth.getJwtToken();
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// response interceptor
http.interceptors.response.use(
  (response) => {
    const res = response.data;
    return res;
  },
  (error) => {
    errorHandle(error);
    return Promise.reject(error);
  },
);

export default http;
