import { Checkbox } from 'antd';

export const renderRegionColumns = (onClickRegion, ruleItem, onCheckRegion) => {
  const columns = [
    {
      dataIndex: 'action',
      render: (text, record) => {
        const { id, isChecked } = record;
        return (
          <Checkbox
            key={id}
            checked={isChecked}
            onChange={(event) => onCheckRegion(event, record)}
          />
        );
      },
    },
    {
      dataIndex: 'name',
      width: '20%',
      render: (text, record) => {
        const { name } = record;
        return (
          <div
            aria-hidden="true"
            className="region-column"
            onClick={() => onClickRegion(record, ruleItem)}>
            {name}
          </div>
        );
      },
    },
  ];

  return columns;
};

export const renderCompanyColumns = (onCheckCompany) => {
  const columns = [
    {
      dataIndex: 'isAccess',
      width: '10%',
      render: (text, record) => {
        const { id, isAccess } = record;
        return (
          <Checkbox
            checked={isAccess}
            onChange={(event) => onCheckCompany(event, record)}
            key={id}
          />
        );
      },
    },
    {
      dataIndex: 'name',
      width: '30%',
    },
  ];

  return columns;
};
