/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import { Row, Col, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { STATUS, openConfirmModal } from 'utilities';
import BaseSelect from 'components/UI/BaseSelect';
import { BaseButton } from 'components/UI/BaseButton';
import {
  updateDetailUser,
  getRegion,
  getCompany,
  getJobType,
  setUserAdminRole,
  setUserViewAllTiles,
} from 'redux/administration/user-management/operation';
import { selectUserManagements } from 'redux/administration/user-management/selector';
import { OPTION, TYPE_BUSINESS } from './util/constants';
import { set } from 'lodash';

const EditSingleUser = ({ onHandleCancelEditUser }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { userManagementInfo } = useSelector((state) => ({
    userManagementInfo: selectUserManagements(state),
  }));

  const [status, setStatus] = useState(userManagementInfo.detailUser.status);
  const [tileRolesLocal, setTileRolesLocal] = useState([]);
  const [optionRegion, setOptionRegion] = useState([
    { value: '', displayName: OPTION.region },
  ]);
  const [optionCompany, setOptionCompany] = useState([
    { value: '', displayName: OPTION.company },
  ]);
  const [optionJobType, setOptionJobType] = useState([
    { value: '', displayName: OPTION.jobType },
  ]);

  const [region, setRegion] = useState({
    id: '',
    name: '',
  });

  const [company, setCompany] = useState({
    id: '',
    name: '',
  });

  const [jobType, setJobType] = useState({
    id: '',
    name: '',
  });

  const isAdmin = userManagementInfo.detailUser?.userRole?.id === 2;
  const isViewAllTiles = userManagementInfo.detailUser?.userRole?.id === 3;

  useEffect(() => {
    dispatch(getRegion());
    dispatch(getJobType());
    if (userManagementInfo.detailUser.companyId) {
      dispatch(getCompany(userManagementInfo.detailUser.regionId));
    }
  }, []);

  const setInitialOption = (type) => {
    switch (type) {
      case TYPE_BUSINESS.region: {
        const options = userManagementInfo.region.map((item) => {
          return {
            value: item.id,
            displayName: item.name,
          };
        });
        options.unshift({ value: '', displayName: OPTION.region });
        return options;
      }
      case TYPE_BUSINESS.company: {
        const options = userManagementInfo.company.map((item) => {
          return {
            value: item.id,
            displayName: item.id,
          };
        });
        options.unshift({ value: '', displayName: OPTION.company });
        return options;
      }
      case TYPE_BUSINESS.jobType: {
        const options = userManagementInfo.jobTypes.map((item) => {
          return {
            value: item.id,
            displayName: item.name,
          };
        });
        options.unshift({ value: '', displayName: OPTION.jobType });
        return options;
      }
      default:
        return [];
    }
  };

  useEffect(() => {
    if (userManagementInfo.region.length > 0) {
      const options = setInitialOption(TYPE_BUSINESS.region);
      setOptionRegion(options);
    }
  }, [userManagementInfo.region]);

  useEffect(() => {
    setRegion({
      id: userManagementInfo.detailUser.region.id,
      name: userManagementInfo.detailUser.region.name,
    });
  }, [userManagementInfo.detailUser.region]);

  useEffect(() => {
    setCompany({
      id:
        userManagementInfo.detailUser.company &&
        userManagementInfo.detailUser.company.id
          ? userManagementInfo.detailUser.company.id
          : '',
      name:
        userManagementInfo.detailUser.company &&
        userManagementInfo.detailUser.company.name
          ? userManagementInfo.detailUser.company.name
          : OPTION.company,
    });
  }, [userManagementInfo.detailUser.company]);

  useEffect(() => {
    if (userManagementInfo.company.length > 0) {
      const options = setInitialOption(TYPE_BUSINESS.company);
      setOptionCompany(options);
    }
  }, [userManagementInfo.company]);

  useEffect(() => {
    if (userManagementInfo.jobTypes.length > 0) {
      const options = setInitialOption(TYPE_BUSINESS.jobType);
      setOptionJobType(options);
      setJobType({
        id:
          userManagementInfo.detailUser.jobType &&
          userManagementInfo.detailUser.jobType.id
            ? userManagementInfo.detailUser.jobType.id
            : '',
        name:
          userManagementInfo.detailUser.jobType &&
          userManagementInfo.detailUser.jobType.name
            ? userManagementInfo.detailUser.jobType.name
            : OPTION.jobType,
      });
    }
  }, [userManagementInfo.jobTypes]);

  const handleTileRole = (value, option) => {
    const tileExists = tileRolesLocal.find(
      (item) => item.tileId === option.parent,
    );
    if (tileExists) {
      const newTileRolesLocal = tileRolesLocal.map((item) => {
        if (item.tileId === tileExists.tileId) {
          item.tileTypeRoleId = option.value;
        }

        return item;
      });
      setTileRolesLocal(newTileRolesLocal);
    } else {
      setTileRolesLocal([
        ...tileRolesLocal,
        {
          tileId: option.parent,
          tileTypeRoleId: option.value,
        },
      ]);
    }
  };

  const renderTileRole = () => {
    if (userManagementInfo.detailUser) {
      const { userTileRoles } = userManagementInfo.detailUser;
      const itemTileRoles = [];
      userTileRoles.forEach((item) => {
        const result = Object.keys(userManagementInfo.detailUser).find(
          (key) => key === item.tile.title.replace(/\s/g, '_'),
        );

        const detailTile = userManagementInfo.detailUser.tiles.find(
          (tileRole) =>
            tileRole.id === userManagementInfo.detailUser[result].id,
        );

        const options = detailTile
          ? detailTile.tileTypeRoles
              .map((role) => ({
                ...role,
                value: role.id,
                displayName: role.name,
                parent: detailTile.id,
              }))
              .sort((a, b) => {
                if (a.displayName < b.displayName) return -1;
                return a.displayName > b.displayName ? 1 : 0;
              })
          : [];

        const defaultValue = userManagementInfo.detailUser[result].idTileRole;
        if (result) {
          itemTileRoles.push(
            <Col key={item.tile.title} md={12} lg={12} className="mt-4">
              <div className="d-flex flex-column align-start">
                <span className="bold-text label">{item.tile.title}</span>
                <BaseSelect
                  options={options}
                  onChange={handleTileRole}
                  defaultValue={defaultValue}
                />
              </div>
            </Col>,
          );
        }
      });

      return <>{itemTileRoles}</>;
    }
    return null;
  };

  const handleStatus = (statusChanged) => {
    setStatus(statusChanged);
  };

  const handleJobType = (value, option) => {
    setJobType({ id: option.value, name: option.children });
  };

  const handleRegion = (value, option) => {
    dispatch(getCompany(value));
    setRegion({ id: option.value, name: option.children });
    setCompany({ id: '' });
  };

  const handleCompany = (value, option) => {
    setCompany({ id: option.value, name: option.children });
  };

  const onSaveChanges = () => {
    const data = {
      status,
      userTileRoles: tileRolesLocal,
      username: userManagementInfo.detailUser.username,
      userTypeId: userManagementInfo.detailUser.userTypeId,
    };
    if (userManagementInfo.detailUser.manualSetCompany) {
      data.manualSetCompany = userManagementInfo.detailUser.manualSetCompany;
      data.companyId = company.id;
      data.regionId = region.id;
    }
    if (jobType.id) {
      data.jobTypeId = jobType.id;
      data.jobTypeName = jobType.name;
    } else {
      data.jobTypeId = null;
      data.jobTypeName = null;
    }
    dispatch(updateDetailUser(params.userId, data));
  };

  //Set role handling

  const message = (
    <span>Are you sure you want to give admin rights to this user?</span>
  );

  const messageRemove = (
    <span>
      Are you sure you want to remove the admin rights from this user?
    </span>
  );

  const messageViewTiles = (
    <span>
      Are you sure you want to give the rights to this user to see all tiles?
    </span>
  );

  const messageRemoveViewTiles = (
    <span>
      Are you sure you want to remove the rights to this user to see all tiles?
    </span>
  );

  const openModal = useCallback((event) => {
    const modalMessage =
      event.target.id === 'admin'
        ? event.target.checked
          ? message
          : messageRemove
        : event.target.checked
        ? messageViewTiles
        : messageRemoveViewTiles;
    openConfirmModal({
      title: 'ANNOUNCEMENT',
      content: modalMessage,
      onOk: () => {
        if (event.target.id === 'admin') {
          dispatch(
            setUserAdminRole(
              userManagementInfo.detailUser.id,
              event.target.checked,
            ),
          );
        } else {
          dispatch(
            setUserViewAllTiles(
              userManagementInfo.detailUser.id,
              event.target.checked,
            ),
          );
        }
      },
    });
  });

  return (
    <>
      {userManagementInfo.detailUser && (
        <>
          <div className="single-user-management-general pt-5 pb-5">
            <div className="middle-content">
              <h1>{userManagementInfo.detailUser.fullname} </h1>
              <Row>
                <Col xs={24} md={8} lg={8}>
                  <div className="d-flex flex-column align-start">
                    <span className="bold-text label">LOGIN ID</span>
                    <span>{userManagementInfo.detailUser.id}</span>
                  </div>
                </Col>
                <Col xs={24} md={8} lg={8}>
                  <div className="d-flex flex-column align-start">
                    <span className="bold-text label">USER STATUS</span>
                    {userManagementInfo.detailUser.status === STATUS.ACTIVE && (
                      <span className="status-active width-20">
                        {userManagementInfo.detailUser.status}
                      </span>
                    )}
                    {userManagementInfo.detailUser.status ===
                      STATUS.INACTIVE && (
                      <span className="status-inactive width-20">
                        {userManagementInfo.detailUser.status}
                      </span>
                    )}
                  </div>
                </Col>
                <Col xs={24} md={8} lg={8}>
                  <div className="d-flex flex-column align-start">
                    <span className="bold-text label">E-MAIL ADDRESS</span>
                    <span>{userManagementInfo.detailUser.email}</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="single-user-management-information middle-content pt-5 pb-5">
            <Row>
              <Col xs={24} md={16} lg={16}>
                <h3 className="bold-text">USER INFORMATION </h3>
                <Row>
                  <Col md={12} lg={12} className="mt-4">
                    <div className="d-flex flex-column align-start">
                      <span className="bold-text label">
                        {userManagementInfo.detailUser.fullname}
                      </span>
                      <span>{userManagementInfo.detailUser.email}</span>
                    </div>
                  </Col>
                  <Col md={12} lg={12} className="mt-4" />
                  <Col md={12} lg={12} className="mt-4">
                    <div className="d-flex flex-column align-start">
                      <span className="bold-text label">Region</span>
                      <BaseSelect
                        options={optionRegion}
                        value={region.id}
                        onSelect={handleRegion}
                        disabled={
                          !userManagementInfo.detailUser.manualSetCompany
                        }
                      />
                    </div>
                  </Col>
                  <Col md={12} lg={12} className="mt-4">
                    <div className="d-flex flex-column align-start">
                      <span className="bold-text label">Company</span>
                      <BaseSelect
                        options={optionCompany}
                        value={company.id}
                        disabled={
                          !userManagementInfo.detailUser.manualSetCompany
                        }
                        onSelect={handleCompany}
                      />
                    </div>
                  </Col>
                  <Col md={12} lg={12} className="mt-4">
                    <div className="d-flex flex-column align-start">
                      <span className="bold-text label">Job Type</span>
                      <BaseSelect
                        options={optionJobType}
                        value={jobType.id}
                        onSelect={handleJobType}
                      />
                    </div>
                  </Col>

                  <Col md={12} lg={12} className="mt-4">
                    <Checkbox
                      checked={isAdmin}
                      id="admin"
                      onChange={(event) => openModal(event)}>
                      {' '}
                      Admin
                    </Checkbox>{' '}
                    <br></br>
                    <Checkbox
                      checked={isViewAllTiles}
                      id="viewAllTiles"
                      onChange={(event) => openModal(event)}>
                      {' '}
                      View all tiles
                    </Checkbox>
                  </Col>
                  {renderTileRole()}
                </Row>
              </Col>
              <Col xs={24} md={8} lg={8}>
                <h3 className="bold-text">USER STATUS </h3>
                <div>
                  <BaseButton
                    className={
                      status === STATUS.ACTIVE ? 'base-yellow-btn' : ''
                    }
                    onClick={() => handleStatus(STATUS.ACTIVE)}>
                    ACTIVE
                  </BaseButton>
                  <BaseButton
                    className={`ml-3 ${
                      status === STATUS.INACTIVE ? 'base-yellow-btn' : ''
                    }`}
                    onClick={() => handleStatus(STATUS.INACTIVE)}>
                    INACTIVE
                  </BaseButton>
                </div>
                <hr align="left" className="divider-user-status mt-13" />
              </Col>
            </Row>
          </div>
          <div className="single-user-management-button middle-content pt-5 pb-5 d-flex flex-row align-center">
            <BaseButton className="btn-cancel" onClick={onHandleCancelEditUser}>
              CANCEL
            </BaseButton>
            <BaseButton
              className="base-yellow-btn btn-save-changes"
              loading={userManagementInfo.isLoading}
              onClick={onSaveChanges}>
              SAVE CHANGES
            </BaseButton>
          </div>
        </>
      )}
    </>
  );
};

EditSingleUser.propTypes = {
  onHandleCancelEditUser: PropTypes.func.isRequired,
};

export default EditSingleUser;
