import immutable from 'immutable';
import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  UPDATE_USER_LANGUAGE_REQUEST,
  UPDATE_USER_LANGUAGE_SUCCESS,
  UPDATE_USER_LANGUAGE_ERROR,
  UPDATE_USER_INFO_REQUEST,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_ERROR,
} from './action';

const init = () => {
  const initValue = immutable.fromJS({
    data: undefined,
    isLoading: undefined,
  });

  return initValue
    .set('data', {
      id: '',
      fullname: '',
      email: '',
      company: '',
      langCode: '',
      phoneNumber: '',
      languages: [],
    })
    .set('isLoading', false);
};

export default function userProfileReducer(state = init(), action) {
  switch (action.type) {
    case GET_USER_PROFILE_REQUEST:
      return state.set('isLoading', true);
    case GET_USER_PROFILE_SUCCESS: {
      const { payload } = action;
      return state
        .update('data', (data) => ({
          ...data,
          ...payload,
          company: payload?.company?.name || '',
        }))
        .set('isLoading', false);
    }
    case GET_USER_PROFILE_FAIL:
      return state.set('isLoading', false);
    case UPDATE_USER_LANGUAGE_REQUEST:
      return state.set('isLoading', true);
    case UPDATE_USER_LANGUAGE_SUCCESS: {
      const { payload } = action;
      return state
        .update('data', (data) => ({
          ...data,
          langCode: payload.langCode,
        }))
        .set('isLoading', false);
    }
    case UPDATE_USER_LANGUAGE_ERROR:
      return state.set('isLoading', false);
    case UPDATE_USER_INFO_REQUEST:
      return state.set('isLoading', true);
    case UPDATE_USER_INFO_SUCCESS:
    case UPDATE_USER_INFO_ERROR:
      return state.set('isLoading', false);

    default:
      return state;
  }
}
