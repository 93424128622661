export const GET_TILES_REQUEST = 'GET_TILES_REQUEST';
export const GET_TILES_SUCCESS = 'GET_TILES_SUCCESS';
export const GET_TILES_FAIL = 'GET_TILES_FAIL';

export const DELETE_TILE_REQUEST = 'DELETE_TILE_REQUEST';
export const DELETE_TILE_SUCCESS = 'DELETE_TILE_SUCCESS';
export const DELETE_TILE_FAIL = 'DELETE_TILE_FAIL';

export const CHANGE_ORDER_TILE_REQUEST = 'CHANGE_ORDER_TILE_REQUEST';
export const CHANGE_ORDER_TILE_SUCCESS = 'CHANGE_ORDER_TILE_SUCCESS';
export const CHANGE_ORDER_TILE_FAIL = 'CHANGE_ORDER_TILE_FAIL';
