import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_HEADER_TITLE } from 'redux/global/header/action';
import { selectHeaderTitle } from 'redux/global/header/selector';

/**
 * React Hook to update header title.
 *
 * @param {string} title - The prototype of title.
 */

const useHeader = (title) => {
  const dispatch = useDispatch();
  const currentTitle = useSelector((state) => selectHeaderTitle(state));

  useEffect(() => {
    if (title !== currentTitle) {
      dispatch({ type: UPDATE_HEADER_TITLE, payload: { title } });
    }
  }, [dispatch, title, currentTitle]);
};

export default useHeader;
