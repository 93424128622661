export const OPTION = {
  region: 'Please select the region',
  company: 'Please select the company',
  jobType: 'Please select the job type',
};

export const TYPE_BUSINESS = {
  region: 'region',
  company: 'company',
  jobType: 'jobType',
};
