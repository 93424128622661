import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { W2pService } from 'services';
import { toast } from 'utilities';
import {
  GET_W2P_CONTENT_FAIL,
  GET_W2P_CONTENT_REQUEST,
  GET_W2P_CONTENT_SUCCESS,
  UPDATE_THUMBNAIL,
  UPDATE_THUMBNAIL_FAIL,
  UPDATE_THUMBNAIL_SUCCESS,
} from './action';

export const getW2PContent = (paginationData) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_W2P_CONTENT_REQUEST });
    const result = await W2pService.getTemplatesManagements(paginationData);
    dispatch(hideLoading());
    dispatch({ type: GET_W2P_CONTENT_SUCCESS, payload: result.data });
  } catch (err) {
    dispatch({ type: GET_W2P_CONTENT_FAIL, err });
    dispatch(hideLoading());
  }
};

export const updateThumbnail = (id) => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: UPDATE_THUMBNAIL });
    const result = await W2pService.updateThumbnail(id);
    dispatch(hideLoading());
    dispatch({ type: UPDATE_THUMBNAIL_SUCCESS, payload: result.data });
    toast('success', 'Template', 'Update thumbnail Successfully');
  } catch (err) {
    dispatch({ type: UPDATE_THUMBNAIL_FAIL, err });
    dispatch(hideLoading());
  }
};
