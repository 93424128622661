import immutable from 'immutable';
import { UPDATE_BROWSER_TITLE } from './action';

const init = () => {
  const initValue = immutable.fromJS({
    browser: undefined,
  });

  return initValue.set('browser', {
    title: '',
  });
};

export default function browserReducer(state = init(), action) {
  if (action.type === UPDATE_BROWSER_TITLE) {
    return state.update('browser', (browser) => ({
      ...browser,
      title: action.payload.title,
    }));
  } else {
    return state;
  }
}
