import { Layout, Menu, Dropdown } from 'antd';
import { navigationRoutes } from 'utilities';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { Contentmanagement } from 'assets/images';
import { selectMenuInfo } from 'redux/administration/menu/selector';
import { getCmsMenu } from 'redux/administration/menu/operation';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const SideBar = () => {
  const { Sider } = Layout;
  const [openKeys, setOpenKeys] = useState([]);
  const { pathname } = useLocation();

  const navigate = useHistory();

  const openMenu = useCallback(
    (menu, isMain) => {
      const updateMenu = [...openKeys];
      if (updateMenu.indexOf(menu) >= 0) {
        if (isMain) {
          setOpenKeys([]);
          return;
        }
        updateMenu.pop(menu);
      } else updateMenu.push(menu);
      setOpenKeys(updateMenu);
    },
    [openKeys],
  );

  const dispatch = useDispatch();

  const menuSelector = useSelector((state) => selectMenuInfo(state));

  const allMenuLink = useMemo(() => {
    const result = [];
    if (menuSelector.menu && menuSelector.menu.length > 0) {
      menuSelector.menu.forEach((item) => {
        if (item.embeddedLink) {
          result.push(item.embeddedLink);
        }
        if (item.children && item.children.length > 0) {
          item.children.forEach((child) => {
            if (child.embeddedLink) {
              result.push(child.embeddedLink);
            }
            if (child.children && child.children.length > 0) {
              child.children.forEach((subChild) => {
                if (subChild.embeddedLink) {
                  result.push(subChild.embeddedLink);
                }
              });
            }
          });
        }
      });
    }
    return result;
  }, [menuSelector.menu]);

  const defaultSelection = useMemo(() => {
    const checkedArr = allMenuLink.filter(
      (link) => pathname.indexOf(link) !== -1,
    );
    return checkedArr[checkedArr.length - 1] || allMenuLink[0];
  }, [allMenuLink, pathname]);

  useEffect(() => {
    dispatch(getCmsMenu());
  }, [dispatch]);

  const sitesRenderer = useMemo(() => {
    const returnSites = [];
    menuSelector.menu.forEach((site) => {
      if (site.children) {
        const subMenu = [];
        site.children.forEach((child) => {
          if (child.children) {
            const subMenuKey = child.embeddedLink;
            subMenu.push(
              <Menu.SubMenu
                className="sidebar-submenu_item add-menu"
                title={
                  <>
                    <button
                      type="button"
                      className="expand-button"
                      onClick={() => openMenu(subMenuKey)}>
                      <i
                        className={
                          openKeys.includes(subMenuKey)
                            ? 'arrow-icon-expand'
                            : 'arrow-icon'
                        }
                      />
                    </button>
                    <div className="submenu_item-content_wrapper">
                      <span className="ant-menu-title-content">
                        <Link to={child.embeddedLink} className="in-menu-link">
                          <span> {`${child.title?.toUpperCase()} `}</span>
                        </Link>
                      </span>
                      {child.isCMS && (
                        <Dropdown
                          className="add-sub-page-btn"
                          overlay={
                            <Menu className="sub-page-menu">
                              <Menu.Item key="0">
                                <Link
                                  to={`${navigationRoutes.MicroSingleStartPage}/${child.id}/text-sub-page`}>
                                  Create Text Sub Page
                                </Link>
                              </Menu.Item>
                              <Menu.Item key="1">
                                <Link
                                  to={`${navigationRoutes.MicroSingleStartPage}/${child.id}/media-sub-page`}>
                                  Create Media Sub Page
                                </Link>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={['click']}>
                          <button
                            type="button"
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}>
                            <PlusOutlined />
                          </button>
                        </Dropdown>
                      )}
                    </div>
                  </>
                }
                key={subMenuKey}>
                {child.children.map((child2) => {
                  return (
                    <Menu.Item
                      key={child2.embeddedLink}
                      className="sidebar-menu_item">
                      <Link
                        to={child2.embeddedLink}
                        className="sidebar-menu_link d-flex align-center flex-row">
                        &#9679;&nbsp;
                        <span
                          className="sidebar-menu_title"
                          dangerouslySetInnerHTML={{ __html: child2.title }}
                        />
                      </Link>
                    </Menu.Item>
                  );
                })}
              </Menu.SubMenu>,
            );
          } else {
            subMenu.push(
              <Menu.Item key={child.embeddedLink} className="sidebar-menu_item">
                <Link
                  to={child.embeddedLink}
                  className="sidebar-menu_link d-flex align-center flex-row">
                  &#9679;&nbsp;
                  <span
                    className="sidebar-menu_title"
                    dangerouslySetInnerHTML={{ __html: child.title }}
                  />
                </Link>
              </Menu.Item>,
            );
          }
        });

        returnSites.push(
          <Menu.SubMenu
            className="sidebar-submenu_item"
            icon={<Contentmanagement className="icon-submenu" />}
            title={
              <span
                className="ant-menu-title-content"
                dangerouslySetInnerHTML={{ __html: site.title }}
              />
            }
            onTitleClick={() => {
              navigate.push(site.embeddedLink);
              openMenu(site.id, true);
            }}
            key={site.id}>
            {subMenu.map((e) => e)}
          </Menu.SubMenu>,
        );
      } else {
        returnSites.push(
          <Menu.Item key={site.embeddedLink} className="sidebar-menu_item">
            <Link
              to={site.embeddedLink}
              className="sidebar-menu_link d-flex align-center flex-row">
              {site.icon}
              <span
                className="sidebar-menu_title"
                dangerouslySetInnerHTML={{ __html: site.title }}
              />
            </Link>
          </Menu.Item>,
        );
      }
    });

    return returnSites;
  }, [menuSelector.menu, openMenu, openKeys]);

  return (
    <Sider className="sidebar">
      <Menu
        selectedKeys={[defaultSelection]}
        openKeys={openKeys}
        mode="inline"
        className="sidebar-menu">
        {sitesRenderer.map((e) => e)}
      </Menu>
    </Sider>
  );
};

export default SideBar;
