import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox } from 'antd';
import PropType from 'prop-types';
import { SearchBox } from 'components/UI/SearchBox';
import BaseTable from 'components/UI/BaseTable';
import {
  selectAllRegions,
  selectBusinessInfo,
  selectIsLoadingRegions,
} from 'redux/administration/mapping-rules/selector';

import {
  getAllRegions,
  getBusinessInfos,
  updateBusinessInfo,
  updateTileBusinessInfos,
} from 'redux/administration/mapping-rules/operation';
import { BUSINESS_TYPE } from 'utilities';

const initPaging = {
  _page: 1,
  _limit: 20,
};

const BaseRule = (props) => {
  const { type } = props;
  const { biSelector, allRegions, isLoadingAllRegions } = useSelector(
    (state) => ({
      biSelector: selectBusinessInfo(state),
      allRegions: selectAllRegions(state),
      isLoadingAllRegions: selectIsLoadingRegions(state),
    }),
  );
  // Paging
  const [paginationData, setPaginationData] = useState(initPaging);

  const dispatch = useDispatch();

  // Payload
  useEffect(() => {
    dispatch(getBusinessInfos(type, paginationData));
  }, [dispatch, type, paginationData]);

  useEffect(() => {
    if (type === BUSINESS_TYPE.COMPANY) {
      dispatch(getAllRegions());
    }
  }, [dispatch, type]);

  // Event handler
  const oneRowBusinessInfoSelect = useCallback(
    (businessInfo, checked) => {
      const updated = biSelector.businessInfos.find(
        (e) => e.id === businessInfo.id,
      );
      dispatch(updateBusinessInfo(type, updated, checked.target.checked));
    },
    [dispatch, biSelector.businessInfos, type],
  );

  const onTileBusinessInfoSelect = useCallback(
    (checked, tile) => {
      dispatch(updateTileBusinessInfos(type, tile, checked.target.checked));
    },
    [dispatch, type],
  );

  const onBusinessInfoSelect = useCallback(
    (businessInfo, tile, checked) => {
      const updated = biSelector.businessInfos.find(
        (e) => e.id === businessInfo.id,
      );
      updated.accessTiles.forEach((t) => {
        if (t.id === tile) {
          t.hasAccess = checked.target.checked;
          if (type === BUSINESS_TYPE.REGION && t.hasAccess) {
            t.allCompanyChecked = true;
          }
        }
      });
      dispatch(updateBusinessInfo(type, updated));
    },
    [biSelector.businessInfos, dispatch, type],
  );

  const onFilter = (startWith) => {
    if (startWith === 'ALL') {
      setPaginationData((currentPagination) => ({
        ...currentPagination,
        ...initPaging,
        _startWith: '',
      }));
    } else {
      setPaginationData((currentPagination) => ({
        ...currentPagination,
        ...initPaging,
        _startWith: startWith,
      }));
    }
  };

  const onSearch = (term) => {
    setPaginationData((currentPagination) => ({
      ...currentPagination,
      ...initPaging,
      name: term,
    }));
  };

  const onSelectRegion =
    type === BUSINESS_TYPE.COMPANY
      ? (value) => {
          setPaginationData((currentPagination) => ({
            ...currentPagination,
            ...initPaging,
            regionId: value,
          }));
        }
      : null;

  const onClearSelectedRegion = () => {
    setPaginationData((currentPagination) => {
      delete currentPagination.regionId;
      return {
        ...currentPagination,
        ...initPaging,
      };
    });
  };

  // Build Table
  const columns = useMemo(
    () =>
      biSelector.businessInfos.length > 0
        ? [
            {
              title: 'Name',
              dataIndex: 'name',
            },
            {
              title: '',
              dataIndex: 'checked',
              render: (data, businessInfo) => {
                let countDisabled = 0;
                Object.entries(businessInfo).forEach(([, value]) => {
                  if (value.id && value.isDisabled === true) countDisabled += 1;
                });
                return (
                  <Checkbox
                    checked={data}
                    disabled={
                      type === BUSINESS_TYPE.COMPANY && countDisabled > 0
                    }
                    key={businessInfo.id}
                    onChange={(event) =>
                      oneRowBusinessInfoSelect(businessInfo, event)
                    }
                  />
                );
              },
            },
            ...biSelector.businessInfos[0].accessTiles.map((e) => {
              const checkAllTile =
                biSelector.businessInfos.filter(
                  (bi) =>
                    bi.accessTiles.filter((t) => t.id === e.id && !t.hasAccess)
                      .length > 0,
                ).length > 0;

              const isColDisabled =
                type === BUSINESS_TYPE.COMPANY &&
                biSelector.businessInfos.filter(
                  (bi) =>
                    bi.accessTiles.filter(
                      (t) => t.id === e.id && t.isDisabled === true,
                    ).length ===
                    bi.accessTiles.filter((t) => t.id === e.id).length,
                ).length > 0;
              return {
                title: (
                  <Checkbox
                    className="column-check"
                    disabled={isColDisabled}
                    checked={!checkAllTile}
                    onChange={(event) => onTileBusinessInfoSelect(event, e.id)}>
                    <p>{e.name}</p>
                  </Checkbox>
                ),
                dataIndex: e.id,
                render: (data, businessInfo) => {
                  const { allCompanyChecked, hasAccess, isDisabled } = data;
                  return (
                    <div
                      key={businessInfo.id}
                      className={
                        hasAccess && allCompanyChecked === false
                          ? 'rule high-light'
                          : 'rule'
                      }>
                      <Checkbox
                        checked={hasAccess}
                        disabled={isDisabled}
                        onChange={(event) =>
                          onBusinessInfoSelect(businessInfo, data.id, event)
                        }
                      />
                    </div>
                  );
                },
              };
            }),
          ]
        : [],
    [
      biSelector.businessInfos,
      oneRowBusinessInfoSelect,
      onTileBusinessInfoSelect,
      onBusinessInfoSelect,
      type,
    ],
  );

  const dataSource = useMemo(
    () =>
      biSelector.businessInfos.map((businessInfo) => {
        const flatten = {
          id: businessInfo.id,
          name: businessInfo.name,
          checked:
            businessInfo.accessTiles.filter((e) => e.hasAccess).length ===
            businessInfo.accessTiles.length,
        };
        businessInfo.accessTiles.forEach((tile) => {
          flatten[tile.id] = {
            id: tile.id,
            name: tile.name,
            hasAccess: tile.hasAccess,
            isDisabled: tile.isDisabled,
            allCompanyChecked: tile.allCompanyChecked,
          };
        });
        return flatten;
      }),
    [biSelector.businessInfos],
  );

  const handleTableChange = (pagination) => {
    setPaginationData({
      _limit: pagination.pageSize,
      _page: pagination.current,
    });
  };

  const tableProps = useMemo(
    () => ({
      columns,
      loading: biSelector.isLoading,
      dataSource,
      rowKey: 'id',
      pagination: {
        current: biSelector.metadata ? Number(biSelector.metadata.page) : 1,
        pageSize: biSelector.metadata ? Number(biSelector.metadata.limit) : 10,
        total: biSelector.metadata
          ? Number(biSelector.metadata.totalRecords)
          : 0,
      },
      onChange: handleTableChange,
    }),
    [columns, biSelector.isLoading, dataSource, biSelector.metadata],
  );

  return (
    <div className="region-rule middle-content">
      <h3 className="bold-text">
        Region overwrites company, company overwrites job type rules.
      </h3>
      <SearchBox
        onFilter={onFilter}
        onSearch={onSearch}
        onSelect={onSelectRegion}
        selectProps={{
          loading: isLoadingAllRegions,
          options: allRegions,
          placeHolder: 'Filter by region',
          onClear: onClearSelectedRegion,
        }}
      />
      <BaseTable {...tableProps} />
    </div>
  );
};

export default BaseRule;

BaseRule.propTypes = {
  type: PropType.string.isRequired,
};
