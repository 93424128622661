import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectPreviewTextSubPage } from 'redux/microSite/selector';
import PreviewComponent from 'components/UI/PreviewComponent';

const Preview = ({ startPageTitle }) => {
  const textSubPage = useSelector((state) => selectPreviewTextSubPage(state));

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
      },
      {
        title: startPageTitle || '',
      },
      {
        title: textSubPage.title || '',
      },
    ],
    [startPageTitle, textSubPage.title],
  );

  const categories = useMemo(
    () =>
      textSubPage.categories
        ? textSubPage.categories.filter((category) => !!category)
        : null,
    [textSubPage],
  );

  return (
    <PreviewComponent
      contentClassName="preview-text-sub-page"
      title={textSubPage?.title}
      breadCrumbItems={breadCrumbItems}
      isHideFooter
      isHasModal
      modalClassName="preview-modal-text-sub-page">
      <div className="banner">
        <div className="middle-content">
          <h2 className="preview-title bold-text mb-0">{textSubPage?.title}</h2>
          <p className="preview-text bold-text mb-0">
            {textSubPage?.textHeadline}
          </p>
        </div>
      </div>
      {textSubPage.mediaHeadline && (
        <div className="media-headline">
          <img src={textSubPage.mediaHeadline} alt="" />
        </div>
      )}
      <div className="content">
        <div className="middle-content">
          {categories && categories.length > 0 ? (
            <>
              {categories.map((item) => (
                <div key={item.id ? item.id : Math.random()}>
                  <h2 className="sub-headline-title bold-text">{item.title}</h2>
                  <p className="sub-headline-text">{item.text}</p>
                </div>
              ))}
            </>
          ) : null}
        </div>
      </div>
    </PreviewComponent>
  );
};

Preview.propTypes = {
  startPageTitle: PropTypes.string,
};

Preview.defaultProps = {
  startPageTitle: '',
};

export default Preview;
