import { Select } from 'antd';
import PropTypes from 'prop-types';

const BaseSelect = (props) => {
  const { Option } = Select;
  const { className, options, ...rest } = props;
  const customClassName = `base-select ${className}`;
  return (
    <Select {...rest} className={customClassName}>
      {options.map((option) => (
        <Option key={option.value} value={option.value} parent={option.parent}>
          {option.displayName}
        </Option>
      ))}
    </Select>
  );
};

BaseSelect.defaultProps = {
  className: '',
};

BaseSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BaseSelect;
