import { Button } from 'antd';
import PropTypes from 'prop-types';

export const BaseButton = (props) => {
  const { children, className, ...rest } = props;
  const customClassName = `base-button ${className}`;

  return (
    <Button {...rest} className={customClassName}>
      {children}
    </Button>
  );
};

BaseButton.defaultProps = {
  children: undefined,
  className: '',
};

BaseButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};
