import { Spin, Row, Empty, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { SearchBox } from 'components/UI/SearchBox';
import BaseGroupOptions from 'components/UI/BaseGroupOptions';
import {
  selectTags,
  selectTemplates,
  selectIsLoading,
} from 'redux/w2p/templates-by-tag/selector';
import {
  getTagsAssociateTemplate,
  getTemplatesByTag,
} from 'redux/w2p/templates-by-tag/operation';
import { useHistory } from 'react-router-dom';
import { ChevronRight, imagePlaceHolder } from 'assets/images';
import { navigationRoutes } from 'utilities';

export const Web2PublishTemplatesByTag = () => {
  const templates = useSelector((state) => selectTemplates(state));
  const tags = useSelector((state) => selectTags(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const history = useHistory();

  const dispatch = useDispatch();

  const [termValue, setTermValue] = useState();
  const [tagValues, setTagValues] = useState([]);

  useEffect(() => {
    dispatch(getTagsAssociateTemplate());
  }, [dispatch]);

  useEffect(() => {
    const tagIds = tagValues.map((tag) => tag.id);
    dispatch(getTemplatesByTag(tagIds, termValue));
  }, [dispatch, tagValues, termValue]);

  const options = useMemo(
    () =>
      tags.map((t) => {
        return {
          name: t.name,
          id: t.id,
        };
      }),
    [tags],
  );

  const onSearch = (term) => {
    setTermValue(term);
  };

  const onValueChange = useCallback((values) => {
    setTagValues(values);
  }, []);

  const onClickBaseTemplateItem = (templateItem) => {
    const breadCrumbItems = [
      {
        path: navigationRoutes.Web2PublishMain,
        title: 'Home',
      },
      {
        path: navigationRoutes.Web2PublishMain,
        title: 'Categories',
      },
    ];
    if (templateItem.categories && templateItem.categories.length > 0) {
      breadCrumbItems.push({
        title: templateItem.categories[0].name,
      });
    }
    history.push(
      `${navigationRoutes.SingleAsset}/${templateItem.id}`,
      breadCrumbItems,
    );
  };

  return (
    <div className="web2Publish-templates-by-tag-container">
      <div className="middle-content">
        <SearchBox onSearch={onSearch} />
        <BaseGroupOptions
          options={options}
          values={tagValues}
          onValueChange={(values) => onValueChange(values)}
        />
        {isLoading ? (
          <div className="loading">
            <Spin />
          </div>
        ) : (
          [
            templates.length > 0 ? (
              <Row
                className="templates"
                key="templates"
                gutter={[
                  { xs: 8, sm: 16, md: 24 },
                  { xs: 8, sm: 16, md: 24 },
                ]}>
                {templates.map((temp) => (
                  <Col
                    key={temp.id}
                    xs={12}
                    md={12}
                    lg={6}
                    onClick={() => onClickBaseTemplateItem(temp)}>
                    <div className="template-image_wrapper">
                      <img
                        className="template-image"
                        src={temp.image ? temp.image : imagePlaceHolder}
                        alt=""
                      />
                      <div className="template-image_overlay">
                        <div className="template-image_overlay-content">
                          <h2 className="template-image_overlay-text bold-text mr-2">
                            View
                          </h2>
                          <ChevronRight />
                        </div>
                      </div>
                    </div>
                    <h2 className="template-title bold-text">{temp.title}</h2>
                    <h2 className="template-size">{`Size: ${temp.assetSize}`}</h2>
                    <h3 className="template-description">{temp.bodyShort}</h3>
                  </Col>
                ))}
              </Row>
            ) : (
              <div className="empty" key="templates-by-tag-empty">
                <Empty description={<span>No templates found.</span>} />
              </div>
            ),
          ]
        )}
      </div>
    </div>
  );
};
