import { Form, Input, Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logoBlack } from 'assets/images';
import { useCallback, useMemo } from 'react';
import PropType from 'prop-types';
import { BaseButton } from 'components/UI/BaseButton';
import {
  Auth,
  BROWSER_TITLE,
  DEALER_PROVIDER_NAME,
  INTERNAL_PROVIDER_NAME,
  EXTERNAL_PROVIDER_NAME,
  LOGIN_TYPE,
} from 'utilities';
import useTitle from 'hooks/useTitle';
import { updateUserInfo } from 'redux/userProfile/operation';
import { selectIsLoading } from 'redux/userProfile/selector';

export const Login = ({ loginType }) => {
  useTitle(BROWSER_TITLE.MAPO);
  const dispatch = useDispatch();
  const history = useHistory();
  const { Content } = Layout;

  const { isLoading } = useSelector((state) => ({
    isLoading: selectIsLoading(state),
  }));

  const handleLogin = useCallback((provider) => {
    Auth.signIn(provider);
  }, []);

  const onSubmitInfo = useCallback(
    (values) => {
      dispatch(updateUserInfo(values, history));
    },
    [dispatch, history],
  );

  const onSubmitEmail = useCallback(
    (values) => {
      dispatch(updateUserInfo(values, history));
    },
    [dispatch, history],
  );

  const onSubmitNameAndEmail = useCallback(
    (values) => {
      dispatch(updateUserInfo(values, history));
    },
    [dispatch, history],
  );

  const renderContent = useMemo(() => {
    switch (loginType) {
      case LOGIN_TYPE.DEALER_NAME:
        return (
          <Form className="login-dealer-form" onFinish={onSubmitInfo}>
            <Form.Item
              name="givenName"
              rules={[
                { required: true, message: 'Please input your first name!' },
              ]}>
              <Input
                className="login-dealer-form-input"
                placeholder="First name"
              />
            </Form.Item>
            <Form.Item
              name="familyName"
              rules={[
                { required: true, message: 'Please input your last name!' },
              ]}>
              <Input
                className="login-dealer-form-input"
                placeholder="Last name"
              />
            </Form.Item>
            <BaseButton
              className="base-yellow-btn"
              htmlType="submit"
              loading={isLoading}>
              SUBMIT
            </BaseButton>
          </Form>
        );

      case LOGIN_TYPE.DEALER_EMAIL:
        return (
          <Form className="login-dealer-form" onFinish={onSubmitEmail}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}>
              <Input className="login-dealer-form-input" placeholder="Email" />
            </Form.Item>
            <BaseButton
              className="base-yellow-btn"
              htmlType="submit"
              loading={isLoading}>
              SUBMIT
            </BaseButton>
          </Form>
        );

      case LOGIN_TYPE.DEALER_NAME_AND_EMAIL:
        return (
          <Form className="login-dealer-form" onFinish={onSubmitNameAndEmail}>
            <Form.Item
              name="givenName"
              rules={[
                { required: true, message: 'Please input your first name!' },
              ]}>
              <Input
                className="login-dealer-form-input"
                placeholder="First name"
              />
            </Form.Item>
            <Form.Item
              name="familyName"
              rules={[
                { required: true, message: 'Please input your last name!' },
              ]}>
              <Input
                className="login-dealer-form-input"
                placeholder="Last name"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}>
              <Input className="login-dealer-form-input" placeholder="Email" />
            </Form.Item>
            <BaseButton
              className="base-yellow-btn"
              htmlType="submit"
              loading={isLoading}>
              SUBMIT
            </BaseButton>
          </Form>
        );

      default:
        return (
          <div className="login-button d-flex flex-column">
            <BaseButton
              className="base-yellow-btn mb-6"
              onClick={() => handleLogin(EXTERNAL_PROVIDER_NAME)}>
              EXTERNAL
            </BaseButton>
            <BaseButton
              className="base-yellow-btn mb-6"
              onClick={() => handleLogin(INTERNAL_PROVIDER_NAME)}>
              EMPLOYEE
            </BaseButton>
            <BaseButton
              className="base-yellow-btn mb-6"
              onClick={() => handleLogin(DEALER_PROVIDER_NAME)}>
              DEALER
            </BaseButton>
          </div>
        );
    }
  }, [loginType, onSubmitInfo, isLoading, onSubmitEmail, handleLogin]);

  return (
    <Layout className="login d-flex align-center">
      <Content className="login-content d-flex flex-column">
        <div className="logo mb-6">
          <img src={logoBlack} alt="logo" />
        </div>
        <div className="text-container mb-6">
          <span className="login-content-title">welcome to kärcher</span>
          <br />
          <span className="login-content-title">marketing portal</span>
        </div>

        <div className="text-container mb-6">
          <span className="login-content-sign-in">
            {loginType === LOGIN_TYPE.DEFAULT
              ? 'PLEASE SIGN IN'
              : `PLEASE SUBMIT YOUR ${
                  loginType === LOGIN_TYPE.DEALER_EMAIL ? 'EMAIL' : 'FULL NAME'
                }`}
          </span>
        </div>

        <hr className="login-divider mb-12" />
        {renderContent}
      </Content>
    </Layout>
  );
};

Login.propTypes = {
  loginType: PropType.string.isRequired,
};
