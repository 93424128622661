import { Layout } from 'antd';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ScrollToTopButton from 'components/ScrollToTopButton';
import Login from 'screens/Login';
import Callback from 'screens/Callback';
import routes from './navigations';

const RouteComponent = ({ isLoggedIn }) => {
  return (
    <BrowserRouter>
      {isLoggedIn ? (
        <Layout className="mapo-content">
          <Header />
          <Switch>
            {routes.map((route) => {
              return (
                <Route
                  path={route.path}
                  key={route.path}
                  component={route.component}
                  {...route}
                />
              );
            })}
            <Redirect from="*" to="/home" />
          </Switch>
          <ScrollToTopButton />
          <Footer />
        </Layout>
      ) : (
        <Switch>
          <Route exact path="*" component={Login} />
          <Route path="/callback" component={Callback} />
        </Switch>
      )}
    </BrowserRouter>
  );
};

RouteComponent.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default RouteComponent;
