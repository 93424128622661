import { W2pService } from 'services';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
} from './action';

export const getCategories = () => async (dispatch) => {
  try {
    // Loading
    dispatch(showLoading());
    dispatch({ type: GET_CATEGORIES, payload: true });
    const res = await W2pService.getCategories();
    dispatch(hideLoading());
    dispatch({ type: GET_CATEGORIES_SUCCESS, payload: res });
  } catch (err) {
    dispatch({ type: GET_CATEGORIES_FAIL, err });
    dispatch(hideLoading());
  }
};
