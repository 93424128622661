export const GET_START_PAGE_REQUEST = 'GET_START_PAGE_REQUEST';
export const GET_START_PAGE_SUCCESS = 'GET_START_PAGE_SUCCESS';
export const GET_START_PAGE_FAIL = 'GET_START_PAGE_FAIL';

export const UPDATE_START_PAGE_REQUEST = 'UPDATE_START_PAGE_REQUEST';
export const UPDATE_START_PAGE_SUCCESS = 'UPDATE_START_PAGE_SUCCESS';
export const UPDATE_START_PAGE_FAIL = 'UPDATE_START_PAGE_FAIL';

export const UPDATE_PREVIEW_START_PAGE = 'UPDATE_PREVIEW_START_PAGE';
export const RESET_START_PAGE_DATA = 'RESET_START_PAGE_DATA';

export const DELETE_SUB_PAGE_REQUEST = 'DELETE_SUB_PAGE_REQUEST';
export const DELETE_SUB_PAGE_SUCCESS = 'DELETE_SUB_PAGE_SUCCESS';
export const DELETE_SUB_PAGE_FAIL = 'DELETE_SUB_PAGE_FAIL';

export const GET_TEXT_SUB_PAGE_REQUEST = 'GET_TEXT_SUB_PAGE_REQUEST';
export const GET_TEXT_SUB_PAGE_SUCCESS = 'GET_TEXT_SUB_PAGE_SUCCESS';
export const GET_TEXT_SUB_PAGE_FAIL = 'GET_TEXT_SUB_PAGE_FAIL';

export const CREATE_TEXT_SUB_PAGE_REQUEST = 'CREATE_TEXT_SUB_PAGE_REQUEST';
export const CREATE_TEXT_SUB_PAGE_SUCCESS = 'CREATE_TEXT_SUB_PAGE_SUCCESS';
export const CREATE_TEXT_SUB_PAGE_FAIL = 'CREATE_TEXT_SUB_PAGE_FAIL';

export const UPDATE_TEXT_SUB_PAGE_REQUEST = 'UPDATE_TEXT_SUB_PAGE_REQUEST';
export const UPDATE_TEXT_SUB_PAGE_SUCCESS = 'UPDATE_TEXT_SUB_PAGE_SUCCESS';
export const UPDATE_TEXT_SUB_PAGE_FAIL = 'UPDATE_TEXT_SUB_PAGE_FAIL';

export const UPDATE_PREVIEW_TEXT_SUB_PAGE = 'UPDATE_PREVIEW_TEXT_SUB_PAGE';
export const RESET_TEXT_SUB_PAGE_DATA = 'RESET_TEXT_SUB_PAGE_DATA';

export const GET_MEDIA_SUB_PAGE_REQUEST = 'GET_MEDIA_SUB_PAGE_REQUEST';
export const GET_MEDIA_SUB_PAGE_SUCCESS = 'GET_MEDIA_SUB_PAGE_SUCCESS';
export const GET_MEDIA_SUB_PAGE_FAIL = 'GET_MEDIA_SUB_PAGE_FAIL';

export const CREATE_MEDIA_SUB_PAGE_REQUEST = 'CREATE_MEDIA_SUB_PAGE_REQUEST';
export const CREATE_MEDIA_SUB_PAGE_SUCCESS = 'CREATE_MEDIA_SUB_PAGE_SUCCESS';
export const CREATE_MEDIA_SUB_PAGE_FAIL = 'CREATE_MEDIA_SUB_PAGE_FAIL';

export const UPDATE_MEDIA_SUB_PAGE_REQUEST = 'UPDATE_MEDIA_SUB_PAGE_REQUEST';
export const UPDATE_MEDIA_SUB_PAGE_SUCCESS = 'UPDATE_MEDIA_SUB_PAGE_SUCCESS';
export const UPDATE_MEDIA_SUB_PAGE_FAIL = 'UPDATE_MEDIA_SUB_PAGE_FAIL';

export const UPDATE_PREVIEW_MEDIA_SUB_PAGE = 'UPDATE_PREVIEW_MEDIA_SUB_PAGE';
export const RESET_MEDIA_SUB_PAGE_DATA = 'RESET_MEDIA_SUB_PAGE_DATA';
