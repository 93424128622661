import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { SearchBox } from 'components/UI/SearchBox';
import { selectUserManagements } from 'redux/administration/user-management/selector';
import {
  searchUsers,
  deleteUser,
} from 'redux/administration/user-management/operation';
import BaseTable from 'components/UI/BaseTable';
import { useHistory } from 'react-router-dom';
import { navigationRoutes, openConfirmModal, STATUS } from 'utilities';
import { EditIcon, DeleteIcon } from 'assets/images';
import { Tooltip } from 'antd';
import { PAGE_SIZE } from './util/constant';

const RESET = 'RESET';

const UserManagementList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userManagementInfo } = useSelector((state) => ({
    userManagementInfo: selectUserManagements(state),
  }));

  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: PAGE_SIZE,
    search: userManagementInfo.search,
    startWith: userManagementInfo.startWith,
    filters: {
      companies: [],
      regions: [],
      jobTypes: [],
      userTypes: [],
      status: null,
    },
  });

  useEffect(() => {
    const data = [];
    if (paginationData.filters.companies.length > 0) {
      data.push({
        fieldName: 'companyId',
        value: paginationData.filters.companies.join(','),
      });
    }
    if (paginationData.filters.regions.length > 0) {
      data.push({
        fieldName: 'regionId',
        value: paginationData.filters.regions.join(','),
      });
    }
    if (paginationData.filters.jobTypes.length > 0) {
      data.push({
        fieldName: 'jobTypeId',
        value: paginationData.filters.jobTypes.join(','),
      });
    }
    if (paginationData.filters.userTypes.length > 0) {
      data.push({
        fieldName: 'userTypeId',
        value: paginationData.filters.userTypes.join(','),
      });
    }
    if (paginationData.filters.status) {
      data.push({
        fieldName: 'status',
        value: paginationData.filters.status,
      });
    }
    if (paginationData.search || userManagementInfo.search) {
      data.push({
        fieldName: 'term',
        value:
          paginationData.search === RESET
            ? ''
            : paginationData.search || userManagementInfo.search,
      });
    }
    if (paginationData.startWith || userManagementInfo.startWith) {
      data.push({
        fieldName: '_startWith',
        value: paginationData.startWith || userManagementInfo.startWith,
      });
    }
    dispatch(
      searchUsers(paginationData.current, paginationData.pageSize, data),
    );
  }, [dispatch, paginationData]);

  const handleTableChange = useCallback((pagination, filter) => {
    const paging = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      search: userManagementInfo.search,
      filters: {
        companies: filter.company ?? [],
        regions: filter.region ?? [],
        jobTypes: filter.jobType ?? [],
        userTypes: filter.userType ?? [],
        status: filter.status,
      },
      startWith: userManagementInfo.startWith,
    };
    setPaginationData(paging);
  }, []);

  const onFilter = (keyword) => {
    if (keyword === 'ALL') {
      window.location.reload(false);
    } else {
      setPaginationData({ ...paginationData, startWith: keyword });
      // dispatch(
      //   searchUsers(1, PAGE_SIZE, [
      //     { fieldName: '_startWith', value: keyword },
      //   ]),
      // );
    }
  };

  const onSearch = (keyword) => {
    setPaginationData({ ...paginationData, search: keyword || RESET });
  };

  const onClickEdit = useCallback(
    ({ id }) => {
      history.push(`${navigationRoutes.SingleUserInformation}/${id}`);
    },
    [history],
  );

  const onDeleteUser = useCallback(
    (id) => {
      const message = (
        <span>Are you sure you want to delete user with id - {id}?</span>
      );
      openConfirmModal({
        title: 'ANNOUNCEMENT',
        content: message,
        onOk: () => {
          dispatch(deleteUser(id));
        },
      });
    },
    [dispatch],
  );

  const columns = useMemo(() => {
    const filterOptions = userManagementInfo?.info?.metadata?.filterOptions ?? {
      companies: [],
      regions: [],
      jobTypes: [],
      userTypes: [],
    };

    const renderColumns = [
      {
        title: 'Type',
        dataIndex: 'userType',
        render: (type) => {
          return type?.name;
        },
        filters: filterOptions.userTypes.map((c) => ({
          value: c.id,
          text: c.name,
        })),
      },
      {
        title: 'eMail',
        dataIndex: 'email',
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
      },
      {
        title: 'Username',
        dataIndex: 'actualId',
      },
      {
        title: 'Company',
        dataIndex: 'company',
        filters: filterOptions.companies.map((c) => ({
          value: c.id,
          text: c.id,
        })),
      },
      {
        title: 'Region',
        dataIndex: 'region',
        filters: filterOptions.regions.map((c) => ({
          value: c.id,
          text: c.name,
        })),
      },
      {
        title: 'Job Type',
        dataIndex: 'jobType',
        filters: filterOptions.jobTypes.map((c) => ({
          value: c.id,
          text: c.name,
        })),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        filters: [
          {
            text: 'Active',
            value: 'active',
          },
          {
            text: 'Inactive',
            value: 'inactive',
          },
        ],

        render: (status) => {
          if (status === STATUS.ACTIVE) {
            return <span className="status status-active">{status}</span>;
          }

          if (status === STATUS.INACTIVE) {
            return <span className="status status-inactive">{status}</span>;
          }

          return null;
        },
      },
      {
        title: '',
        render: (record) => {
          const { id } = record;
          return (
            <div className="user-management-action">
              <Tooltip
                className="user-management-list_editable"
                onClick={() => onClickEdit({ history, id })}
                title="Edit"
                placement="bottom">
                <EditIcon />
              </Tooltip>
              <Tooltip
                onClick={() => onDeleteUser(id)}
                className="delete-icon"
                title="Delete"
                placement="bottom">
                <DeleteIcon />
              </Tooltip>
            </div>
          );
        },
      },
    ];

    return renderColumns;
  }, [history, onClickEdit, onDeleteUser, userManagementInfo.info]);

  const tableProps = useMemo(
    () => ({
      columns,
      loading: userManagementInfo.isLoading,
      dataSource: userManagementInfo.info.data
        ? userManagementInfo.info.data
        : [],
      rowKey: 'id',
      pagination: {
        current: userManagementInfo.info.metadata
          ? Number(userManagementInfo.info.metadata.page)
          : 1,
        pageSize: userManagementInfo.info.metadata
          ? Number(userManagementInfo.info.metadata.limit)
          : PAGE_SIZE,
        total: userManagementInfo.info.metadata
          ? Number(userManagementInfo.info.metadata.totalRecords)
          : 0,
      },
      className: 'user-management-table',
      onChange: handleTableChange,
    }),
    [
      columns,
      handleTableChange,
      userManagementInfo.info.data,
      userManagementInfo.info.metadata,
      userManagementInfo.isLoading,
    ],
  );

  return (
    <div className="user-management-list middle-content">
      <SearchBox onFilter={onFilter} onSearch={onSearch} />
      <BaseTable {...tableProps} />
    </div>
  );
};

export default UserManagementList;
