import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { selectPreviewMediaSubPage } from 'redux/microSite/selector';
import PreviewComponent from 'components/UI/PreviewComponent';
import { imagePlaceHolder } from 'assets/images';

const Preview = ({ startPageTitle }) => {
  const mediaSubPage = useSelector((state) => selectPreviewMediaSubPage(state));

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
      },
      {
        title: startPageTitle || '',
      },
      {
        title: mediaSubPage.title || '',
      },
    ],
    [startPageTitle, mediaSubPage.title],
  );

  const categories = useMemo(
    () =>
      mediaSubPage.categories
        ? mediaSubPage.categories.filter((category) => !!category)
        : null,
    [mediaSubPage],
  );

  return (
    <PreviewComponent
      contentClassName="preview-media-sub-page"
      modalClassName="preview-modal-media-sub-page"
      title={mediaSubPage.title || ''}
      breadCrumbItems={breadCrumbItems}
      isHideFooter
      isHasModal>
      <div className="banner">
        <div className="middle-content">
          <h2 className="preview-headline bold-text mb-0">
            {mediaSubPage?.title}
          </h2>
          <p className="preview-headline-text bold-text mb-0">
            {mediaSubPage?.textHeadline}
          </p>
        </div>
      </div>
      {mediaSubPage?.mediaHeadline && (
        <div className="preview-image">
          <img src={mediaSubPage.mediaHeadline} alt="" />
        </div>
      )}
      <div className="preview-category">
        <div className="middle-content">
          <h2 className="preview-sub-headline bold-text">
            {mediaSubPage?.subHeadlineTitle}
          </h2>
          <p className="preview-sub-headline-text">
            {mediaSubPage?.subHeadlineText}
          </p>
          {categories && categories.length > 0 ? (
            <Row className="preview-category-items" gutter={[2, 2]}>
              {categories.map((item) => (
                <Col span={24} key={item.id ? item.id : Math.random()}>
                  <div className="preview-category-img_wraper">
                    <img
                      className="preview-category-img"
                      src={item.mediaLink || imagePlaceHolder}
                      alt=""
                    />
                  </div>
                  <div className="preview-category-title bold-text">
                    {item.title}
                  </div>
                </Col>
              ))}
            </Row>
          ) : null}
        </div>
      </div>
    </PreviewComponent>
  );
};

Preview.propTypes = {
  startPageTitle: PropTypes.string,
};

Preview.defaultProps = {
  startPageTitle: '',
};

export default Preview;
