import { Layout } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectUserManagements } from 'redux/administration/user-management/selector';
import {
  getDetailUser,
  resetUser,
} from 'redux/administration/user-management/operation';
import Breadcrumb from 'components/UI/Breadcrumb';
import { navigationRoutes } from 'utilities';
import ViewSingleUser from './ViewSingleUser';
import EditSingleUser from './EditSingleUser';

const SingleUserInformation = () => {
  const { Content } = Layout;
  const dispatch = useDispatch();
  const params = useParams();
  const { userManagementInfo } = useSelector((state) => ({
    userManagementInfo: selectUserManagements(state),
  }));
  const [editable, setEditable] = useState(false);
  const [fullName, setFullName] = useState('');

  const handleEditUser = () => {
    setEditable(true);
  };

  const handleCancelEditUser = () => {
    setEditable(false);
  };

  useEffect(() => {
    dispatch(resetUser());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDetailUser(params.userId));
  }, [dispatch, params]);

  useEffect(() => {
    if (userManagementInfo.detailUser) {
      setFullName(userManagementInfo.detailUser.fullname);
    } else {
      setFullName('');
    }
    setEditable(false);
  }, [userManagementInfo.detailUser]);

  const breadcrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Home,
      },
      {
        title: 'Administration',
        path: navigationRoutes.UserManagement,
      },
      {
        title: 'User Management',
        path: navigationRoutes.UserManagement,
      },
      {
        title: fullName,
      },
    ],
    [fullName],
  );

  return (
    <Content className="mapo-administrator-user_content">
      <Breadcrumb items={breadcrumbItems} />
      <div className="single-user-management-content">
        {!editable && <ViewSingleUser onHandleEditUser={handleEditUser} />}
        {editable && (
          <EditSingleUser onHandleCancelEditUser={handleCancelEditUser} />
        )}
      </div>
    </Content>
  );
};

export default SingleUserInformation;
