import { Layout } from 'antd';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Breadcrumb from 'components/UI/Breadcrumb';
import { SearchBox } from 'components/UI/SearchBox';
import { selectW2PContent } from 'redux/administration/content-management/w2p-content-management/selector';
import { getW2PContent } from 'redux/administration/content-management/w2p-content-management/operation';
import BaseTable from 'components/UI/BaseTable';
import { navigationRoutes } from 'utilities';
import { PAGE_SIZE } from './util/constants';
import { renderColumns } from './util/render-columns';

const W2PContentManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Content } = Layout;
  const { W2PContent } = useSelector((state) => ({
    W2PContent: selectW2PContent(state),
  }));

  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: PAGE_SIZE,
  });

  useEffect(() => {
    dispatch(getW2PContent(paginationData));
  }, [dispatch, paginationData]);

  const columns = useMemo(() => {
    return renderColumns(history, dispatch);
  }, [history, dispatch]);

  const handleTableChange = (pagination) => {
    setPaginationData(pagination);
  };

  const tableProps = useMemo(
    () => ({
      columns,
      loading: W2PContent.isLoading,
      dataSource: W2PContent.info,
      rowKey: 'id',
      scroll: { x: 800 },
      pagination: false,
      className: 'w2p-content-management-table',
      onChange: handleTableChange,
    }),
    [columns, W2PContent.info, W2PContent.isLoading],
  );

  const onSearch = (term) => {
    setPaginationData({ title: term });
  };

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Home,
      },
      {
        title: 'Administration',
        path: navigationRoutes.UserManagement,
      },
      {
        title: 'Content Management',
        path: navigationRoutes.MapoContent,
      },
      {
        title: 'WEB 2 PUBLISH',
      },
    ],
    [],
  );

  return (
    <Content className="mapo-administrator-w2p-content-management">
      <Breadcrumb items={breadCrumbItems} />

      <div className="w2p-content-management_banner">
        <h1 className="bold-text font-size-medium">WEB 2 PUBLISH</h1>
      </div>

      <div className="middle-content pt-8 pb-5">
        <SearchBox onSearch={onSearch} />
        <BaseTable {...tableProps} />
      </div>
    </Content>
  );
};

export default W2PContentManagement;
