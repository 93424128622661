import useHeader from 'hooks/useHeader';
import useTitle from 'hooks/useTitle';
import { Route, Switch } from 'react-router-dom';
import { headerTitle, BROWSER_TITLE } from 'utilities';
import routes from './microSiteNavigations';

const Routes = () => {
  useHeader(headerTitle.mapo);
  useTitle(BROWSER_TITLE.MAPO);
  return (
    <Switch>
      {routes.map((route) => {
        return (
          <Route
            path={route.path}
            key={route.path}
            component={route.component}
            {...route}
          />
        );
      })}
    </Switch>
  );
};

export default Routes;
