import { Layout } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import Breadcrumb from 'components/UI/Breadcrumb';
import { navigationRoutes } from 'utilities';
import BaseTabs from 'components/UI/BaseTabs';
import MyTemplate from './contents/myTemplate';
import MyDocuments from './contents/myDocuments';

export const Web2PublishMyTemplates = () => {
  const { Content } = Layout;

  const [BreadcrumbRoute, setBreadcrumbRoute] = useState('PROJECTS');

  const breadCrumbItems = useMemo(
    () => [
      {
        title: 'Home',
        path: navigationRoutes.Web2PublishMain,
      },
      {
        title: 'My Templates',
        path: navigationRoutes.MyTemplate,
      },
      {
        title: BreadcrumbRoute.toLowerCase(),
      },
    ],
    [BreadcrumbRoute],
  );

  const panes = useMemo(
    () => [
      {
        title: 'PROJECTS',
        key: '1',
        content: <MyTemplate breadCrumbItems={breadCrumbItems} />,
      },
      {
        title: 'DOWNLOADS',
        key: '2',
        content: <MyDocuments />,
      },
    ],
    [breadCrumbItems],
  );

  const onTabClick = useCallback(
    (key) => {
      const selectedTab = panes.find((tab) => tab.key === key);
      setBreadcrumbRoute(selectedTab.title);
    },
    [panes],
  );

  return (
    <Content className="web2Publish-my-templates-container">
      <Breadcrumb items={breadCrumbItems} />
      <div className="web2Publish-my-templates_banner">
        <div className="middle-content">
          <h1 className="bold-text web2Publish-my-templates--title">
            My Templates
          </h1>
          <h3 className="bold-text web2Publish-my-templates--text">
            Your already personalized templates are saved here. Those templates
            collected in the Projects section can be edited at any time. You can
            find the history of all templates completed and downloaded by you in
            the Downloads section.
          </h3>
        </div>
      </div>
      <div className="my-template-main-tabs">
        <BaseTabs onTabClick={onTabClick} initialPanes={panes} />
      </div>
    </Content>
  );
};
