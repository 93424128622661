import { AuthenticateService } from 'services';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
  GET_USER_TILE_ROLES,
  GET_USER_TILE_ROLES_SUCCESS,
  GET_USER_TILE_ROLES_FAIL,
} from './action';

export const getAuthentication = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: GET_USER_TILE_ROLES });
    const res = await AuthenticateService.getAuthenticationInfos();
    dispatch(hideLoading());
    dispatch({
      type: GET_USER_TILE_ROLES_SUCCESS,
      payload: {
        tiles: res.data.accessTiles,
        userRole: res.data.userRole,
      },
    });
  } catch (err) {
    dispatch({ type: GET_USER_TILE_ROLES_FAIL, err });
    dispatch(hideLoading());
  }
};
